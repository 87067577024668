import { differenceInSeconds , differenceInMinutes, differenceInHours, differenceInDays, differenceInMonths, differenceInYears } from 'date-fns';

export const dateDifference = (date, media) => {
  let mediaTmp;
  const processedDate = date.replace('Z', '');
  const givenDate = new Date(processedDate);

  const currentDate = new Date();

  const timeDifferenceInSeconds = differenceInSeconds(currentDate, givenDate);
  const timeDifferenceInMinutes = differenceInMinutes(currentDate, givenDate);
  if(media === undefined || media === null) mediaTmp = true;
  else mediaTmp = media

  if(timeDifferenceInMinutes < 1) return `${timeDifferenceInSeconds} ${mediaTmp ? "detik yang lalu" : "dtk lalu"}`;
  else {
    const timeDifferenceInHours = differenceInHours(currentDate, givenDate);

    if(timeDifferenceInHours < 1) return `${timeDifferenceInMinutes} ${mediaTmp ? "menit yang lalu" : "mnt lalu"}`;
    else {
      const timeDifferenceInDays = differenceInDays(currentDate, givenDate);

      if(timeDifferenceInDays < 1) return `${timeDifferenceInHours} ${mediaTmp ? "jam yang lalu" : "jm lalu"}`;
      else {
        const timeDifferenceInMonths = differenceInMonths(currentDate, givenDate);
        
        if(timeDifferenceInMonths < 1) return `${timeDifferenceInDays} ${mediaTmp ? "hari yang lalu" : "hr lalu"}`;
        else {
          const timeDifferenceInYears = differenceInYears(currentDate, givenDate);

          if(timeDifferenceInYears < 1) return `${timeDifferenceInMonths} ${mediaTmp ? "bulan yang lalu" : "bln lalu"}`;
          else return `${timeDifferenceInYears} ${mediaTmp ? "tahun yang lalu" : "thn lalu"}`;
        }
      }
    }
  }
}

export const deadlineDifference = (deadline) => {
  const processedDeadline = deadline.replace('Z', '');
  const waktuDeadline = new Date(processedDeadline);

  const waktuSekarang = new Date();

  const selisihHari = differenceInDays(waktuDeadline, waktuSekarang);

  return selisihHari;
}