import React, { Fragment, useRef, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import {isMobile} from 'react-device-detect';

import { ReactComponent as Plus } from '../../../../assets/svg/Plus.svg';
import { ReactComponent as Trash } from '../../../../assets/svg/Trash.svg';

import CustomInputSelect from "../../../../components/CustomInputSelect/index.jsx";
import CustomInput from "../../../../components/CustomInput/index.jsx";
import CustomInputButton from "../../../../components/CustomInputButton/index.jsx";
import CustomCheckbox from "../../../../components/CustomCheckbox/index.jsx";
import CustomButton2 from "../../../../components/CustomButton2/index.jsx";
import CustomButton3 from "../../../../components/CustomButton3/index.jsx";

import { defaultDataPengalaman } from "../../../../config/defaultData.js";
import { defaultDataPendidikan } from "../../../../config/defaultData.js";
import { months, years } from "../../../../config/date.js";
import { styleCircleBack, styleLabelBack, styleCircle, styleLabel } from "../../../../config/style.js";

import "./index.css";

const PengalamanPendidikan = ({ style, setStyle, showPage, setShowPage, dataToSend }) => {
  const style1 = isMobile ? { width: "100vw" } : { width: "calc(100vw - 17px)" };
  const style2 = showPage.page1;

  const combinedStyle = {
    ...style1,
    ...style2,
  };

  const dataPengalamanRef = useRef({
    jabatan: "",
    perusahaan: "",
    lokasi: "",
    tipeKerja: "",
    deskripsi: ""
  });
  const dataPendidikanRef = useRef({
    perguruanTinggi: "",
    jurusan: "",
    lokasi: "",
    gelar: "",
    deskripsi: ""
  })

  const [dataToSendPengalaman, setDataToSendPengalaman] = useState([0]);
  const [dataToSendPendidikan, setDataToSendPendidikan] = useState([0]);
  const [pengalamanChecked, setPengalamanChecked] = useState([false]);
  const [pendidikanChecked, setPendidikanChecked] = useState([false]);
  const [deskripsiPengalaman, setDeskripsiPengalaman] = useState([""]);
  const [deskripsiPendidikan, setDeskripsiPendidikan] = useState([""]);
  const [warningText, setWarningText] = useState(<></>);

  const handleDataAdd = (e, data, setData, dataRef, defaultData, checked, setChecked, formRef,input, setInput) => {
    e.preventDefault();
    const values = Object.values(formRef);
    let check = true;
    for(let i = 0 ; i < values.length-1 ; i++){
      if(!values[i].checkValidity()) {
        check = false;
        break;
      }
    } 
    
    if (check) {
      setWarningText(<></>);
      var defaultTemp = JSON.parse(JSON.stringify(defaultData));
      dataRef.push(defaultTemp);
      setData([...data, data[data.length - 1] + 1]);
      setChecked([...checked, false]);
      setInput([...input, ""])
    } else {
      setWarningText(<label className="lengkapiProfil-wrap-error warning-text">*Lengkapi form</label>)
    }
  }

  const handleCheckedBox = (index, checked, setChecked, data, objek) => {
    var updatedChecked = [...checked];
    updatedChecked[index] = !checked[index];

    setChecked(updatedChecked);
    data[index][objek] = !checked[index]
  }

  const deleteItem = (index, data, setData, dataRef, input, setInput) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
    
    const newInput = [...input];
    newInput.splice(index, 1);
    setInput(newInput);

    dataRef.splice(index, 1);
  }

  const handleCountCharacter = (e, index, input, setInput, dataInput) => {
    dataInput[index].deskripsi = e.target.value;

    const newInput = [...input];
    newInput[index] = e.target.value;
    setInput(newInput);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    scroll.scrollToTop();
    setShowPage({
      ...showPage,
      page1: {"transform": "translateX(-300%)"},
      page2: {"transform": "translateX(-300%)"},
      page3: {"transform": "translateX(-300%)"},
      page4: {"transform": "translateX(-300%)"}
    })
    setStyle({
      ...style,
      styleCircle4: styleCircle,
      styleLabel4: styleLabel
    })
  }

  const handleBack = (e) => {
    e.preventDefault();
    scroll.scrollToTop();
    setShowPage({
      ...showPage,
      page1: { "transform": "translateX(-100%)" },
      page2: { "transform": "translateX(-100%)" },
      page3: { "transform": "translateX(-100%)" },
      page4: { "transform": "translateX(-100%)" }
    })
    setStyle({
      ...style,
      styleCircle3: styleCircleBack,
      styleLabel3: styleLabelBack
    })
  }

  return (
    <div
      name="pengalamanPendidikan"
      className="lengkapiProfil-container"
      style={combinedStyle}
    >
      <div className="lengkapiProfil-wrap">
        <form action="#" onSubmit={handleSubmit} className="pengalamanPendidikan-item">
          {dataToSendPengalaman.map((_, index) => (
            <Fragment key={index}>
              <div className="lengkapiProfil-title">
                <label>Pengalaman Kerja</label>
                <div className="pengelamanPendidikan-svg">
                  {index > 0 &&
                    <Trash className="pengelamanPendidikan-svg-item" onClick={() => deleteItem(index, dataToSendPengalaman, setDataToSendPengalaman, dataToSend.pengalaman, deskripsiPengalaman, setDeskripsiPengalaman)} />
                  }
                </div>
              </div>
              <CustomInput
                label={"Posisi/Jabatan"}
                warning={"*"}
                type={"text"}
                placeholder={"Jr. Front End Developer"}
                innerRef={(ref) => dataPengalamanRef.current.jabatan = ref}
                onChange={(e) => dataToSend.pengalaman[index].jabatan = e.target.value}
                required={true}
              />
              <CustomInput
                label={"Perusahaan"}
                warning={"*"}
                type={"text"}
                placeholder={"PT. Maju Sendiri"}
                innerRef={(ref) => dataPengalamanRef.current.perusahaan = ref}
                onChange={(e) => dataToSend.pengalaman[index].perusahaan = e.target.value}
                required={true}
              />
              <CustomInput
                label={"Lokasi"}
                warning={"*"}
                type={"text"}
                placeholder={"Jakarta Timur"}
                innerRef={(ref) => dataPengalamanRef.current.lokasi = ref}
                onChange={(e) => dataToSend.pengalaman[index].lokasi = e.target.value}
                required={true}
              />
              <CustomInput
                label={"Tipe Kerja"}
                warning={"*"}
                type={"text"}
                placeholder={"On Site"}
                innerRef={(ref) => dataPengalamanRef.current.tipeKerja = ref}
                onChange={(e) => dataToSend.pengalaman[index].tipeKerja = e.target.value}
                required={true}
              />
              <CustomInput
                input={"textarea"}
                label={"Deskripsi"}
                warning={"*"}
                placeholder={"Jobdesk selama berkeja sudah mengerjakan apa saja dan aktivitas tiap harinya."}
                rows={10}
                description={"min. 150 Karakter"}
                currentText={deskripsiPengalaman[index].length}
                maxText={2000}
                innerRef={(ref) => dataPengalamanRef.current.deskripsi = ref}
                onChange={(e) => handleCountCharacter(e, index, deskripsiPengalaman, setDeskripsiPengalaman, dataToSend.pengalaman)}
                required={true}
                minLength={150}
              />
              <CustomInputSelect
                label={"Periode"}
                warning={"*"}
                subLabel={"Tanggal Mulai"}
                firstOnChange={(e) => dataToSend.pengalaman[index].tanggalMulai.bulan = e.target.value}
                firstData={months}
                secondOnChange={(e) => dataToSend.pengalaman[index].tanggalMulai.tahun = e.target.value}
                secondData={years()}
                double={true}
              />
              <CustomInputSelect
                label={""}
                subLabel={"Tanggal Berakhir"}
                firstOnChange={(e) => dataToSend.pengalaman[index].tanggalBerakhirBulan = e.target.value}
                firstData={months}
                secondOnChange={(e) => dataToSend.pengalaman[index].tanggalBerakhirTahun = e.target.value}
                secondData={years()}
                double={true}
              />
              <div className="pengalamanPendidikan-checkbox">
                <div className="pengalamanPendidikan-checkbox-ghost"></div>
                <div className="pengalamanPendidikan-checkbox-real">
                  <CustomCheckbox 
                    id={`checkBox-pengalaman-kerja-${index}`}
                    checked={pengalamanChecked[index]}
                    onChange={() => handleCheckedBox(index, pengalamanChecked, setPengalamanChecked, dataToSend.pengalaman, "pekerjaanSekarang")} 
                  />
                  <label>Masih bekerja disini</label>
                </div>
              </div>
            </Fragment>
          ))}
          <CustomButton3
            onClick={(e) => handleDataAdd(e, dataToSendPengalaman, setDataToSendPengalaman, dataToSend.pengalaman, defaultDataPengalaman, pengalamanChecked, setPengalamanChecked, dataPengalamanRef.current, deskripsiPengalaman, setDeskripsiPengalaman)}
            logo={<Plus className="lengkapiProfil-tambah-svg" />}
            label={"Tambah Pengalaman Kerja"}
          />
          {warningText}
          {dataToSendPendidikan.map((_, index) => (
            <Fragment key={index}>
              <div className="lengkapiProfil-title">
                <label>Pendidikan</label>
                <div className="pengelamanPendidikan-svg">
                  {index > 0 &&
                    <Trash className="pengelamanPendidikan-svg-item" onClick={() => deleteItem(index, dataToSendPendidikan, setDataToSendPendidikan, dataToSend.pendidikan, deskripsiPendidikan, setDeskripsiPendidikan)} />
                  }
                </div>
              </div>
              <CustomInput
                label={"Nama Perguruan Tinggi"}
                warning={"*"}
                type={"text"}
                placeholder={"Universitas Iyain Aja"}
                innerRef={(ref) => dataPendidikanRef.current.perguruanTinggi = ref}
                onChange={(e) => dataToSend.pendidikan[index].perguruanTinggi = e.target.value}
                required={true}
              />
              <CustomInput
                label={"Jurusan"}
                warning={"*"}
                type={"text"}
                placeholder={"Teknik Informatika"}
                innerRef={(ref) => dataPendidikanRef.current.jurusan = ref}
                onChange={(e) => dataToSend.pendidikan[index].jurusan = e.target.value}
                required={true}
              />
              <CustomInput
                label={"Lokasi"}
                warning={"*"}
                type={"text"}
                placeholder={"Jakarta Selatan"}
                innerRef={(ref) => dataPendidikanRef.current.lokasi = ref}
                onChange={(e) => dataToSend.pendidikan[index].lokasi = e.target.value}
                required={true}
              />
              <CustomInput
                label={"Gelar"}
                warning={"*"}
                type={"text"}
                placeholder={"Sarjana"}
                innerRef={(ref) => dataPendidikanRef.current.gelar = ref}
                onChange={(e) => dataToSend.pendidikan[index].gelar = e.target.value}
                required={true}
              />
              <CustomInput
                input={"textarea"}
                label={"Deskripsi"}
                warning={"*"}
                placeholder={"Selama menempuh pendidikan sudah berbuat apa dan menghasilkan apa aktivitas selama menempuh pendidikan."}
                rows={10}
                description={"min. 150 Karakter"}
                currentText={deskripsiPendidikan[index].length}
                maxText={2000}
                innerRef={(ref) => dataPendidikanRef.current.deskripsi = ref}
                onChange={(e) => handleCountCharacter(e, index, deskripsiPendidikan, setDeskripsiPendidikan, dataToSend.pendidikan)}
                required={true}
                minLength={150}
              />
              <CustomInputSelect
                label={"Periode"}
                warning={"*"}
                subLabel={"Tanggal Mulai"}
                firstOnChange={(e) => dataToSend.pendidikan[index].tanggalMulai.bulan = e.target.value}
                firstData={months}
                secondOnChange={(e) => dataToSend.pendidikan[index].tanggalMulai.tahun = e.target.value}
                secondData={years()}
                double={true}
              />
              <CustomInputSelect
                label={""}
                subLabel={"Tanggal Berakhir"}
                firstOnChange={(e) => dataToSend.pendidikan[index].tanggalLulus.bulan = e.target.value}
                firstData={months}
                secondOnChange={(e) => dataToSend.pendidikan[index].tanggalLulus.tahun = e.target.value}
                secondData={years()}
                double={true}
              />
              <div className="pengalamanPendidikan-checkbox">
                <div className="pengalamanPendidikan-checkbox-ghost"></div>
                <div className="pengalamanPendidikan-checkbox-real">
                  <CustomCheckbox 
                    id={`checkBox-pendidikan-${index}`}
                    checked={pendidikanChecked[index]}
                    onChange={(e) => handleCheckedBox(index, pendidikanChecked, setPendidikanChecked, dataToSend.pendidikan, "pendidikanSekarang")} 
                  />
                  <label>Masih dalam berproses</label>
                </div>
              </div>
            </Fragment>
          ))}
          <CustomButton3
            onClick={(e) => handleDataAdd(e, dataToSendPendidikan, setDataToSendPendidikan, dataToSend.pendidikan, defaultDataPendidikan, pendidikanChecked, setPendidikanChecked, dataPendidikanRef.current, deskripsiPendidikan, setDeskripsiPendidikan)}
            logo={<Plus className="lengkapiProfil-tambah-svg" />}
            label={"Tambah Pendidikan"}
          />
          {warningText}
          <div className="lengkapiProfil-bottom">
            <CustomInputButton
              color={true}
              value={"Selanjutnya"}
            />
            <CustomButton2 
              onClick={handleBack}
              label={"Kembali"}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default PengalamanPendidikan;