import React, { useEffect, useRef, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import { isMobile } from 'react-device-detect';
import { ReactComponent as Upload } from '../../../../../assets/svg/Upload.svg';

import CustomButton2 from "../../../../../components/CustomButton2";
import CustomInput from "../../../../../components/CustomInput";
import CustomInputImage from "../../../../../components/CustomInputImage";
import CustomInputButton from "../../../../../components/CustomInputButton";
import CustomInputSelect from "../../../../../components/CustomInputSelect";
import CustomCheckbox from "../../../../../components/CustomCheckbox";

import { styleCircleBack, styleLabelBack, styleCircle, styleLabel } from "../../../../../config/style";
import { getProfile } from "../../../../../config/getProfile";
import { changeImage } from "../../../../../config/changeImage";
import axios from "../../../../../config/api";
import { handleCountCharacter } from "../../../../../config/handleChange";

import "./index.css";

const DetilJobBudget = ({ 
  style, 
  setStyle, 
  showPage, 
  setShowPage, 
  dataToSend, 
  fileTambahan, 
  setFileTambahan,
}) => {
  const style1 = isMobile ? { width: "100vw" } : { width: "calc(100vw - 17px)" };
  const style2 = showPage.page1;

  const combinedStyle = {
    ...style1,
    ...style2,
  };

  const fileTambahanInputRef = useRef(null);

  const [subKategoriIndex, setSubKategoriIndex] = useState(0);
  const [negosiasiChecked, setNegosiasiChecked] = useState(false);
  const [file, setFile] = useState(null);
  const [industri, setIndustri] = useState([]);
  const [kategori, setKategori] = useState([]);
  const [detilPekerjaan, setDetilPekerjaan] = useState("");

  const handleChangeImageClick = (ref) => {
    ref.click();
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    scroll.scrollTo(0);
    setShowPage({
      ...showPage,
      page1: {"transform": "translateX(-200%)"},
      page2: {"transform": "translateX(-200%)"},
      page3: {"transform": "translateX(-200%)"}
    })
    setStyle({
      ...style,
      styleCircle3: styleCircle,
      styleLabel3: styleLabel
    })
  }

  const subKategoriDisplay = (e) => {
    const selectedValue = e.target.value;
    const selectedText = e.target.options[e.target.selectedIndex].text;
    dataToSend.detilJobBudget.kategori = selectedValue;
    dataToSend.detilJobBudget.kategori_label = selectedText;

    const tmpIndex = kategori.findIndex((item) => item._id === selectedValue);
    if (tmpIndex !== -1) {
        const selectedSubKategori = kategori[tmpIndex].subKategori[0]._id;
        dataToSend.detilJobBudget.subKategori = selectedSubKategori;
        dataToSend.detilJobBudget.subKategori_label = kategori[tmpIndex].subKategori.find(sub => sub._id === selectedSubKategori)?.label || '';
        setSubKategoriIndex(tmpIndex);
    }
  }

  const handleCheckedBox = () => {
    setNegosiasiChecked(!negosiasiChecked);
    dataToSend.detilJobBudget.freelanceNegosiasi = !negosiasiChecked
  }

  const handleIndustriChange = (e) => {
    const selectedValue = e.target.value;
    const selectedText = e.target.options[e.target.selectedIndex].text;
    dataToSend.detilJobBudget.industri = selectedValue;
    dataToSend.detilJobBudget.industri_label = selectedText;
  }

  const handleBack = (e) => {
    e.preventDefault();
    scroll.scrollToTop();
    setShowPage({
      ...showPage,
      page1: {"transform": "translateX(0)"},
      page2: {"transform": "translateX(0)"},
      page3: {"transform": "translateX(0)"}
    })
    setStyle({
      ...style,
      styleCircle2: styleCircleBack,
      styleLabel2: styleLabelBack
    })
  }

  useEffect(() => {
    const getData = async() => {
      await getProfile("informasiProfil")
      .then(response => {
        if(dataToSend.informasiProfil) {
          dataToSend.informasiProfil.nama = response.data.informasiProfil.nama;
          dataToSend.informasiProfil.username = response.data.informasiProfil.username;
          dataToSend.informasiProfil.email = response.data.informasiProfil.email;
          dataToSend.informasiProfil.noTelepon = response.data.informasiProfil.noTelepon;
        };
      })
      .catch(error => {
        console.log(error);
      })
    }

    const getIndustry = async() => {
      try{
        const response = await axios.get("/industry");
        dataToSend.detilJobBudget.industri = response.data.industri[0]._id;
        dataToSend.detilJobBudget.industri_label = response.data.industri[0].label;
        setIndustri(response.data.industri);
      } catch(error) {
        console.log(error);
      }
    }

    const getCategory = async() => {
      try{
        const response = await axios.get("/category");
        dataToSend.detilJobBudget.kategori = response.data.kategori[0]._id;
        dataToSend.detilJobBudget.kategori_label = response.data.kategori[0].label;
        dataToSend.detilJobBudget.subKategori = response.data.kategori[0].subKategori[0]._id;
        dataToSend.detilJobBudget.subKategori_label = response.data.kategori[0].subKategori[0].label;
        setKategori(response.data.kategori);
      } catch(error) {
        console.log(error);
      }
    }
    
    getCategory();
    getIndustry();
    getData();
  },[])

  return (
    <div 
      name="detilJobBudget" 
      className="lengkapiProfil-container informasiProfilIdentitas"
      style={combinedStyle}
    >
      <div className="lengkapiProfil-wrap">
        <form action="#" onSubmit={handleSubmit} className="informasiProfilIdentitas-item">
          <CustomInput
            label={"Judul Pekerjaan"}
            warning={"*"}
            type={"text"}
            placeholder={"UI Design Mobile App"}
            onChange={(e) => dataToSend.detilJobBudget.judulPekerjaan = e.target.value}
            required={true}
          />
          <CustomInputSelect
            label={"Kategori"}
            warning={"*"}
            firstOnChange={subKategoriDisplay}
            firstData={kategori}
          />
          <CustomInputSelect
            label={"Sub Kategori"}
            warning={"*"}
            firstOnChange={(e) => (dataToSend.detilJobBudget.subKategori = e.target.value)}
            firstData={kategori[subKategoriIndex]?.subKategori}
          />
          <CustomInput
            input={"textarea"}
            label={"Deskripsi Pekerjaan"}
            warning={"*"}
            placeholder={"Deskripsi detil pekerjaan"}
            rows={10}
            description={"min. 150 Karakter"}
            currentText={detilPekerjaan.length}
            onChange={(e) => handleCountCharacter(e, setDetilPekerjaan, dataToSend, "detilPekerjaan")}
            required={true}
            maxText={2000}
            minLength={150}
            maxLength={2000}
          />
          <CustomInputSelect
            label={"Industri"}
            warning={"*"}
            firstOnChange={handleIndustriChange}
            firstData={industri}
          />
          <CustomInputImage
            title={"Unggah File Tambahan"}
            onClick={() => handleChangeImageClick(fileTambahanInputRef.current)}
            src={fileTambahan}
            logo={<Upload className="lengkapiProfil-tambah-svg"/>}
            label={"Upload File Tambahan"}
            innerRef={fileTambahanInputRef}
            onChange={(e) => changeImage(e, setFileTambahan, 5, true, true, true, setFile)}
            required={true}
            description={"Format (JPG, JPEG, PNG, GIF, SVG, CDR, PDF, WORD, EXCEL, PPT, MP4, WEBP, OGG) Maks. 5mb"}
            file={file}
          />
          <CustomInput
            label={"Deadline Job Tutup"}
            warning={"*"}
            type={"date"}
            onChange={(e) => dataToSend.detilJobBudget.deadline = e.target.value}
            required={true}
          />
          <CustomInput
            label={"Budget Job"}
            warning={"*"}
            ponsel={true}
            labelPonsel={"IDR"}
            type={"number"}
            placeholder={"0"}
            onChange={(e) => dataToSend.detilJobBudget.budget = e.target.value}
            required={true}
          />
          <div className="pengalamanPendidikan-checkbox">
            <div className="pengalamanPendidikan-checkbox-ghost"></div>
            <div className="pengalamanPendidikan-checkbox-real">
              <CustomCheckbox
                id={`checkBox-negosiasi`}
                checked={negosiasiChecked}
                onChange={handleCheckedBox}
              />
              <label>Freelance Negosiasi</label>
            </div>
          </div>
          <div className="lengkapiProfil-bottom">
            <CustomInputButton
              color={true}
              value={"Selanjutnya"}
            />
            <CustomButton2
              onClick={handleBack}
              label={"Kembali"}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default DetilJobBudget;
