import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { useLocation} from 'react-router-dom';

import Sandi from "./screens/Sandi";
import Notifikasi from "./screens/Notifikasi";
import Kategori from "./screens/Kategori";

import { 
  dashboardFreelanceLink, 
  dashboardClientLink, 
  dashboardAdminLink, 
  dashboardModeratorLink 
} from "../../../../../config/dashboardLink";

import Navigasi from "../../../../../components/Navigasi";

import "./index.css";

const Pengaturan = () => {
  const location = useLocation();

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })
  const { userData } = useSelector((state) => state.auth);

  const params = new URLSearchParams(location.search);
  const initialState = params.get("state") || "KataSandi";
  const [selectedItem, setSelectedItem] = useState(initialState);

  return (
    <div className="dashboard-container">
      <div className="dashboard-wrap">
        {media ?
          <div className="dashboard-bar">
            <Navigasi
              title={"Dashboard"}
              data={userData.role === process.env.REACT_APP_ADMIN ? dashboardAdminLink : userData.role === process.env.REACT_APP_MODERATOR ? dashboardModeratorLink : userData.role === process.env.REACT_APP_FREELANCER ? dashboardFreelanceLink : dashboardClientLink}
              location={userData.role === process.env.REACT_APP_ADMIN ? 4 : userData.role === process.env.REACT_APP_MODERATOR ? 2 : userData.role === process.env.REACT_APP_FREELANCER ? 6 : 5}
            />
          </div>
          :
          null
        }
        <div className="dashboard-detail">
          <div className="dashboard-title">
            <h3>Pengaturan</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <div className="dashboard-item">
            <div className="dashboard-tab">
              <div
                className={`dashboard-tab-item ${selectedItem === "KataSandi" ? "clicked" : ""}`}
                onClick={() => setSelectedItem("KataSandi")}
              >
                Kata Sandi
              </div>
              {userData.role === process.env.REACT_APP_FREELANCER &&
                <div
                  className={`dashboard-tab-item ${selectedItem === "Kategori" ? "clicked" : ""}`}
                  onClick={() => setSelectedItem("Kategori")}
                >
                  Kategori
                </div>
              }
              <div
                className={`dashboard-tab-item ${selectedItem === "Notifikasi" ? "clicked" : ""}`}
                onClick={() => setSelectedItem("Notifikasi")}
              >
                Notifikasi
              </div>
            </div>
            <div className="dashboard-tab-item-line"></div>
            {selectedItem === "KataSandi" &&
              <Sandi />
            }
            {selectedItem === "Kategori" && userData.role === process.env.REACT_APP_FREELANCER &&
              <Kategori 
                selectedItem={selectedItem}
              />
            }
            {selectedItem === "Notifikasi" &&
              <Notifikasi 
                selectedItem={selectedItem}
              />
            }
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Pengaturan;