import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import { dashboardFreelanceLink, dashboardClientLink } from "../../../../../config/dashboardLink";

import Navigasi from "../../../../../components/Navigasi";
import RatingCard from "../../../../../components/RatingCard";

import axios from "../../../../../config/api";

import "./index.css";

const RatingSaya = () => {
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })
  const { userData } = useSelector((state) => state.auth);

  const [job, setJob] = useState([]);

  useEffect(() => {
    const getData = async () => {
      await axios.get(`/jobhub/rating`)
        .then(response => {
          setJob(response.data);
        })
        .catch(error => {
          console.log(error);
        })
    }

    getData();
  }, [])

  return (
    <div className="dashboard-container">
      <div className="dashboard-wrap">
        {media ?
          <div className="dashboard-bar">
            <Navigasi
              title={"Dashboard"}
              data={userData.role === process.env.REACT_APP_FREELANCER ? dashboardFreelanceLink : dashboardClientLink}
              location={userData.role === process.env.REACT_APP_FREELANCER ? 5 : 4}
            />
          </div>
          :
          null
        }
        <div className="dashboard-detail">
          <div className="dashboard-title">
            <h3>Rating</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <div className="dashboard-item">
            <div className="dashboard-grid">
              {job?.map((item, index) => (
                <div key={index} className="proyekSaya-detail-item">
                  <RatingCard item={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RatingSaya;