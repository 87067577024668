import React, { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';

import PortofolioSaya from "./screens/PortofolioSaya";

import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import Footer from "../../../components/Footer";

import { getProfile } from "../../../config/getProfile";

import "./index.css";

const PortofolioSayaPage = () => {
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const [open, setOpen] = useState(false);
  const [proses, setProses] = useState([]);
  const [setujui, setSetujui] = useState([]);
  const [thumbnailProses, setThumbnailProses] = useState([]);
  const [fileProyekProses, setFileProyekProses] = useState([]);
  const [thumbnailSetujui, setThumbnailSetujui] = useState([]);
  const [fileProyekSetujui, setFileProyekSetujui] = useState([]);

  useEffect(() => {
    const getData = async () => {
      await getProfile("portofolio")
        .then(response => {
          const filteredProses = response.data.filter(item => item.verifikasi === false);
          setProses(filteredProses);

          const newThumbnailProses = filteredProses.map((item) => {
            return `${process.env.REACT_APP_API_URL}${item.thumbnail}`
          })
          setThumbnailProses(newThumbnailProses);

          const newFileProyekProses = filteredProses.map((item) => {
            return `${process.env.REACT_APP_API_URL}${item.fileProyek}`
          })
          setFileProyekProses(newFileProyekProses);

          const filteredSetujui = response.data.filter(item => item.verifikasi === true);
          setSetujui(filteredSetujui);

          const newThumbnailSetujui = filteredSetujui.map((item) => {
            return `${process.env.REACT_APP_API_URL}${item.thumbnail}`
          })
          setThumbnailSetujui(newThumbnailSetujui);

          const newFileProyekSetujui = filteredSetujui.map((item) => {
            return `${process.env.REACT_APP_API_URL}${item.fileProyek}`
          })
          setFileProyekSetujui(newFileProyekSetujui);
        })
        .catch(error => {
          console.log(error);
        })
    }

    getData();
  }, [])

  return (
    <div className="dashboardPage-container">
      <div>
        <Navbar open={open} setOpen={setOpen} />
        {!media && (
          <Sidebar open={open} />
        )}
        <PortofolioSaya 
          proses={proses}
          thumbnailProses={thumbnailProses}
          setujui={setujui}
          thumbnailSetujui={thumbnailSetujui}
          setThumbnailSetujui={setThumbnailSetujui}
          fileProyekSetujui={fileProyekSetujui}
          setFileProyekSetujui={setFileProyekSetujui}
        />
      </div>
      <Footer />
    </div>
  )
}

export default PortofolioSayaPage;