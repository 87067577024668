import React from "react";

import "./index.css"

function CustomButton({ 
	label, 
	onClick,
	fullWidth
}) {
	return (
		<button className={`custom-button ${fullWidth ? "fullWidth" : ""}`} onClick={onClick}>
			{label}
		</button>
	)
}

export default CustomButton;