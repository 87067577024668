import React, { useEffect, useState } from "react";

import ProyekCard from "../../../../../../../components/ProyekCard";

import axios from "../../../../../../../config/api";

import "./index.css";
import "../../index.css";

const RiwayatProyek = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      await axios.get("/jobhub/proyeksaya?type=riwayat")
        .then(response => {
          setData(response.data);
        })
        .catch(error => {
          console.log(error);
        })
    }

    getData();
  }, [])

  return (
    data?.map((item, index) =>
      <div key={index} className="proyekSaya-detail-item">
        <ProyekCard item={item} />
      </div>
    )
  )
}

export default RiwayatProyek;