import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from 'react-redux';

import LandingPage from './pages/LandingPage';
import RegisterFreelancePage from "./pages/RegisterLoginPage/RegisterFreelancePage";
import RegisterClientPage from "./pages/RegisterLoginPage/RegisterClientPage";
import VerifikasiTerkirimPage from "./pages/VerifikasiPage/VerifikasiTerkirimPage";
import VerifikasiACCPage from "./pages/VerifikasiPage/VerifikasiACCPage";
import VerifikasiGagalPage from "./pages/VerifikasiPage/VerifikasiGagalPage";
import LoginPage from './pages/RegisterLoginPage/LoginPage';
import LengkapiProfilPage from "./pages/LengkapiProfilPage";
import UploadJobsPage from "./pages/JobsPage/UploadJobsPage";
import LihatJobsPage from './pages/JobsPage/LihatJobsPage';
import DetailJobsPage from './pages/JobsPage/DetailJobsPage';
import CheckStatusJobsPage from "./pages/JobsPage/CheckStatusJobsPage";
import ProfilSayaPage from "./pages/DashboardPage/ProfilSayaPage";
import PortofolioSayaPage from "./pages/DashboardPage/PortofolioSayaPage";
import ProyekSayaPage from "./pages/DashboardPage/ProyekSayaPage";
import DompetSayaPage from "./pages/DashboardPage/DompetSayaPage";
import AktivitasPage from "./pages/DashboardPage/AktivitasPage";
import PengaturanPage from "./pages/DashboardPage/PengaturanPage";
import RatingSayaPage from "./pages/DashboardPage/RatingSayaPage";
import ChatPage from "./pages/ChatPage";
import DashboardAdminPage from "./pages/DashboardPage/DashboardAdminPage";
import KelolaUserAdminPage from "./pages/DashboardPage/KelolaUserPage";
import KelolaModeratorAdminPage from "./pages/DashboardPage/KelolaModeratorPage";
import KelolaKategoriSubKategoriPage from "./pages/DashboardPage/KelolaKategoriSubKategoriPage";
import KelolaIndustriPage from "./pages/DashboardPage/KelolaIndustriPage";
import KelolaPortofolioPage from "./pages/DashboardPage/KelolaPortofolioPage";
import KelolaJobPage from "./pages/DashboardPage/KelolaJobPage";

import { ProtectedRoute } from "./config/ProtectedRoute";

import './App.css';
import './pages/DashboardPage/index.css';
import './pages/LengkapiProfilPage/index.css';
import './pages/RegisterLoginPage/index.css';
import './pages/VerifikasiPage/index.css';
import './pages/JobsPage/index.css';
import './components/NavbarIndikator/index.css';

function App() {
  const admin = process.env.REACT_APP_ADMIN;
  const moderator = process.env.REACT_APP_MODERATOR;
  const client = process.env.REACT_APP_CLIENT;
  const freelancer = process.env.REACT_APP_FREELANCER;
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<LandingPage />} />
            <Route path="/register-freelancer" element={<RegisterFreelancePage />} />
            <Route path="/register-client" element={<RegisterClientPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/lihat-jobs" element={<LihatJobsPage />} />
            <Route path="/lihat-jobs/:id" element={<DetailJobsPage />} />
            <Route path="/check-status/" element={<CheckStatusJobsPage />} />
            <Route path="/verifikasi-acc" element={ <VerifikasiACCPage /> } />
            <Route path="/verifikasi-gagal" element={ <VerifikasiGagalPage /> } />
            <Route
              path="/verifikasi"
              element={
                <ProtectedRoute role={[client, freelancer]}>
                  <VerifikasiTerkirimPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/lengkapi-profil"
              element={
                // <ProtectedRoute role={[client, freelancer]}>
                //   <LengkapiProfilPage />
                // </ProtectedRoute>
                <LengkapiProfilPage />
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute role={[admin]}>
                  <DashboardAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/profil-saya"
              element={
                <ProtectedRoute role={[client, freelancer]}>
                  <ProfilSayaPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/portofolio"
              element={
                <ProtectedRoute role={[client, freelancer]}>
                  <PortofolioSayaPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/proyek"
              element={
                <ProtectedRoute role={[client, freelancer]}>
                  <ProyekSayaPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/dashboard/dompet"}
              element={
                <ProtectedRoute role={[freelancer]}>
                  <DompetSayaPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/dashboard/keuangan"}
              element={
                <ProtectedRoute role={[client]}>
                  <DompetSayaPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/aktivitas"
              element={
                <ProtectedRoute role={[client, freelancer]}>
                  <AktivitasPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/pengaturan"
              element={
                <ProtectedRoute role={[admin, moderator, client, freelancer]}>
                  <PengaturanPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/rating"
              element={
                <ProtectedRoute role={[client, freelancer]}>
                  <RatingSayaPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/upload-jobs"
              element={
                <ProtectedRoute role={[client]}>
                  <UploadJobsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/kelola-portofolio"
              element={
                <ProtectedRoute role={[admin, moderator]}>
                  <KelolaPortofolioPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/kelola-job"
              element={
                <ProtectedRoute role={[admin, moderator]}>
                  <KelolaJobPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/kelola-kategori-subKategori"
              element={
                <ProtectedRoute role={[admin]}>
                  <KelolaKategoriSubKategoriPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/kelola-industri"
              element={
                <ProtectedRoute role={[admin]}>
                  <KelolaIndustriPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/kelola-user"
              element={
                <ProtectedRoute role={[admin, moderator]}>
                  <KelolaUserAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/kelola-moderator"
              element={
                <ProtectedRoute role={[admin, moderator]}>
                  <KelolaModeratorAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat"
              element={
                <ProtectedRoute role={[client, freelancer]}>
                  <ChatPage />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
