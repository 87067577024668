import React from "react";

import "./index.css";

const CustomButton4 = ({
  color,
  notAllowed,
  onClick,
  label,
}) => {
  const customButton4 = notAllowed ? "custom-button4 notAllowed" : color ? "custom-button4 color" : "custom-button4 transparant";

  return(
    <button 
      className={customButton4}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

export default CustomButton4;