import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";

import PortofolioModal from "../../../../../components/PortofolioModal";

import { ReactComponent as Plus } from '../../../../../assets/svg/Plus.svg';

import CustomButton3 from "../../../../../components/CustomButton3";
import Navigasi from "../../../../../components/Navigasi";
import { errorAlert } from "../../../../../components/Alert";
import PortofolioCard from "../../../../../components/PortofolioCard";

import { dashboardFreelanceLink } from "../../../../../config/dashboardLink";
import axios from "../../../../../config/api";
import { defaultPortofolio } from "../../../../../config/defaultData";
import { convertDate } from "../../../../../config/convertDate";

import "./index.css";

const PortofolioSaya = ({
  proses,
  thumbnailProses,
  setujui,
  thumbnailSetujui,
  setThumbnailSetujui,
  fileProyekSetujui,
  setFileProyekSetujui
}) => {
  const navigate = useNavigate();
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  var dataTmpRef = JSON.parse(JSON.stringify(defaultPortofolio));
  var dataTmp = JSON.parse(JSON.stringify(defaultPortofolio));

  dataTmpRef.judulProyek = {};
  dataTmpRef.deskripsi = {};
  dataTmpRef.kategori = {};
  dataTmpRef.subKategori = {};
  dataTmpRef.deadline = {};

  const dataToSendRef = useRef(dataTmpRef);
  const dataToSendTmp = useRef(dataTmp);

  const [isModalOpen, setModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dataIndex, setDataIndex] = useState(0);
  const [subKategoriIndex, setSubKategoriIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState("portofolioDiproses");
  const [kategori, setKategori] = useState([]);

  const openModal = (edit, index) => {
    setModalOpen(true);
    setIsEdit(edit);
    setDataIndex(index ? index : 0);
  }

  const closeModal = () => {
    setModalOpen(false);
    setIsEdit(false);
  }

  const deletePortofolio = async (id) => {
    try {
      await axios.delete(`/portofolio/${id}`)
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      navigate(0);
    }
  }

  useEffect(() => {
    if (isEdit && dataToSendRef.current) {
      const index = kategori.findIndex(item => item._id === setujui[dataIndex].kategori._id);
      const newDataToSendPortofolio = JSON.parse(JSON.stringify(setujui[dataIndex]))
      setSubKategoriIndex(index);

      const deadline = convertDate(setujui[dataIndex].deadline);

      dataToSendRef.current.judulProyek.value = setujui[dataIndex].judulProyek;
      dataToSendRef.current.deskripsi.value = setujui[dataIndex].deskripsi;
      dataToSendRef.current.kategori.value = setujui[dataIndex].kategori;
      dataToSendRef.current.subKategori.value = setujui[dataIndex].subKategori;
      dataToSendRef.current.deadline.value = deadline;

      dataToSendTmp.current = newDataToSendPortofolio;
      delete dataToSendTmp.current._id;
    }
  }, [isEdit])

  useEffect(() => {
    const getData = async() => {
      try{
        const response = await axios.get("/category");
        setKategori(response.data.kategori);
      } catch(error) {
        console.log(error);
      }
    }
    
    getData();
  },[])

  return (
    <div className="dashboard-container">
      <div className="dashboard-wrap">
        {media ?
          <div className="dashboard-bar">
            <Navigasi
              title={"Dashboard"}
              data={dashboardFreelanceLink}
              location={4}
            />
          </div>
          :
          null
        }
        <div className="dashboard-detail">
          <div className="dashboard-title">
            <h3>Portofolio</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <div className="dashboard-item">
            <div className="dashboard-tab">
              <div
                className={`dashboard-tab-item ${selectedItem === "portofolioDiproses" ? "clicked" : ""}`}
                onClick={() => setSelectedItem("portofolioDiproses")}
              >
                Portofolio diproses
              </div>
              <div
                className={`dashboard-tab-item ${selectedItem === "portofolioDisetujui" ? "clicked" : ""}`}
                onClick={() => setSelectedItem("portofolioDisetujui")}
              >
                Portofolio disetujui
              </div>
            </div>
            <div className="dashboard-tab-item-line"></div>
            <div className="dashboard-grid">
              {(selectedItem === "portofolioDiproses" ? proses : setujui)?.map((item, index) => (
                <PortofolioCard
                  key={index}
                  proses={selectedItem === "portofolioDiproses" ? true : false}
                  gambar={selectedItem === "portofolioDiproses" ? thumbnailProses[index] : thumbnailSetujui[index]}
                  judulProyek={item.judulProyek}
                  editOnClick={() => openModal(true, index)}
                  deleteOnClick={() => deletePortofolio(item._id)}
                />
              ))}
            </div>
            {selectedItem === "portofolioDiproses" &&
              <CustomButton3
                logo={<Plus className="isiDataTiga-tambah-svg" />}
                onClick={() => openModal(false)}
                label={"Tambah Portofolio"}
              />
            }
          </div>
        </div>
        <PortofolioModal
          isOpen={isModalOpen}
          isEdit={isEdit}
          dataToSendRef={dataToSendRef.current}
          dataToSendTmp={dataToSendTmp}
          closeModal={closeModal}
          id={setujui[dataIndex]?._id}
          dataIndex={dataIndex}
          thumbnailSetujui={thumbnailSetujui}
          setThumbnailSetujui={setThumbnailSetujui}
          fileProyekSetujui={fileProyekSetujui}
          setFileProyekSetujui={setFileProyekSetujui}
          subKategoriIndex={subKategoriIndex}
          setSubKategoriIndex={setSubKategoriIndex}
        />
      </div>
    </div>
  )
}

export default PortofolioSaya;