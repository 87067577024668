import React, { Fragment, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CustomButton2 from "../CustomButton2";
import CustomButton4 from "../CustomButton4";

import { ReactComponent as PesanButton } from '../../assets/svg/PesanButton.svg';

import { errorAlert } from "../Alert";
import Penawaran from "../Penawaran";

import axios from "../../config/api";
import { defaultFotoProfil } from "../../config/defaultData";
import { dateDifference } from "../../config/dateDifference";
import { HEADER_NO_COOKIE } from "../../config/headers";
import { defaultPenawaran } from "../../config/defaultData";

import "./index.css";

const AktivitasCard = ({ item }) => {
  var dataPenawaran = JSON.parse(JSON.stringify(defaultPenawaran));

  const navigate = useNavigate();

  const dataToSendRef = useRef(dataPenawaran);

  const { userData } = useSelector((state) => state.auth);

  const [loding, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const status = item.status === "ditolak" ? "ditolak" : "" 

  const openModal = () => {
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
  }

  const updateBanding = async (e, status) => {
    e.preventDefault();

    setLoading(true);

    try {
      await axios.put(`/bargain/banding/${item.pelamar._id}`, {
        status: status
      }, HEADER_NO_COOKIE);

      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }
  }

  const updateTawaran = async (e, status) => {
    e.preventDefault();

    setLoading(true);

    try {
      await axios.put(`/bargain/${item.pelamar._id}`, {
        status: status
      }, HEADER_NO_COOKIE);

      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }
  }
  return (
    <div
      className={`aktivitasCard-wrap ${status}`}
    >
      <div
        className={`aktivitasCard-detail ${status}`}
        onClick={() => item.tipe === "Proyek" || item.tipe === "Banding" ? 
          navigate(`/lihat-jobs/${item.job._id}`)
          : item.tipe === "Akun" ?
            navigate("/dashboard/profil-saya")
            : item.tipe === "Portofolio" ?
              navigate("/dashboard/portofolio-saya")
              : item.tipe === "SaldoMasuk" || item.tipe === "SaldoKeluar" ?
                navigate("/dashboard/dompet-saya")
                : null
        }
      >
        <img
          src={`${process.env.REACT_APP_API_URL}${item.pelamar.user[0] ? 
            item.pelamar.user[0].informasiProfil.fotoProfil 
            : item.author ? 
              item.author.informasiProfil.fotoProfil
              : userData.fotoProfil}`}
          alt="Foto Profil"
        />
        <div className={"aktivitasCard-detail-item"}>
          <h4>
            {
              item.pelamar.user[0] ?
                item.pelamar.user[0].informasiProfil.username
                : item.author ?
                  item.author.informasiProfil.username
                  : userData.username
            }
          </h4>
          <h5>{item.detail}</h5>
          {/* {
            process.env.REACT_APP_FREELANCER === userData.role ?
              item.pelamar.status === "Diterima" ?
                <h5>Job / Penawaran Anda pada proyek <b>{item.job.detilJobBudget.judulPekerjaan}</b> diterima. Silahkan untuk melanjutkannya via fitur chat yang tersedia.</h5>
                :
                item.pelamar.status === "Proses" ?
                  <h5>Client anda pada proyek <b>{item.job.detilJobBudget.judulPekerjaan}</b> menawarkan banding sebesar <b>IDR {comasNumber(item.lastRiwayatPenawaran.detilPembayaran.nilaiPekerjaan)}</b>. Apakah anda berkenan untuk menerima banding?</h5>
                  :
                  <h5>Oopps! Maaf, klien sudah mendapatkan kandidat pada proyek <b>{item.job.detilJobBudget.judulPekerjaan}</b>.</h5>
              :
              item.riwayatPenawaranLength > 1 ?
                <h5><b>{item.pelamar.user.informasiProfil.username}</b> pada proyek <b>{item.job.detilJobBudget.judulPekerjaan}</b> {item.lastRiwayatPenawaran.status === "Diterima" ? "menyetujui" : "Menolak"} penawaran banding anda sebesar <b>IDR {comasNumber(item.lastRiwayatPenawaran.detilPembayaran.nilaiPekerjaan)}</b>. Apakah anda berkenan untuk menerima?</h5>
                :
                <h5>Halo, ada yang baru daftar job di proyek <b>{item.job.detilJobBudget.judulPekerjaan}</b>. <b>{item.pelamar.user.informasiProfil.username}</b> telah melakukan penawaran sebesar <b>IDR {comasNumber(item.pelamar.penawaran)}</b>. Apakah anda berkenan untuk menerima?</h5>
          } */}
          <p>{dateDifference(item.waktu)}</p>
        </div>
      </div>
      {item.statusProses === "proses" &&
        <Fragment>
          <div className={"aktivitasCard-button"}>
            {item.status === "diterima" ?
              <CustomButton2
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/chat`)
                }}
                label={
                  <>
                    <PesanButton className="aktivitasCard-svg" />
                    Mulai percakapan
                  </>
                }
                color={true}
              />
              : process.env.REACT_APP_FREELANCER === userData.role ?
                <Fragment>
                  <CustomButton4
                    onClick={(e) => updateBanding(e, "Ditolak")}
                    label={"Tolak"}
                    notAllowed={true}
                  />
                  <CustomButton4
                    onClick={(e) => updateBanding(e, "Diterima")}
                    label={"Terima"}
                    color={true}
                  />
                </Fragment>
                :
                <Fragment>
                  <CustomButton4
                    onClick={(e) => updateTawaran(e, "Ditolak")}
                    label={"Tolak"}
                    notAllowed={true}
                  />
                  {item.job.detilJobBudget.freelanceNegosiasi &&
                    <CustomButton4
                      onClick={openModal}
                      label={"Ajukan Banding"}
                    />
                  }
                  <CustomButton4
                    onClick={(e) => updateTawaran(e, "Diterima")}
                    label={"Terima"}
                    color={true}
                  />
                </Fragment>
            }
          </div>
          <Penawaran
            isOpen={isModalOpen}
            closeModal={closeModal}
            idPelamar={item.pelamar._id}
            banding={true}
            dataToSendRef={dataToSendRef.current}
          />
        </Fragment>
      }
    </div>
  )
}

export default AktivitasCard;