import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import LihatJobs from "./screens/LihatJobs";
import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import Footer from "../../../components/Footer";

import "./index.css";

const LihatJobsPage = () => {
  const [open, setOpen] = useState(false);

  const media = useMediaQuery({
    query: "(min-width: 912px)",
  });

  return (
    <div className="lihatJobsPages-container">
      <Navbar open={open} setOpen={setOpen} />
      {!media && <Sidebar open={open} />}
      <LihatJobs />
      <Footer />
    </div>
  );
};

export default LihatJobsPage;
