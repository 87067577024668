import React, { useEffect, useRef, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import {isMobile} from 'react-device-detect';

import CustomCheckbox from "../../../../components/CustomCheckbox";
import CustomButton2 from "../../../../components/CustomButton2";

import { styleCircle, styleLabel } from "../../../../config/style";
import axios from "../../../../config/api";

import "./index.css";

const Minat = ({ style, setStyle, showPage, setShowPage, dataToSend }) => {
  const style1 = isMobile ? { width: "100vw" } : { width: "calc(100vw - 17px)" };
  const style2 = showPage.page1;

  const combinedStyle = {
    ...style1,
    ...style2,
  };

  const [kategori, setKategori] = useState([]);
  const [optionKategori, setOptionKategori] = useState([]);
  const [minimum, setMinimum] = useState(<></>);

  const handleKategori = (option) => {
    if (optionKategori.filter(e => e._id === option._id).length > 0) {
      const updatedValues = optionKategori.filter((v) => v._id !== option._id);
      setOptionKategori(updatedValues);

      const updatedValuesRef = dataToSend.minat.filter((v) => v.kategori !== option._id);
      dataToSend.minat = updatedValuesRef;
    } else {
      var temp = JSON.parse(JSON.stringify(option));

      dataToSend.minat.push({
        kategori: temp._id,
        subKategori: temp.subKategori
      });
      dataToSend.minat[dataToSend.minat.length-1].subKategori.length = 0;

      const newSubKategori = option.subKategori.map(item => ({
        _id: item._id,
        label: item.label,
        checked: false
      }));

      setOptionKategori([...optionKategori, {
        ...option,
        subKategori: newSubKategori
      }])
    }
  };

  const handleCheckboxChange = (option, index, subOption) => {
    if (subOption.checked){
      const updatedValuesRef = dataToSend.minat.map(item => ({
        ...item,
        subKategori: item.subKategori.filter(subItem => subItem !== subOption._id)
      }))
      dataToSend.minat = updatedValuesRef;
    }else
      dataToSend.minat[index].subKategori.push(subOption._id);

    const updateOption = optionKategori.map((item) => {
      if(item._id === option._id && Array.isArray(item.subKategori)) {
        return {
          ...item,
          subKategori: item.subKategori.map((subItem) => ({
            ...subItem,
            checked: subItem._id === subOption._id ? !subItem.checked : subItem.checked
          }))
        }
      } else
        return item;
    })

    setOptionKategori(updateOption);
  };

  const handleSubmit = () => {
    var count = dataToSend.minat.reduce((total, item) => total + item.subKategori.length, 0)
    if (dataToSend.minat.length >= 2 && count >= 6){
      let isDataComplete = true;
      for(let item of dataToSend.minat) {
        if(item.subKategori.length === 0) {
          isDataComplete = false;
          break
        }
      }
      if (isDataComplete) {
        scroll.scrollToTop({
          duration: 500,
        });
        setMinimum(<></>);
        setShowPage({
          ...showPage,
          page1: { "transform": "translateX(-100%)" },
          page2: { "transform": "translateX(-100%)" },
          page3: { "transform": "translateX(-100%)" },
          page4: { "transform": "translateX(-100%)" }
        })
        setStyle({
          ...style,
          styleCircle2: styleCircle,
          styleLabel2: styleLabel
        })
      } else {
        setMinimum(<label className="lengkapiProfil-wrap-error warning-text">*Sub kategori kosong</label>)
      }
    } else
      setMinimum(<label className="lengkapiProfil-wrap-error warning-text">*Minimum memilih 2 Kategori dan 6 Sub Kategori!</label>)
  }

  useEffect(() => {
    setStyle({
      ...style,
      styleCircle1: styleCircle,
      styleLabel1: styleLabel
    })

    const getData = async() => {
      try{
        const response = await axios.get("/category");
        setKategori(response.data.kategori);
      } catch(error) {
        console.log(error);
      }
    }
    
    getData();
  },[])

  return (
    <div 
      name="minat" 
      className="lengkapiProfil-container"
      style={combinedStyle}
    >
      <div className="lengkapiProfil-wrap">
        <div className="lengkapiProfil-title">
          <label>Pilih Kategori</label><p className="warning-text">*Minimum memilih 2 Kategori dan 6 Sub Kategori!</p>
        </div>
        <div className="minat-item">
          {kategori.map((optionDetail, indexDetail) => (
            <button 
              className={`minat-item-button ${
                optionKategori.filter(e => e._id === optionDetail._id).length > 0 ? 'clicked' : ''
              }`} 
              onClick={() => handleKategori(optionDetail)} 
              key={indexDetail}
            >
              {optionDetail.label}
            </button>
          ))}
        </div>
        <div className="lengkapiProfil-title">
          <label>Sub Kategori</label>
        </div>
        <div className="minat-subKategori">
          {optionKategori.map((optionDetail, indexDetail) => (
            <div className="minat-subKategori-wrap" key={indexDetail}>
              <label>{optionDetail.label}</label>
              {optionDetail.subKategori.map((optionSubKategori, indexSubKategori) => (
                <div key={indexSubKategori} className="minat-subKategori-wrap-item">
                  <label>{optionSubKategori.label}</label>
                  <CustomCheckbox 
                    id={optionSubKategori._id}
                    checked={optionSubKategori.checked}
                    onChange={() => handleCheckboxChange(optionDetail, indexDetail, optionSubKategori)}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="lengkapiProfil-bottom noForm">
          <CustomButton2
            color={true}
            onClick={handleSubmit}
            label={"Selanjutnya"}
          />
        </div>
        {minimum}
      </div>
    </div>
  )
}

export default Minat;