import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import { ReactComponent as PerusahaanTergabung } from "../../../../assets/svg/PerusahaanTergabung.svg";
import { ReactComponent as KlienSenang } from "../../../../assets/svg/KlienSenang.svg";
import { ReactComponent as ProyekSelesai } from "../../../../assets/svg/ProyekSelesai.svg";
import { ReactComponent as RegisterBlack } from "../../../../assets/svg/RegisterBlack.svg";

import CustomButton from "../../../../components/CustomButton";

import "./index.css";

const Home = () => {
  const media = useMediaQuery({
    query: "(min-width: 912px)",
  });

  const navigate = useNavigate();

  return (
    <div className="home-container">
      <div className="home-item-top">
        <div className="home-item-top-item">
          {media ? null : (
            <img
              className="home-image"
              src={"/images/homeImage.png"}
              alt="Gambar Home"
            />
          )}
          <h1>
            Find out<span> talented freelancer</span> with better review
            <span> faster</span>
          </h1>
          <h4>
            Explore highly skilled freelance experts. Acquire experienced
            professionals quickly. Quality guaranteed!
          </h4>
          <CustomButton label={"Masuk"} onClick={() => navigate("/login")} />
        </div>
        {media ? (
          <img
            className="home-image"
            src={"/images/homeImage.png"}
            alt="Gambar Home"
          />
        ) : null}
      </div>
      <div className="home-item-bottom">
        <div className="home-item-bottom-box">
          <div className="home-item-bottom-detail">
            <PerusahaanTergabung className="home-item-bottom-svg" />
            <div className="home-item-bottom-content">
              <h1>
                100<span>+</span>
              </h1>
              <label>Perusahaan Tergabung</label>
            </div>
          </div>
          <div className="home-item-bottom-detail">
            <ProyekSelesai className="home-item-bottom-svg" />
            <div className="home-item-bottom-content">
              <h1>
                100<span>+</span>
              </h1>
              <label>Proyek Selesai</label>
            </div>
          </div>
          <div className="home-item-bottom-detail">
            <KlienSenang className="home-item-bottom-svg" />
            <div className="home-item-bottom-content">
              <h1>
                100<span>+</span>
              </h1>
              <label>Klien Senang</label>
            </div>
          </div>
          <div className="home-item-bottom-detail">
            <RegisterBlack className="home-item-bottom-svg" />
            <div className="home-item-bottom-content">
              <h1>
                100<span>+</span>
              </h1>
              <label>Freelance Tergabung</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
