import React, { useState } from "react";

import "./index.css";

const CustomToggle = ({
  toggled,
  setToggled
}) => {
  
  return (
    <button
      className={`toggle-btn ${toggled ? "toggled" : ""}`}
      onClick={() => setToggled(!toggled)}
    >
      <div className="thumb"></div>
    </button>
  )
}

export default CustomToggle;