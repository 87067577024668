import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ReactComponent as HidePassword } from "../../../../../assets/svg/HidePassword.svg";
import { ReactComponent as SeePassword } from "../../../../../assets/svg/SeePassword.svg";

import { errorAlert } from "../../../../../components/Alert";
import CustomCheckbox from "../../../../../components/CustomCheckbox";
import CustonInput from "../../../../../components/CustomInput";

import axios from "../../../../../config/api";
import { HEADER_NO_COOKIE } from "../../../../../config/headers";
import { updateProfile } from "../../../../../config/authActions";

import "./index.css";

const DaftarFreelancer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [loading, setLoading] = useState(false);

  const dataToSendRef = useRef({
    nama: "",
    username: "",
    noTelepon: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const media = useMediaQuery({
    query: "(min-width: 912px)",
  });

  const togglePasswordVisibility = (e) => {
    e.type = e.type === "password" ? "text" : "password";
    setShowPassword({
      ...showPassword,
      [e.id]: !showPassword[e.id],
    });
  };

  const saveData = async (e) => {
    e.preventDefault();
    setLoading(true);
    const inputData = {
      nama: dataToSendRef.current.nama.value,
      username: dataToSendRef.current.username.value,
      noTelepon: dataToSendRef.current.noTelepon.value,
      email: dataToSendRef.current.email.value,
      password: dataToSendRef.current.password.value,
      confirmPassword: dataToSendRef.current.confirmPassword.value,
    };
    try {
      const response = await axios.post(
        `/register/freelancer`,
        inputData,
        HEADER_NO_COOKIE
      );

      dispatch(updateProfile(response.data));

      navigate("/verifikasi");
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="registerLogin-container">
      <div className="registerLogin-wrap">
        {media ? (
          <div className="registerLogin-wrap-image">
            <img src="/images/daftarFreelance.png" />
          </div>
        ) : null}
        <form
          action="#"
          onSubmit={saveData}
          className="registerLogin-wrap-form"
        >
          <h1>
            Daftar <label>sebagai Freelancer</label>
          </h1>
          {/* <h5>Lorem Ipsum dolor sit amet connecbetur. ac nutum in est reque grovida fau ibus magne ancu.</h5> */}
          <CustonInput
            regisLogin={true}
            label={"Nama Lengkap"}
            warning={"*"}
            type={"text"}
            placeholder={"Masukkan nama lengkap"}
            innerRef={(ref) => (dataToSendRef.current.nama = ref)}
            required={true}
          />
          <CustonInput
            regisLogin={true}
            label={"Username"}
            warning={"*"}
            type={"text"}
            placeholder={"Masukkan username"}
            innerRef={(ref) => (dataToSendRef.current.username = ref)}
            required={true}
          />
          <CustonInput
            regisLogin={true}
            label={"Email"}
            warning={"*"}
            type={"email"}
            placeholder={"Masukkan email"}
            innerRef={(ref) => (dataToSendRef.current.email = ref)}
            required={true}
          />
          <CustonInput
            regisLogin={true}
            label={"Nomor Ponsel"}
            warning={"*"}
            ponsel={true}
            labelPonsel={"+62"}
            type={"number"}
            placeholder={"Masukkan nomor ponsel"}
            innerRef={(ref) => (dataToSendRef.current.noTelepon = ref)}
            required={true}
          />
          <CustonInput
            regisLogin={true}
            label={"Kata Sandi"}
            warning={"*"}
            id={"password"}
            type={"password"}
            placeholder={"Masukkan kata sandi"}
            innerRef={(ref) => (dataToSendRef.current.password = ref)}
            required={true}
            svg={
              showPassword.password ? (
                <SeePassword
                  onClick={() =>
                    togglePasswordVisibility(dataToSendRef.current.password)
                  }
                  className="registerLogin-wrap-form-input-svg"
                />
              ) : (
                <HidePassword
                  onClick={() =>
                    togglePasswordVisibility(dataToSendRef.current.password)
                  }
                  className="registerLogin-wrap-form-input-svg"
                />
              )
            }
          />
          <CustonInput
            regisLogin={true}
            label={"Ulangi Kata Sandi"}
            warning={"*"}
            id={"confirmPassword"}
            type={"password"}
            placeholder={"Masukkan kata sandi"}
            innerRef={(ref) => (dataToSendRef.current.confirmPassword = ref)}
            required={true}
            svg={
              showPassword.confirmPassword ? (
                <SeePassword
                  onClick={() =>
                    togglePasswordVisibility(
                      dataToSendRef.current.confirmPassword
                    )
                  }
                  className="registerLogin-wrap-form-input-svg"
                />
              ) : (
                <HidePassword
                  onClick={() =>
                    togglePasswordVisibility(
                      dataToSendRef.current.confirmPassword
                    )
                  }
                  className="registerLogin-wrap-form-input-svg"
                />
              )
            }
          />
          <div className="daftar-wrap-form-syaratketentuan">
            <CustomCheckbox id={"checkBox"} required={true} />
            <div className="daftar-wrap-form-syaratketentuan-label">
              <p>
                Saya setuju dengan <span>Syarat & Ketentuan</span> serta{" "}
                <span>Kebijakan Privasi</span> yang berlaku.
              </p>
            </div>
          </div>
          <div className="registerLogin-wrap-button">
            {loading ? (
              <div>
                <BeatLoader color={"#E4E4E7"} size={16} />
              </div>
            ) : (
              <input type="submit" value="Daftar" />
            )}
          </div>
          <div className="registerLogin-wrap-login">
            <p>
              Sudah punya akun?{" "}
              <Link to={"/login"} relative="path">
                <label>Masuk</label>
              </Link>
            </p>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DaftarFreelancer;
