import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as HidePassword } from '../../../../../../../assets/svg/HidePassword.svg';
import { ReactComponent as SeePassword } from '../../../../../../../assets/svg/SeePassword.svg';

import CustomInput from "../../../../../../../components/CustomInput";
import CustomButton2 from "../../../../../../../components/CustomButton2";
import { errorAlert } from "../../../../../../../components/Alert";

import axios from "../../../../../../../config/api";
import { HEADER_NO_COOKIE } from "../../../../../../../config/headers";

import "./index.css";
import "../../index.css";

const Sandi = () => {
  const navigate = useNavigate();

  const dataToSendRef = useRef({
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  });

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false
  });
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = (e) => {
    e.type = e.type === 'password' ? 'text' : 'password';
    setShowPassword({
      ...showPassword,
      [e.id]: !showPassword[e.id]
    });
  };

  const handleChangePassword = async () => {
    setLoading(true);
    const inputData = {
      currentPassword: dataToSendRef.current.currentPassword.value,
      newPassword: dataToSendRef.current.newPassword.value,
      confirmPassword: dataToSendRef.current.confirmPassword.value
    }
    try {
      await axios.put(`/account/change-password`, inputData, HEADER_NO_COOKIE);
      navigate('/login');
      window.location.reload();
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="pengaturan-detail">
      <CustomInput
        regisLogin={true}
        label={"Kata Sandi Sekarang"}
        id={"currentPassword"}
        type={"password"}
        placeholder={"********"}
        innerRef={(ref) => (dataToSendRef.current.currentPassword = ref)}
        required={true}
        svg={showPassword.password ?
          <SeePassword onClick={() => togglePasswordVisibility(dataToSendRef.current.currentPassword)} className="registerLogin-wrap-form-input-svg" />
          :
          <HidePassword onClick={() => togglePasswordVisibility(dataToSendRef.current.currentPassword)} className="registerLogin-wrap-form-input-svg" />}
      />
      <CustomInput
        regisLogin={true}
        label={"Kata Sandi"}
        id={"newPassword"}
        type={"password"}
        placeholder={"********"}
        innerRef={(ref) => (dataToSendRef.current.newPassword = ref)}
        required={true}
        svg={showPassword.password ?
          <SeePassword onClick={() => togglePasswordVisibility(dataToSendRef.current.newPassword)} className="registerLogin-wrap-form-input-svg" />
          :
          <HidePassword onClick={() => togglePasswordVisibility(dataToSendRef.current.newPassword)} className="registerLogin-wrap-form-input-svg" />}
      />
      <CustomInput
        regisLogin={true}
        label={"Konfirmasi Kata Sandi Baru"}
        warning={"*"}
        id={"confirmPassword"}
        type={"password"}
        placeholder={"********"}
        innerRef={(ref) => (dataToSendRef.current.confirmPassword = ref)}
        required={true}
        svg={showPassword.confirmPassword ?
          <SeePassword onClick={() => togglePasswordVisibility(dataToSendRef.current.confirmPassword)} className="registerLogin-wrap-form-input-svg" />
          :
          <HidePassword onClick={() => togglePasswordVisibility(dataToSendRef.current.confirmPassword)} className="registerLogin-wrap-form-input-svg" />}
      />
      <div className="profilSaya-edit">
        <CustomButton2
          color={true}
          label={loading ?
            <BeatLoader
              color={"#E4E4E7"}
              size={10}
            />
            :
            "Reset Kata Sandi"
          }
          onClick={handleChangePassword}
        />
      </div>
      <ToastContainer />
    </div>
  )
}

export default Sandi;