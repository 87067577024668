import React, {useState} from "react";

import "./index.css";

const CustomInputSelect = ({
  label,
  subLabel,
  warning,
  regisLogin,
  firstRef,
  firstData,
  firstValue,
  firstOnChange,
  firstDisabled,
  secondRef,
  secondData,
  secondValue,
  secondOnChange,
  secondDisabled,
  double
}) => {
  const customInputImageContainer = regisLogin ? "custom-input-image-container" : "custom-input-image-container noRegisLogin";
  const customInputLabel = regisLogin ? "custom-input-label" : "custom-input-label noRegisLogin";
  const customInputImageWrap = regisLogin ? "custom-input-select-wrap" : "custom-input-select-wrap noRegisLogin";

  return (
    <div className={customInputImageContainer}>
      <div className={customInputLabel}>
        <label className="custom-input-label-title">{label}</label>
        <label className="custom-input-label-warning warning-text">{warning}</label>
      </div>
      <div className="custom-input-select-container">
        <label className="custom-input-sublabel-title">{subLabel}</label>
        <div className={customInputImageWrap}>
          <div className="custom-input-select-item">
            <select
              ref={firstRef}
              value={firstValue}
              onChange={firstOnChange}
              disabled={firstDisabled}
            >
              {firstData?.map((firstData, index) => (
                <option key={index} value={firstData._id}>
                  {firstData.label}
                </option>
              ))}
            </select>
          </div>
          {double &&
            <div className="custom-input-select-item">
              <select
                ref={secondRef}
                value={secondValue}
                onChange={secondOnChange}
                disabled={secondDisabled}
              >
                {secondData.map((secondData, index) => (
                  <option key={index} value={secondData._id}>
                    {secondData.label}
                  </option>
                ))}
              </select>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default CustomInputSelect;