import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import BeatLoader from "react-spinners/BeatLoader";

import {errorAlert} from "../Alert";
import CustomInput from "../CustomInput";
import CustomButton2 from "../CustomButton2";
import CustomInputImage from "../CustomInputImage";

import { ReactComponent as Upload } from '../../assets/svg/Upload.svg';

import { HEADER_NO_COOKIE, HEADER_NO_COOKIE_FORM_DATA } from "../../config/headers";
import { changeImage } from "../../config/changeImage";
import axios from "../../config/api";

import "./index.css";

const KategoriModal = ({ 
  type,
  isOpen, 
  isEdit,
  dataEdit,
  closeModal,
  kategoriId
}) => {
  const navigate = useNavigate();

  const dataToSendRef = useRef({
    label: "",
  });
  const imageRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [subKategoriSVG, setSubKategoriSVG] = useState(null);

  useEffect(() => {
    if (isEdit) {
      dataToSendRef.current.label.value = dataEdit.label;
    }
  },[isEdit])

  if(!isOpen) return null;

  const handleChangeImageClick = (ref) => {
    ref.click();
  }

  const tambahKategori = async(e) => {
    e.preventDefault();
    setLoading(true);
    const inputData = {
      kategori: dataToSendRef.current.label.value,
    }
    try {
      await axios.post(`/category`, inputData, HEADER_NO_COOKIE);
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }

    closeModal();
  }

  const tambahSubKategori = async(e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    if(subKategoriSVG) {
      const subKategoriSVGBlob = await fetch(subKategoriSVG).then(response => response.blob());
      formData.append('subKategoriSVG', subKategoriSVGBlob);
    }

    formData.append("subKategori", JSON.stringify(dataToSendRef.current.label.value));

    try {
      await axios.post(`/category/subCategory/${dataEdit._id}`, formData, HEADER_NO_COOKIE_FORM_DATA);
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setSubKategoriSVG(null);
      setLoading(false);
    }

    closeModal();
  }

  const ubahKategori = async(e) => {
    e.preventDefault();
    setLoading(true);
    const inputData = {
      kategori: dataToSendRef.current.label.value,
    }
    try {
      await axios.put(`/category/${dataEdit._id}`, inputData, HEADER_NO_COOKIE);
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }

    closeModal();
  }

  const ubahSubKategori = async(e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();

    if(subKategoriSVG) {
      const subKategoriSVGBlob = await fetch(subKategoriSVG).then(response => response.blob());
      formData.append('subKategoriSVG', subKategoriSVGBlob);
    }

    formData.append("subKategori", JSON.stringify(dataToSendRef.current.label.value));

    try {
      await axios.put(`/category/subCategory/${kategoriId}/${dataEdit._id}`, formData, HEADER_NO_COOKIE_FORM_DATA);
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setSubKategoriSVG(null);
      setLoading(false);
    }

    closeModal();
  }

  return (
    <div className="moderator-container">
      <div className="moderator-wrap">
        <form className="moderator-item">
          <CustomInput
            label={`Nama ${type === "kategori" ? "Kategori" : "Sub Kategori"}`}
            warning={"*"}
            type={"text"}
            placeholder={`Masukkan nama ${type === "kategori" ? "Kategori" : "Sub Kategori"}`}
            innerRef={(ref) => (dataToSendRef.current.label = ref)}
            required={true}
          />
          {type === "subKategori" &&
            <CustomInputImage
              onClick={() => handleChangeImageClick(imageRef.current)}
              src={isEdit ? subKategoriSVG ? subKategoriSVG : `/svg/${kategoriId}/${dataEdit._id}.svg` : subKategoriSVG}
              logo={<Upload className="lengkapiProfil-tambah-svg" />}
              label={"Upload gambar sub kategori"}
              innerRef={imageRef}
              onChange={(e) => changeImage(e, setSubKategoriSVG, 1, true)}
              required={true}
              description={"Format (SVG) Maks. 1mb"}
            />
          }
          <div className="moderator-bottom">
            <CustomButton2
              color={true}
              label={loading ?
                <BeatLoader
                  color={"#E4E4E7"}
                  size={10}
                />
                :
                isEdit ? `Ubah ${type === "kategori" ? "Kategori" : "Sub Kategori"}` : `Tambah ${type === "kategori" ? "Kategori" : "Sub Kategori"}`
              }
              onClick={
                isEdit ? 
                  type === "kategori" ? ubahKategori : ubahSubKategori 
                : 
                  type === "kategori" ? tambahKategori : tambahSubKategori
              }
            />
            <CustomButton2
              onClick={() => closeModal()}
              label={"Batal"}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default KategoriModal;