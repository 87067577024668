import { useState } from "react";
import { useMediaQuery } from 'react-responsive';

import Home from "./screens/Home";
import Kategori from "./screens/Kategori";
import Rekomendasi from "./screens/Rekomendasi";

import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";

const LandingPage = () => {
  const [open, setOpen] = useState(false);

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  return (
    <div>
      <Navbar open={open} setOpen={setOpen}/>
      {!media && (
        <Sidebar open={open} />
      )}
      <Home />
      <Kategori />
      <Rekomendasi />
      <Footer />
    </div>
  )
}

export default LandingPage