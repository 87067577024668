import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';

import Pengaturan from "./screens/Pengaturan";

import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import Footer from "../../../components/Footer";

import "./index.css";

const PengaturanPage = () => {
  const [open, setOpen] = useState(false);

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  return (
    <div className="dashboardPage-container">
      <div>
        <Navbar open={open} setOpen={setOpen} />
        {!media && (
          <Sidebar open={open} />
        )}
        <Pengaturan />
      </div>
      <Footer />
    </div>
  )
}

export default PengaturanPage;