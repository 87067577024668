import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import ProyekBerjalan from "./screens/ProyekBerjalan";
import RiwayatProyek from "./screens/RiwayatProyek";

import { ReactComponent as PlusWhite } from '../../../../../assets/svg/PlusWhite.svg';

import { dashboardFreelanceLink, dashboardClientLink } from "../../../../../config/dashboardLink";

import Navigasi from "../../../../../components/Navigasi";

import "./index.css";

const ProyekSaya = () => {
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const { userData } = useSelector((state) => state.auth);

  const [selectedItem, setSelectedItem] = useState("ProyekTerdaftar");

  return (
    <div className="dashboard-container">
      <div className="dashboard-wrap">
        {media ?
          <div className="dashboard-bar">
            <Navigasi
              title={"Dashboard"}
              data={userData.role === process.env.REACT_APP_FREELANCER ? dashboardFreelanceLink : dashboardClientLink}
              location={1}
            />
          </div>
          :
          null
        }
        <div className="dashboard-detail">
          <div className="dashboard-title">
            <h3>Proyek</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <div className="dashboard-title kelola-moderator-title">
            <div></div>
            <Link
              className="proyekSaya-extend-link"
              to="/dashboard/upload-jobs"
              relative="path"
              reloadDocument
            >
              <PlusWhite className="dashboard-moderator-button-svg" />
              Proyek
            </Link>
          </div>
          <div className="dashboard-item">
            <div className="dashboard-tab">
              <div
                className={`dashboard-tab-item ${selectedItem === "ProyekTerdaftar" ? "clicked" : ""}`}
                onClick={() => setSelectedItem("ProyekTerdaftar")}
              >
                {userData.role === process.env.REACT_APP_FREELANCER ?
                  "Proyek Terdaftar"
                  :
                  "Proyek Berjalan"
                }
              </div>
              <div
                className={`dashboard-tab-item ${selectedItem === "RiwayatProyek" ? "clicked" : ""}`}
                onClick={() => setSelectedItem("RiwayatProyek")}
              >
                Riwayat Proyek
              </div>
            </div>
            <div className="dashboard-tab-item-line"></div>
            <div className="dashboard-grid">
              {selectedItem === "ProyekTerdaftar" ?
                <ProyekBerjalan />
                :
                <RiwayatProyek />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProyekSaya;