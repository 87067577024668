import React, { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import { errorAlert } from "../../../../../components/Alert";

import CountDown from "../../../../../config/CountDown";
import axios from "../../../../../config/api";

import "./index.css";

const VerifikasiTerkirim = () => {
  const navigate = useNavigate();

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const { userData } = useSelector((state) => state.auth);

  const [time, setTime] = useState(null);
  const [loading, setLoading] = useState(false);

  const sendToken = async () => {
    if (!time) {
      setLoading(true);
      try {
        await axios.get(`/register/token`);
        navigate(0);
      } catch (error) {
        errorAlert(error.response.data.pesan);
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/register/expiration');
        if(parseInt(response.data.pesan) >= 0) setTime(response.data.pesan);
      } catch (error) {
        console.error('Error fetching TTL:', error.message);
      }
    };

    fetchData();
  },[])

  return (
    <div className="verifikasi-container">
      <div className="verifikasi-wrap">
        <div className="verifikasi-wrap-image">
          {media &&
            <img className="verifikasiTerkirim-wrap-image-1" src="/images/emailVerifikasiLine.png" />
          }
          <img className="verifikasi-wrap-image-2" src="/images/emailVerifikasi.png" />
        </div>
        <h1>Verifikasi Terkirim!</h1>
        <h5>Telah mendaftar sebagai {userData.role === process.env.REACT_APP_CLIENT ? "Client" : "Freelancer"}. Silahkan untuk memeriksa email yang telah Anda daftarkan. kami telah mengirimkan email verifikasi ke alamat email ntuk menyelesaikan proses verifikasi.</h5>
        <div 
          className={`verifikasi-wrap-button ${time ? "disable" : ""}`}
          onClick={sendToken}
        >
          {loading ?
            <div>
              <BeatLoader
                color={"#E4E4E7"}
                size={12}
              />
            </div>
            :
            "Kirim Ulang Kode"
          }
        </div>
        <label className="verifikasi-count">
          {time !== null ? (
            <CountDown initialTime={time} onTimeOver={setTime}/>
          ) : "00:00"
          }
        </label>
      </div>
    </div>
  );
}

export default VerifikasiTerkirim;