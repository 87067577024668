import React, { useRef, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as NextPage } from '../../assets/svg/NextPage.svg';
import { ReactComponent as PreviousPage } from '../../assets/svg/PreviousPage.svg';

import "./index.css";

const Pagination = ({ 
  totalPages, 
  currentPage, 
  onClickPage, 
  onClickNext, 
  onClickPrevious 
}) => {
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const svgPreviouseRef = useRef(null);
  const svgNextRef = useRef(null);

  const renderPagination = () => {
    const displayPages = [];
    const maxVisiblePages = 10;
    const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        displayPages.push(
          <div 
            key={i} 
            className={`pagination-page ${currentPage === i ? 'clicked' : ''}`}
            onClick={() => onClickPage(i)}>
            {i}{' '}
          </div>
        );
      }
    } else {
      if (currentPage <= halfMaxVisiblePages) {
        for (let i = 1; i <= maxVisiblePages - 1; i++) {
          displayPages.push(
            <div 
              key={i}
              className={`pagination-page ${currentPage === i ? 'clicked' : ''}`} 
              onClick={() => onClickPage(i)}
            >
              {i}{' '}
            </div>
          );
        }
        displayPages.push(
          <div 
            key="ellipsis"
            className={"pagination-page"} 
            onClick={() => onClickPage(totalPages)}
          >
            ...{' '}
          </div>
        );
        displayPages.push(
          <div 
            key={totalPages}
            className={"pagination-page"} 
            onClick={() => onClickPage(totalPages)}
          >
            {totalPages}{' '}
          </div>
        );
      } else if (currentPage >= totalPages - halfMaxVisiblePages) {
        displayPages.push(
          <div 
            key={1} 
            className={"pagination-page"}
            onClick={() => onClickPage(1)}
          >
            1{' '}
          </div>
        );
        displayPages.push(
          <div 
            key="ellipsis" 
            className={"pagination-page"}
            onClick={() => onClickPage(1)}
          >
            ...{' '}
          </div>
        );
        for (let i = totalPages - maxVisiblePages + 2; i <= totalPages; i++) {
          displayPages.push(
            <div 
              key={i} 
              className={`pagination-page ${currentPage === i ? 'clicked' : ''}`}
              onClick={() => onClickPage(i)}
            >
              {i}{' '}
            </div>
          );
        }
      } else {
        displayPages.push(
          <div 
            key={1} 
            className={"pagination-page"}
            onClick={() => onClickPage(1)}
          >
            1{' '}
          </div>
        );
        displayPages.push(
          <div 
            key="ellipsis1" 
            className={"pagination-page"}
            onClick={() => onClickPage(currentPage - halfMaxVisiblePages + 2)}
          >
            ...{' '}
          </div>
        );
        for (let i = currentPage - halfMaxVisiblePages + 2; i <= currentPage + halfMaxVisiblePages - 2; i++) {
          displayPages.push(
            <div 
              key={i} 
              className={`pagination-page ${currentPage === i ? 'clicked' : ''}`}
              onClick={() => onClickPage(i)}
            >
              {i}{' '}
            </div>
          );
        }
        displayPages.push(
          <div 
            key="ellipsis2" 
            className={"pagination-page"}
            onClick={() => onClickPage(totalPages)}
          >
            ...{' '}
          </div>
        );
        displayPages.push(
          <div 
            key={totalPages} 
            className={"pagination-page"}
            onClick={() => onClickPage(totalPages)}
          >
            {totalPages}{' '}
          </div>
        );
      }
    }

    return displayPages;
  };

  useEffect(() => {
    if (media) {
      if (svgPreviouseRef.current) {
        svgPreviouseRef.current.setAttribute('width', "28");
        svgPreviouseRef.current.setAttribute('height', "28");
      }

      if (svgNextRef.current) {
        svgNextRef.current.setAttribute('width', "28");
        svgNextRef.current.setAttribute('height', "28");
      }
    } else {
      if (svgPreviouseRef.current) {
        svgPreviouseRef.current.setAttribute('width', "24");
        svgPreviouseRef.current.setAttribute('height', "24");
      }

      if (svgNextRef.current) {
        svgNextRef.current.setAttribute('width', "24");
        svgNextRef.current.setAttribute('height', "24");
      }
    }
  },[svgNextRef.current, svgPreviouseRef.current])

  return (
    <div className='pagination-wrap'>
      <PreviousPage 
        ref={svgPreviouseRef} 
        className="lihatJobs-page-svg" 
        onClick={onClickPrevious}
      />
      {renderPagination()}
      <NextPage 
        ref={svgNextRef} 
        className="lihatJobs-page-svg" 
        onClick={onClickNext}
      />
    </div>
  );
};

export default Pagination;