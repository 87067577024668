import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';

import DompetSaya from "./screens/DompetSaya";

import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import Footer from "../../../components/Footer";

import "./index.css";

const DompetSayaPage = () => {
  const [open, setOpen] = useState(false);

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  return (
    <div className="dashboardPage-container">
      <Navbar open={open} setOpen={setOpen}/>
      {!media && (
        <Sidebar open={open} />
      )}
      <DompetSaya />
      <Footer />
    </div>
  )
}

export default DompetSayaPage;