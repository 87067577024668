import React, { Fragment, useEffect, useRef, useState } from "react";
import { ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

import { ReactComponent as Upload } from '../../assets/svg/Upload.svg';

import { errorAlert } from "../../components/Alert";
import CustomInput from "../../components/CustomInput";
import CustomInputImage from "../../components/CustomInputImage";
import CustomInputButton from "../../components/CustomInputButton";
import CustomButton2 from "../../components/CustomButton2";

import axios from "../../config/api";
import { HEADER_NO_COOKIE, HEADER_NO_COOKIE_FORM_DATA } from "../../config/headers";
import { defaultPenawaran } from "../../config/defaultData";
import { changeImage } from "../../config/changeImage";

import "./index.css";

const Penawaran = ({
  id,
  idPelamar,
  penyedia,
  isOpen,
  isEdit,
  isDirekrut,
  closeModal,
  dataToSendRef,
  dataDetilPembayaran,
  dokumenPenawaran,
  banding
}) => {
  const navigate = useNavigate();

  var dataPenawaran = JSON.parse(JSON.stringify({ ...defaultPenawaran, penyedia: penyedia }));

  const dataToSendTmp = useRef(dataPenawaran);
  const dokumenPenawaranRef = useRef(null);

  const [dokumenPenawaranTmp, setDokumenPenawaranTmp] = useState(null);
  const [penawaran, setPenawaran] = useState(0);
  const [loding, setLoading] = useState(false);

  if (!isOpen) return null;

  const handlePenawaran = (e) => {
    dataToSendTmp.current.penawaran = e.target.value;
    setPenawaran(e.target.value);
  }

  const handleChangeImageClick = (ref) => {
    ref.click();
  };
  const ajukanBanding = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      await axios.post(`/bargain/banding/${idPelamar}`, {
        banding: penawaran
      }, HEADER_NO_COOKIE);

      closeModal();
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }
  }

  const uploadTawaran = async (e) => {
    e.preventDefault();

    setLoading(true);
    const formData = new FormData();

    const dokumenPenawaranBlob = await fetch(dokumenPenawaranTmp).then(response => response.blob());

    formData.append("dokumenPenawaran", dokumenPenawaranBlob);
    formData.append("tawaran", JSON.stringify(dataToSendTmp.current));

    try {
      await axios.post(`/bargain/${id}`, formData, HEADER_NO_COOKIE_FORM_DATA);
      
      var dataTmpTmp = JSON.parse(JSON.stringify(defaultPenawaran));
      dataToSendTmp.current = dataTmpTmp;
      setDokumenPenawaranTmp(null);
      
      closeModal();
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }
  }

  const terimaTawaran = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      await axios.put(`/bargain/${idPelamar}`, {
        status: "Diterima",
        tawaran: dataDetilPembayaran.nilaiPekerjaan
      }, HEADER_NO_COOKIE);
      closeModal();
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }
  }
  
  return (
    <div className="penawaran-container">
      <div className="penawaran-wrap">
        <form onSubmit={banding ? ajukanBanding : isEdit ? terimaTawaran : uploadTawaran} className="penawaran-item">
          <CustomInput
            label={"Penawaran Anda"}
            warning={"*"}
            ponsel={true}
            labelPonsel={"IDR"}
            type={"number"}
            placeholder={"Masukkan penawaran anda"}
            onChange={(e) => handlePenawaran(e)}
            required={true}
            readOnly={isEdit}
            disabled={isEdit}
            innerRef={(ref) => dataToSendRef.penawaran = ref}
          />
          <div className="custom-input-wrap noRegisLogin">
            <div className="custom-input-label noRegisLogin">
              <label className="custom-input-label-title">Detil Pembayaran</label>
              <label className="custom-input-label-warning warning-text">*</label>
            </div>
            <div className="custom-input-item non-radio noRegisLogin">
              <div className="custom-input-input penawaran">
                <div className="penawaran-detil-item">
                  <label>Nilai Pekerjaan</label>
                  <label>{isEdit ? dataDetilPembayaran.nilaiPekerjaan : penawaran} IDR</label>
                </div>
                <div className="penawaran-detil-item ppn">
                  <label>PPn 11%</label>
                  <label>{isEdit ? dataDetilPembayaran.ppn : penawaran * 0.11} IDR</label>
                </div>
                <div className="penawaran-detil-item">
                  <label>Saldo Akhir</label>
                  <label>{isEdit ? dataDetilPembayaran.saldoAkhir : penawaran - (penawaran * 0.11)} IDR</label>
                </div>
              </div>
            </div>
          </div>
          {!banding &&
            <Fragment>
              <CustomInput
                input={"textarea"}
                label={"Alasan Penawaran"}
                warning={"*"}
                placeholder={"Alasan penawaran terkait job yang ingin di apply dan harga yang ingin diajukan..."}
                rows={10}
                onChange={(e) => (dataToSendTmp.current.alasan = e.target.value)}
                required={true}
                readOnly={isEdit}
                disabled={isEdit}
                innerRef={(ref) => dataToSendRef.alasan = ref}
              />
              <CustomInputImage
                title={"Dokumen Tambahan (opsional)"}
                onClick={() => handleChangeImageClick(dokumenPenawaranRef.current)}
                src={dokumenPenawaranTmp ? dokumenPenawaranTmp : dokumenPenawaran}
                logo={<Upload className="isiDataDua-item-kartu-svg" />}
                label={"Upload file tambahan"}
                innerRef={dokumenPenawaranRef}
                onChange={(e) => changeImage(e, setDokumenPenawaranTmp, 5, true, true, true)}
                required={true}
                readOnly={isEdit}
                disabled={isEdit}
                description={"Format (JPG, JPEG, PNG, GIF, SVG, CDR, PDF, WORD, EXCEL, PPT, MP4, WEBP, OGG) Maks. 5mb"}
              />
            </Fragment>
          }
          <div className="penawaran-bottom">
            {loding ?
              <CustomButton2
                color={true}
                label={
                  <BeatLoader
                    color={"#E4E4E7"}
                    size={10}
                  />
                }
              />
              :
              isDirekrut ?
                null
                :
                banding ?
                  <CustomInputButton
                    color={true}
                    value={"Ajukan Banding"}
                  />
                  :
                  isEdit ?
                    <CustomInputButton
                      color={true}
                      value={"Terima Penawaran"}
                    />
                    :
                    <CustomInputButton
                      color={true}
                      value={"Kirim Penawaran"}
                    />
            }
            <CustomButton2
              onClick={closeModal}
              label={"Batal"}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Penawaran;