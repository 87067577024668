import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getCookie } from "./cookies";

export const ProtectedRoute = ({ role, children }) => {
  const { userData } = useSelector((state) => state.auth);
  const cookie = getCookie("connect");

  if (cookie && userData.verifikasiEmail !== null) {
    if (userData.verifikasiEmail || children.type.name === "VerifikasiTerkirimPage") {
      if (children.type.name === "LengkapiProfilPage") {
        if (!userData.verifikasiKelengkapanData) {
          return children;
        } else {
          return <Navigate to="/dashboard/profil-saya" replace />;
        }
      } else {
        const hasRole = role.includes(userData.role);
        if (hasRole) {
          if (children.type.name === "UploadJobsPage") {
            if (!userData.verifikasiProfil) {
              return <Navigate to="/dashboard/profil-saya" replace />;
            } else {
              return children;
            }
          } else {
            return children;
          }
        } else {
          return <Navigate to="/dashboard/profil-saya" replace />;
        }
      }
    } else {
      return <Navigate to="/verifikasi" replace />;
    }
  } else {
    return <Navigate to="/login" replace />;
  }
};
