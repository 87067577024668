import { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import VerifikasACC from "./screens/VerifikasACC";

import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import Footer from "../../../components/Footer";

import { updateVerifikasi } from "../../../config/authActions";
import { getProfile } from "../../../config/getProfile";

const VerifikasiACCPage = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  useEffect(() => {
    const getData = async() => {
      await getProfile("verifikasi")
        .then(response => {
          if(response.data.verifikasiEmail) {
            const userData = {
              verifikasiEmail: response.data.verifikasiEmail,
              verifikasiKelengkapanData: response.data.verifikasiKelengkapanData
            }
            dispatch(updateVerifikasi(userData));
          } else return <Navigate to="/verifikasi-gagal" replace />
        })
        .catch(error => {
          console.log(error);
        })
    }

    getData();
  },[])

  return (
    <div>
      <Navbar open={open} setOpen={setOpen} />
      {!media &&
        <Sidebar open={open} />
      }
      <VerifikasACC />
      <Footer />
    </div>
  )
}

export default VerifikasiACCPage;