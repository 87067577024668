import { errorAlert } from "../components/Alert";

export const changeImage = (event, change, maxSize, imageCheck, documentCheck, videoCheck, setImageUrl) => {
  const file = event.target.files[0];
  if (file) {
    let isValidType = false;

    if (imageCheck && !isValidType) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];

      isValidType = validImageTypes.includes(file.type);
    }

    if (documentCheck && !isValidType) {
      const validDocumentTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/x-cdr'
      ];

      isValidType = validDocumentTypes.includes(file.type);
    }

    if (videoCheck && !isValidType) {
      const validVideoTypes = ['video/mp4', 'video/webm', 'video/ogg'];

      isValidType = validVideoTypes.includes(file.type);
    }

    const maxSizeTmp = maxSize * 1024 * 1024;

    if (file.size <= maxSizeTmp && isValidType) {
      const reader = new FileReader();
    
      reader.onload = (e) => {
        if (
          file.type.includes('image') || 
          file.type.includes('application/pdf') || 
          file.type.includes('application/x-cdr') ||
          file.type.includes('video')
        ) {
          const imageUrl = URL.createObjectURL(file);
          change(imageUrl);
        } else if (
          file.type.includes('application/msword') || 
          file.type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
          file.type.includes('application/vnd.ms-powerpoint') ||
          file.type.includes('application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
          file.type.includes('application/vnd.ms-excel') ||
          file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        ) {
          const arrayBuffer = e.target.result;
          const blob = new Blob([arrayBuffer], { type: file.type });
          const url = URL.createObjectURL(blob);
  
          fetch(`${url}#page=1`)
            .then(response => response.blob())
            .then(blob => {
              const imageBlobUrl = URL.createObjectURL(blob);
              setImageUrl(imageBlobUrl);
            })
            .catch(error => console.error('Error fetching image:', error));
        } else {
          console.error('Unsupported file type');
        }
      };
  
      reader.readAsArrayBuffer(file);
    } else {
      if (file.size > maxSizeTmp) {
        errorAlert(`Ukuran berkas melebihi batas maksimal (${(maxSizeTmp / 1024 / 1024)}MB).`);
      } else {
        errorAlert('Pilih berkas gambar yang valid.');
      }
    }
  }
};