import React from "react";
import { useMediaQuery } from "react-responsive";
import { ReactSVG } from "react-svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as filledStar } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as Clock } from '../../assets/svg/Clock.svg';
import { ReactComponent as DeadLine } from '../../assets/svg/Deadline.svg';
import { ReactComponent as Pelamar } from '../../assets/svg/Pelamar.svg';
import { ReactComponent as Price } from '../../assets/svg/Price.svg';
import { ReactComponent as Seleksi } from '../../assets/svg/Seleksi.svg';

import { convertDate } from "../../config/convertDate";
import { dateDifference } from "../../config/dateDifference";

import "./index.css";

const RekomendasiCard = ({ data, onClick }) => {
	const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

	const size = media ? 70 : 45;
	const deadline = convertDate(data.job.detilJobBudget.deadline);
	const date = dateDifference(data.job.waktu);

	return (
		<div className="rekomendasi-card-wrap" onClick={onClick}>
			<ReactSVG
				src={`/svg/${data.job.detilJobBudget.kategori._id}/${data.job.detilJobBudget.subKategori._id}.svg`}
				beforeInjection={(svg) => {
					svg.setAttribute('style', `width: ${size}px; height: ${size}px`);
					svg.children[0].setAttribute('fill', '#AB1F2A');
				}}
			/>
			<div className="rekomendasi-card-detail">
				<div className="rekomendasi-card-detail-item">
					<div className="rekomendasi-card-detail-title">
						<p className="rekomendasi-card-detail-title-judul">{data.job.detilJobBudget.judulPekerjaan}</p>
						{data.job.rekomendasi &&
							<FontAwesomeIcon icon={filledStar} className="rekomendasi-card-detail-title-icon" />
						}
					</div>
					<div className="rekomendasi-card-detail-subtitle">
						<p className="rekomendasi-card-detail-subtitle-kategori">{data.job.detilJobBudget.kategori.label}</p>
						<Clock className="rekomendasi-card-detail-subtitle-svg" />{date}
					</div>
				</div>
				<div className="rekomendasi-card-detail-bottom">
					<div className="rekomendasi-card-detail-bottom-item">
						<Price className="rekomendasi-card-detail-subtitle-svg" />
						IDR {String(data.job.detilJobBudget.budget).slice(0, -3)}
					</div>
					<div className="rekomendasi-card-detail-bottom-item">
						<DeadLine className="rekomendasi-card-detail-subtitle-svg" />
						{deadline}
					</div>
					<div className="rekomendasi-card-detail-bottom-item">
						<Pelamar className="rekomendasi-card-detail-subtitle-svg" />
						{data.pelamar.length} Pelamar
					</div>
					<div className="rekomendasi-card-detail-bottom-item">
						<Seleksi className="rekomendasi-card-detail-subtitle-svg" />
						{data.job.seleksi}
					</div>
				</div>
			</div>
		</div>
	)
}

export default RekomendasiCard;