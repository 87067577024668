import React from "react";

import "./index.css";

const CustomInputButton = ({
  color,
  value
}) => {
  const customButton2 = color ? "custom-input-button color" : "custom-input-button transparant"

  return (
    <input
      className={customButton2}
      type="submit"
      value={value}
    />
  )
}

export default CustomInputButton