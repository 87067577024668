import React, {useEffect, useState} from "react";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

import { ReactComponent as Upload } from '../../../../../assets/svg/Upload.svg';
import { ReactComponent as PlusWhite } from '../../../../../assets/svg/PlusWhite.svg';
import { ReactComponent as Edit } from '../../../../../assets/svg/Edit.svg';
import { ReactComponent as TrashRed } from '../../../../../assets/svg/TrashRed.svg';

import Navigasi from "../../../../../components/Navigasi";
import Pagination from "../../../../../components/Pagination";
import IndustriModal from "../../../../../components/IndustriModal";
import { errorAlert } from "../../../../../components/Alert";

import { dashboardAdminLink } from "../../../../../config/dashboardLink";
import { scrollToTop } from "../../../../../config/scrollTo";
import axios from "../../../../../config/api";

import "./index.css";

const KelolaIndustri = () => {
  var limit = 10;
  const navigate = useNavigate();
  
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const [industri, setIndustri] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [page, setPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const openModal = (edit, dataToEdit) => {
    setIsModalOpen(true);
    if (dataToEdit !== undefined) setDataEdit(dataToEdit);
    if (edit) setIsEdit(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setDataEdit(null);
    if (isEdit) setIsEdit(false);
  }

  const hapusIndustri = async(e, id) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.delete(`/industry/${id}`);
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }
  }

  const handlePageClick = async(pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);

    try{
      const response = await axios.get(setUrl(pageNumber));
      setIndustri(response.data.industri);
      setPage(Math.ceil(response.data.jumlahDokumen/limit))
    } catch(error) {
      console.log(error);
    } finally {
      scrollToTop();
      setLoading(false);
    }
  };

  const handleNextClick = async() => {
    if (currentPage < page) {
      setLoading(true);
      setCurrentPage(currentPage + 1);
      try{
        const response = await axios.get(setUrl(currentPage + 1));
        setIndustri(response.data.industri);
        setPage(Math.ceil(response.data.jumlahDokumen/limit))
      } catch(error) {
        console.log(error);
      } finally {
        scrollToTop();
        setLoading(false);
      }
    }
  };

  const handlePreviousClick = async() => {
    if (currentPage > 1) {
      setLoading(true);
      setCurrentPage(currentPage - 1);
      try{
        const response = await axios.get(setUrl(currentPage - 1));
        setIndustri(response.data.industri);
        setPage(Math.ceil(response.data.jumlahDokumen/limit))
      } catch(error) {
        console.log(error);
      } finally {
        scrollToTop();
        setLoading(false);
      }
    }
  };

  const setUrl = (pageNumber) => {
    let url = `/industry?page=${pageNumber}&limit=${limit}`;
    var newUrl = url;

    return newUrl;
  }

  useEffect(() => {
    const getData = async() => {
      await axios.get(`/industry?limit=${limit}`)
        .then(response => {
          setIndustri(response.data.industri);
          setPage(Math.ceil(response.data.jumlahDokumen/limit))
        })
        .catch(error => {
          console.log(error);
        })
    }

    getData();
  }, [])

  return (
    <div className="dashboard-container">
      <div className="dashboard-wrap">
        {media ?
          <div className="dashboard-bar">
            <Navigasi
              title={"Dashboard"}
              data={dashboardAdminLink}
              location={5}
            />
          </div>
          :
          null
        }
        <div className="dashboard-detail">
          <div className="dashboard-title">
            <h3>Kelola Industri</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <div className="dashboard-title kelola-moderator-title">
            <h4>Industri</h4>
            <button onClick={() => openModal()} >
              <PlusWhite className="dashboard-moderator-button-svg"/>
              Industri
            </button>
          </div>
          <table className="dashboard-table">
            <thead className="dashboard-table-head">
              <tr>
                <th className="dashboard-table-head-detail">#</th>
                <th className="dashboard-table-head-detail">Industri</th>
                <th className="dashboard-table-head-detail">Aksi</th>
              </tr>
            </thead>
            <tbody className="dashboard-table-body">
              {industri.map((item, index) => (
                <tr key={index}>
                  <td className="dashboard-table-body-detail">{((currentPage - 1) * limit) + (index + 1)}</td>
                  <td className="dashboard-table-body-detail">{item.label}</td>
                  <td className="dashboard-table-body-detail">
                    <div className="dashboard-table-body-detail-action">
                      <Edit className="dashboard-moderator-svg" onClick={() => openModal(true, item)} />
                      <TrashRed className="dashboard-moderator-svg" onClick={(e) => hapusIndustri(e, item._id)} />
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="10">
                  <div className="dashboard-moderator-pagination">
                    <Pagination
                      totalPages={page}
                      currentPage={currentPage}
                      onClickPage={handlePageClick}
                      onClickNext={handleNextClick}
                      onClickPrevious={handlePreviousClick}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <IndustriModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          isEdit={isEdit}
          dataEdit={dataEdit}
        />
      </div>
    </div>
  )
}

export default KelolaIndustri;