import React, { useRef, useState, useEffect, Fragment } from "react";
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';
import { Document, Page, pdfjs } from "react-pdf";

import { ReactComponent as Elipsis } from '../../../../assets/svg/Elipsis.svg';
import { ReactComponent as LTBlack } from '../../../../assets/svg/LTBlack.svg';
import { ReactComponent as Send } from '../../../../assets/svg/Send.svg';
import { ReactComponent as Attachment } from '../../../../assets/svg/Attachment.svg';

import DetailChatCard from "../../../../components/DetailChatCard";
import { errorAlert } from "../../../../components/Alert";

import { defaultFotoProfil } from "../../../../config/defaultData";
import { scrollToBottom } from "../../../../config/scrollTo";
import { changeImage } from "../../../../config/changeImage";
import { getFileType } from "../../../../config/fileType";

import "./index.css";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const DetailChat = ({
  isOpen,
  closeModal,
  id,
  profile,
  message,
  status
}) => {
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const fileInputRef = useRef(null);
  const messageRef = useRef(null);
  const chatBodyRef = useRef(null);

  const { userData } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);


  useEffect(() => {
    if (isOpen && id !== undefined) scrollToBottom(chatBodyRef);
  }, [message, isOpen]);

  useEffect(() => {
    const fetchFileType = async () => {
      if (file) {
        const extension = await getFileType(file);
        setFileType(extension);
      }
    };

    fetchFileType();
  }, [file])

  if (!isOpen) return null;

  const handleChangeImageClick = (ref) => {
    ref.click();
  }

  const deleteFile = () => {
    setFile(null);
    setFileType(null);
    fileInputRef.current.value = "";
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage(e);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    setLoading(true);

    const socket = io(process.env.REACT_APP_API_URL, {
      withCredentials: true,
    });

    try {
      if (file) {
        const fileBlob = await fetch(file).then(response => response.blob());

        socket.emit('sendMessage', {
          _id: id,
          content: messageRef.current.value,
          file: fileBlob,
          mimetype: fileBlob.type
        });

      } else {
        socket.emit("sendMessage", {
          _id: id,
          content: messageRef.current.value
        });
      }
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
      messageRef.current.value = "";
      fileInputRef.current.value = "";
      setFile(null);
      setFileType(null);
    }
  }

  return (
    <div className="detailChat-container">
      <div className="detailChat-wrap">
        {id === undefined ?
          <div className="detailChat-notSelected">
            <p>Pilih chat untuk memulai pesan</p>
          </div>
          :
          <Fragment>
            <div className="detailChat-top">
              <div className="detailChat-top-item">
                <div className="detailChat-top-item-detail">
                  {!media &&
                    <LTBlack
                      className="detailChat-top-item-detail-svg"
                      onClick={closeModal}
                    />
                  }
                  {message ?
                    <Fragment>
                      <img
                        src={profile ? `${process.env.REACT_APP_API_URL}${profile.informasiProfil.fotoProfil}` : `${process.env.REACT_APP_API_URL}${defaultFotoProfil}`}
                        alt="Foto Profil"
                      />
                      <div className="detailChat-top-item-title">
                        <h4>{profile?.informasiProfil.username}</h4>
                        <div className="detailChat-top-item-subtitle">
                          <div className="detailChat-top-item-circle"></div>
                          <h5>Online</h5>
                        </div>
                      </div>
                    </Fragment>
                    :
                    <div className="detailChat-top-item-blank"></div>
                  }
                </div>
                <Elipsis className="detailChat-top-item-detail-svg" />
              </div>
            </div>
            <div className={`detailChat-body ${file ? "file" : ""}`} ref={chatBodyRef}>
              {
                message?.map((item, index) => (
                  <div
                    key={index}
                    className={`detailChat-body-item ${userData._id === item.pengirim ? "pengirim" : ""}`}
                  >
                    <DetailChatCard
                      item={item}
                    />
                  </div>
                ))
              }
            </div>
            {file &&
              <div className="detailChat-img">
                <div className="detailChat-img-item" >
                  {fileType === "pdf" ? (
                    <Document
                      file={file}
                      onLoadError={(error) => console.error("Error loading document:", error)}
                    >
                      <Page pageNumber={1} height={30} />
                    </Document>
                  ) : fileType === "word" ||
                    fileType === "excel" ||
                    fileType === "powerpoint" ? (
                    file && <img src={file} alt="Uploaded File" />
                  ) : fileType === "video" ? (
                    <video controls height="140">
                      <source src={file} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) :
                    (
                      <img src={file} alt="Uploaded File" />
                    )
                  }
                  <div className="close-button" onClick={deleteFile}>
                    <span className="close-button-x"></span>
                    <span className="close-button-x"></span>
                  </div>
                </div>
              </div>
            }
            <div className="detailChat-bottom">
              <div className="detailChat-bottom-item">
                <div className="detailChat-bottom-item-detail">
                  <div className="detailChat-bottom-item-detail-box">
                    <Attachment
                      className="detailChat-bottom-item-detail-box-svg"
                      onClick={() => handleChangeImageClick(fileInputRef.current)}
                    />
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={(e) => changeImage(e, setFile, 5, true, true, true)}
                      disabled={!status}
                      required={true}
                    />
                    <div className="detailChat-bottom-item-detail-box-divider">.</div>
                    <input
                      type="text"
                      placeholder="Ketik pesan yang ingin anda kirim..."
                      onKeyDown={handleKeyDown}
                      readOnly={!status}
                      disabled={!status}
                      ref={messageRef}
                    />
                  </div>
                </div>
                <div
                  className="detailChat-bottom-item-detail-send"
                  onClick={status ? sendMessage : () => {}}
                >
                  <Send
                    className="detailChat-bottom-item-detail-send-svg" />
                </div>
              </div>
            </div>
          </Fragment>
        }
      </div>
    </div>
  )
}

export default DetailChat;