import React from "react";

import "./index.css";

const CustomButton2 = ({
  color,
  onClick,
  label,
  notAllowed
}) => {
  const customButton2 = color ? "custom-button2 color" : "custom-button2 transparant"

  return(
    <button 
      className={`${customButton2} ${notAllowed === true ? "notAllowed" : ""}`}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

export default CustomButton2;