import React, { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import { ReactComponent as DetailData } from '../../../../../assets/svg/DetailData.svg';
import { ReactComponent as TolakData } from '../../../../../assets/svg/TolakData.svg';
import { ReactComponent as TerimaData } from '../../../../../assets/svg/TerimaData.svg';

import Navigasi from "../../../../../components/Navigasi";
import Pagination from "../../../../../components/Pagination";

import axios from "../../../../../config/api";
import { convertDate } from "../../../../../config/convertDate";
import { HEADER_NO_COOKIE } from "../../../../../config/headers";
import { dashboardAdminLink, dashboardModeratorLink } from "../../../../../config/dashboardLink";

import "./index.css";

const KelolaPortofolio = () => {
  var limit = 10;
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const { userData } = useSelector((state) => state.auth);

  const [portofolio, setPortofolio] = useState([]);
  const [page, setPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const updateVerifikasiHandle = async (id, target, verifikasi) => {
    try {
      await axios.put(`/${target}/verifikasi/${id}`, {
        verifikasi: verifikasi
      }, HEADER_NO_COOKIE);
      handlePageClick(currentPage);
    } catch (error) {
      console.log(error);
    }
  }

  const handlePageClick = async(pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);

    try{
      const response = await axios.get(setUrl(pageNumber));
      setPortofolio(response.data.portofolio);
      setPage(Math.ceil(response.data.jumlahDokumen/limit))
    } catch(error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextClick = async() => {
    if (currentPage < page) {
      setLoading(true);
      setCurrentPage(currentPage + 1);
      try{
        const response = await axios.get(setUrl(currentPage + 1));
        setPortofolio(response.data.portofolio);
        setPage(Math.ceil(response.data.jumlahDokumen/limit))
      } catch(error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePreviousClick = async() => {
    if (currentPage > 1) {
      setLoading(true);
      setCurrentPage(currentPage - 1);
      try{
        const response = await axios.get(setUrl(currentPage - 1));
        setPortofolio(response.data.portofolio);
        setPage(Math.ceil(response.data.jumlahDokumen/limit))
      } catch(error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const setUrl = (pageNumber) => {
    let url = `/portofolio?page=${pageNumber}&limit=${limit}`;
    var newUrl = url;

    return newUrl;
  }

  useEffect(() => {
    const getPortofolio = async () => {
      await axios.get(`/portofolio?limit=${limit}`)
        .then(response => {
          setPortofolio(response.data.portofolio);
          setPage(Math.ceil(response.data.jumlahDokumen/limit))
        })
        .catch(error => {
          console.log(error);
        })
    }

    getPortofolio();
  }, [])
  return (
    <div className="dashboard-container">
      <div className="dashboard-wrap">
        {media ?
          <div className="dashboard-bar">
            <Navigasi
              title={"Dashboard"}
              data={userData.role === process.env.REACT_APP_ADMIN ? dashboardAdminLink : dashboardModeratorLink}
              location={userData.role === process.env.REACT_APP_ADMIN ? 2 : 0}
            />
          </div>
          :
          null
        }
        <div className="dashboard-detail">
          <div className="dashboard-title">
            <h3>Kelola Portofolio</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <div className="dashboard-title">
            <h4>Portofolio User Freelance</h4>
          </div>
          <table className="dashboard-table">
            <thead className="dashboard-table-head">
              <tr>
                <th className="dashboard-table-head-detail">#</th>
                <th className="dashboard-table-head-detail">Nama</th>
                <th className="dashboard-table-head-detail">Judul Portofolio</th>
                <th className="dashboard-table-head-detail">Tanggal Selesai</th>
                <th className="dashboard-table-head-detail">Verifikasi</th>
                <th className="dashboard-table-head-detail">Aksi</th>
              </tr>
            </thead>
            <tbody className="dashboard-table-body">
              {portofolio.map((item, index) => (
                <tr key={item._id}>
                  <td className="dashboard-table-body-detail">{((currentPage-1) * limit) + (index + 1)}</td>
                  <td className="dashboard-table-body-detail">{item.owner.informasiProfil.nama}</td>
                  <td className="dashboard-table-body-detail">{item.judulProyek}</td>
                  <td className="dashboard-table-body-detail">{convertDate(item.deadline)}</td>
                  <td className="dashboard-table-body-detail">{item.verifikasi ? "Valid" : "Tidak Valid"}</td>
                  <td className="dashboard-table-body-detail">
                    <div className="dashboard-table-body-detail-action">
                      <TerimaData className="dashboard-moderator-svg" onClick={() => updateVerifikasiHandle(item._id, "portofolio", true)} />
                      <TolakData className="dashboard-moderator-svg" onClick={() => updateVerifikasiHandle(item._id, "portofolio", false)} />
                      <DetailData className="dashboard-moderator-svg" onClick={() => { }} />
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="10">
                  <div className="dashboard-moderator-pagination">
                    <Pagination
                      totalPages={page}
                      currentPage={currentPage}
                      onClickPage={handlePageClick}
                      onClickNext={handleNextClick}
                      onClickPrevious={handlePreviousClick}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default KelolaPortofolio;