import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from 'react-toastify';
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Upload } from '../../assets/svg/Upload.svg';

import {errorAlert} from "../Alert";
import CustomInput from "../CustomInput";
import CustomInputImage from "../CustomInputImage";
import CustomInputSelect from "../CustomInputSelect";
import CustomButton2 from "../CustomButton2";

import axios from "../../config/api";
import { defaultPortofolio } from "../../config/defaultData";
import { HEADER_NO_COOKIE_FORM_DATA } from "../../config/headers";
import { changeImage } from "../../config/changeImage";

import "./index.css";

const PortofolioModal = ({ 
  isOpen,
  isEdit,
  isIsiData,
  closeModal,
  dataToSend,
  dataToSendRef,
  dataToSendTmp,
  dataIndex,
  id,
  thumbnail, 
  setThumbnail,
  fileProyek,
  setFileProyek,
  subKategoriIndex,
  setSubKategoriIndex,
  setIsAfterEdit
}) => {
  const navigate = useNavigate();

  const thumbnailRef = useRef(null);
  const fileProyekRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [thumbnailTmp, setThumbnailTmp] = useState(null);
  const [fileProyekTmp, setFileProyekTmp] = useState(null);
  const [file, setFile] = useState(null);
  const [kategori, setKategori] = useState([]);

  useEffect(() => {
    if (isOpen) {
      const getData = async () => {
        try {
          const response = await axios.get("/category");
          dataToSendTmp.current.kategori = response.data.kategori[0]._id;
          dataToSendTmp.current.subKategori = response.data.kategori[0].subKategori[0]._id;
          setKategori(response.data.kategori);
        } catch (error) {
          console.log(error);
        }
      }

      getData();
    }
  },[isOpen])

  if(!isOpen) return null;

  const handleChangeImageClick = (ref) => {
    ref.click();
  };

  const subKategoriDisplay = (e) => {
    const tmpIndex = kategori.findIndex((item) => item._id === e.target.value);
    dataToSendTmp.current.kategori = e.target.value;
    dataToSendTmp.current.subKategori = kategori[tmpIndex].subKategori[0]._id;
    setSubKategoriIndex(tmpIndex);
  }

  const uploadPortofolio = async(e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    if(thumbnailTmp) {
      const thumbnailBlob = await fetch(thumbnailTmp).then(response => response.blob());
      formData.append('thumbnail', thumbnailBlob);
    }

    if(fileProyekTmp) {
      const fileProyekBlob = await fetch(fileProyekTmp).then(response => response.blob());
      formData.append('fileProyek', fileProyekBlob);
    }
    formData.append("portofolio", JSON.stringify(dataToSendTmp.current));

    try{
      await axios[isEdit ? "put" : "post"](`/portofolio${isEdit ? "/"+id : ""}`, formData, HEADER_NO_COOKIE_FORM_DATA);
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan)
    } finally {
      setLoading(false);
    }
    emptyPortofolio();
  }

  const sendPortofolio = (e) => {
    e.preventDefault();

    if(isEdit) {
      if (thumbnailTmp) {
        const newThumbnail = JSON.parse(JSON.stringify(thumbnail));
        newThumbnail[dataIndex] = thumbnailTmp;
        setThumbnail(newThumbnail);
      }

      if (fileProyekTmp) {
        const newFileProyek = JSON.parse(JSON.stringify(fileProyek));
        newFileProyek[dataIndex] = fileProyekTmp;
        setFileProyek(newFileProyek);
      }

      dataToSend.portofolio = dataToSend.portofolio.map((item, index) => {
        if (index === dataIndex) {
          return dataToSendTmp.current;
        }
      
        return item;
      });
    } else {
      setThumbnail([...thumbnail, thumbnailTmp]);
      setFileProyek([...fileProyek, fileProyekTmp])
      dataToSend.portofolio.push(dataToSendTmp.current);
    }

    emptyPortofolio();
  }

  const emptyPortofolio = (isiData) => {
    var dataTmpTmp = JSON.parse(JSON.stringify(defaultPortofolio));

    dataToSendTmp.current = dataTmpTmp;
    setThumbnailTmp(null);
    setFileProyekTmp(null);
    setSubKategoriIndex(0);
    if(isiData) setIsAfterEdit(false);
    closeModal();
  }

  return (
    <div className="portofolioModal-container">
      <div className="portofolioModal-wrap">
        <form className="portofolioModal-item">
          <CustomInput
            label={"Judul Proyek"}
            warning={"*"}
            type={"text"}
            placeholder={"UI Design Mobile App"}
            onChange={(e) => (dataToSendTmp.current.judulProyek = e.target.value)}
            required={true}
            innerRef={(ref) => dataToSendRef.judulProyek = ref}
          />
          <CustomInput
            input={"textarea"}
            label={"Deskripsi"}
            warning={"*"}
            placeholder={"Deskripsi detil pengerjaan."}
            rows={10}
            onChange={(e) => (dataToSendTmp.current.deskripsi = e.target.value)}
            required={true}
            innerRef={(ref) => dataToSendRef.deskripsi = ref}
          />
          <CustomInputImage
            title={"Thumbnail Proyek"}
            onClick={() => handleChangeImageClick(thumbnailRef.current)}
            src={thumbnailTmp ? thumbnailTmp : isEdit ? thumbnail[dataIndex] : null}
            logo={<Upload className="portofolioModal-item-kartu-svg"/>}
            label={"Upload berkas foto Anda"}
            innerRef={thumbnailRef}
            onChange={(e) => changeImage(e, setThumbnailTmp, 2, true, false, false)}
            required={true}
            description={"Format (JPG, JPEG, PNG) Maks. 2mb"}
          />
          <CustomInputImage
            title={"File Proyek"}
            onClick={() => handleChangeImageClick(fileProyekRef.current)}
            src={fileProyekTmp ? fileProyekTmp : isEdit ? fileProyek[dataIndex] : null}
            logo={<Upload className="portofolioModal-item-kartu-svg"/>}
            label={"Upload file proyek Anda"}
            innerRef={fileProyekRef}
            onChange={(e) => changeImage(e, setFileProyekTmp, 5, true, true, true, setFile)}
            required={true}
            description={"Format (JPG, JPEG, PNG, GIF, SVG, CDR, PDF, WORD, EXCEL, PPT, MP4, WEBP, OGG) Maks. 5mb"}
            file={file}
          />
          <CustomInputSelect
            label={"Kategori"}
            warning={"*"}
            firstOnChange={subKategoriDisplay}
            firstData={kategori}
            firstRef={(ref) => dataToSendRef.kategori = ref}
          />
          <CustomInputSelect
            label={"Sub Kategori"}
            warning={"*"}
            firstOnChange={(e) => (dataToSendTmp.current.subKategori = e.target.value)}
            firstData={kategori[subKategoriIndex]?.subKategori}
            firstRef={(ref) => dataToSendRef.subKategori = ref}
          />
          <CustomInput
            label={"Deadline"}
            warning={"*"}
            type={"date"}
            onChange={(e) => (dataToSendTmp.current.deadline = e.target.value)}
            required={true}
            innerRef={(ref) => dataToSendRef.deadline = ref}
          />
          <div className="portofolioModal-bottom">
            <CustomButton2
              color={true}
              label={loading ?
                <BeatLoader
                  color={"#E4E4E7"}
                  size={10}
                />
                :
                "Upload Portofolio"
              }
              onClick={isIsiData ? sendPortofolio : uploadPortofolio}
            />
            <CustomButton2
              onClick={isIsiData ? () => emptyPortofolio(true) : () => emptyPortofolio(false) }
              label={"Batal"}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default PortofolioModal;