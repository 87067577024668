import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import { ReactComponent as Spageti } from "../../assets/svg/Spageti.svg";
import { ReactComponent as CloseSpageti } from "../../assets/svg/CloseSpageti.svg";
import { ReactComponent as Chat } from "../../assets/svg/Chat.svg";
import { ReactComponent as Notification } from "../../assets/svg/Notification.svg";
import { ReactComponent as Fakta } from "../../assets/svg/Fakta.svg";
import { ReactComponent as Search } from "../../assets/svg/Search.svg";
import { ReactComponent as Freelance } from "../../assets/svg/Freelance.svg";
import { ReactComponent as Client } from "../../assets/svg/Client.svg";

import RegisterLoginModalButton from "../RegisterLoginModalButton";

import axios from "../../config/api";
import { defaultFotoProfil } from "../../config/defaultData";
import { logout } from "../../config/authActions";
import { handleEnter } from "../../config/handleEnter";
import { getProfile } from "../../config/getProfile";

import "./index.css";
import { getCookie } from "../../config/cookies";

const modalStyle = {
  position: "absolute",
  border: "1px solid #ccc",
  borderRadius: "8px",
  padding: "10px",
  backgroundColor: "#fff",
  boxShadow: "0 5px 10px rgba(0,0,0,0.15)",
  zIndex: 1000,
};

const Navbar = ({ open, setOpen }) => {
  const cookies = getCookie("connect");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const media = useMediaQuery({
    query: "(min-width: 912px)",
  });

  const modalFreelanceRef = useRef(null);
  const buttonFreelanceRef = useRef(null);
  const modalClientRef = useRef(null);
  const buttonClientRef = useRef(null);
  const modalProfileRef = useRef(null);
  const buttonProfileRef = useRef(null);
  const modalNotificationRef = useRef(null);
  const buttonNotificationRef = useRef(null);
  const searchRef = useRef("");

  const location = useLocation();

  const { userData } = useSelector((state) => state.auth);

  const [isVisibleFreelance, setIsVisibleFreelance] = useState(false);
  const [modalFreelancePosition, setModalFreelancePosition] = useState({
    top: 0,
    left: 0,
  });
  const [isVisibleClient, setIsVisibleClient] = useState(false);
  const [modalClientPosition, setModalClientPosition] = useState({
    top: 0,
    left: 0,
  });
  const [isVisibleProfile, setIsVisibleProfile] = useState(false);
  const [modalProfilePosition, setModalProfilePosition] = useState({
    top: 0,
    left: 0,
  });
  const [isVisibleNotification, setIsVisibleNotification] = useState(false);
  const [modalNotificationPosition, setModalNotificationPosition] = useState({
    top: 0,
    left: 0,
  });
  const [data, setData] = useState([]);

  const handleButtonClick = (ref, setPosition, setVisible, width) => {
    const buttonRect = ref.getBoundingClientRect();
    setPosition({
      top: buttonRect.bottom + 10,
      left: buttonRect.right - width,
    });
    setVisible(true);
  };

  const handleClickOutside = (event, ref, setVisible) => {
    if (ref && !ref.contains(event.target)) {
      setVisible(false);
    }
  };

  const handleLogout = async () => {
    await axios
      .get(`/auth/logout`)
      .then((_) => {
        navigate("/login");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally((_) => {
        dispatch(logout());
      });
  };

  const searchData = async () => {
    try {
      navigate("/lihat-jobs", {
        state: { from: { search: searchRef.current.value } },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (isVisibleFreelance && buttonFreelanceRef.current) {
        const buttonRect = buttonFreelanceRef.current.getBoundingClientRect();
        setModalFreelancePosition({
          top: buttonRect.bottom + 10,
          left: buttonRect.right - 207,
        });
      }
      if (isVisibleClient && buttonClientRef.current) {
        const buttonRect = buttonClientRef.current.getBoundingClientRect();
        setModalClientPosition({
          top: buttonRect.bottom + 10,
          left: buttonRect.right - 172,
        });
      }
      if (isVisibleProfile && buttonProfileRef.current) {
        const buttonRect = buttonProfileRef.current.getBoundingClientRect();
        setModalProfilePosition({
          top: buttonRect.bottom + 10,
          left: buttonRect.right - 172,
        });
      }
      if (isVisibleNotification && buttonNotificationRef.current) {
        const buttonRect =
          buttonNotificationRef.current.getBoundingClientRect();
        setModalNotificationPosition({
          top: buttonRect.bottom + 10,
          left: buttonRect.right - 272,
        });
      }
    };

    window.addEventListener("resize", handleWindowResize);

    if (isVisibleFreelance) {
      document.addEventListener("mousedown", (event) =>
        handleClickOutside(
          event,
          modalFreelanceRef.current,
          setIsVisibleFreelance
        )
      );
    } else {
      document.removeEventListener("mousedown", (event) =>
        handleClickOutside(
          event,
          modalFreelanceRef.current,
          setIsVisibleFreelance
        )
      );
    }

    if (isVisibleClient) {
      document.addEventListener("mousedown", (event) =>
        handleClickOutside(event, modalClientRef.current, setIsVisibleClient)
      );
    } else {
      document.removeEventListener("mousedown", (event) =>
        handleClickOutside(event, modalClientRef.current, setIsVisibleClient)
      );
    }

    if (isVisibleProfile) {
      document.addEventListener("mousedown", (event) =>
        handleClickOutside(event, modalProfileRef.current, setIsVisibleProfile)
      );
    } else {
      document.removeEventListener("mousedown", (event) =>
        handleClickOutside(event, modalProfileRef.current, setIsVisibleProfile)
      );
    }

    if (isVisibleNotification) {
      document.addEventListener("mousedown", (event) =>
        handleClickOutside(
          event,
          modalNotificationRef.current,
          setIsVisibleNotification
        )
      );
    } else {
      document.removeEventListener("mousedown", (event) =>
        handleClickOutside(
          event,
          modalNotificationRef.current,
          setIsVisibleNotification
        )
      );
    }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
      document.removeEventListener("mousedown", (event) =>
        handleClickOutside(
          event,
          modalFreelanceRef.current,
          setIsVisibleFreelance
        )
      );
      document.removeEventListener("mousedown", (event) =>
        handleClickOutside(event, modalClientRef.current, setIsVisibleClient)
      );
      document.removeEventListener("mousedown", (event) =>
        handleClickOutside(event, modalProfileRef.current, setIsVisibleProfile)
      );
      document.removeEventListener("mousedown", (event) =>
        handleClickOutside(
          event,
          modalNotificationRef.current,
          setIsVisibleNotification
        )
      );
    };
  }, [
    isVisibleFreelance,
    isVisibleClient,
    isVisibleProfile,
    isVisibleNotification,
  ]);

  useEffect(() => {
    const getData = async () => {
      await getProfile("pengaturan")
        .then(async (response) => {
          if (response.data.proyek) {
            await axios
              .get("/activity/notifikasi?type=Proyek")
              .then((response) => {
                setData(response.data);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (
      cookies &&
      (process.env.REACT_APP_FREELANCER === userData.role ||
        process.env.REACT_APP_CLIENT === userData.role)
    )
      getData();
  }, []);

  return (
    <div
      className="navbar-container"
      style={isMobile ? { width: "100vw" } : { width: "100%" }}
    >
      <div className="navbar-wrap">
        {!media ? (
          <div className="navbar-wrap-left">
            {open ? (
              <CloseSpageti
                onClick={() => setOpen(!open)}
                className="navbar-logo-spageti"
              />
            ) : (
              <Spageti
                onClick={() => setOpen(!open)}
                className="navbar-logo-spageti"
              />
            )}
          </div>
        ) : (
          <Fragment>
            <div className="navbar-wrap-left">
              <Link to={"/"} relative="path">
                <Fakta className="navbar-fakta" />
              </Link>
            </div>
            <div className="navbar-wrap-center">
              {cookies ? (
                <Fragment>
                  <Link
                    to={
                      userData.role === process.env.REACT_APP_ADMIN
                        ? "/dashboard"
                        : userData.role === process.env.REACT_APP_MODERATOR
                        ? "/dashboard/kelola-portofolio"
                        : "/dashboard/profil-saya"
                    }
                    className={`navbar-wrap-center-detail ${
                      location.pathname === "/dashboard" ? "active" : ""
                    }`}
                    relative="path"
                    reloadDocument
                  >
                    Dashboard
                  </Link>
                  <Link
                    to={"/lihat-jobs"}
                    className={`navbar-wrap-center-detail ${
                      location.pathname === "/lihat-jobs" ? "active" : ""
                    }`}
                    relative="path"
                    reloadDocument
                  >
                    Lihat Job
                  </Link>
                </Fragment>
              ) : (
                <div className="navbar-item-search">
                  <input
                    type="text"
                    placeholder="Cari jasa atau freelancer"
                    ref={searchRef}
                    onKeyDown={(e) => handleEnter(e, searchData)}
                  />
                  <Search className="navbar-logo-search" />
                </div>
              )}
            </div>
          </Fragment>
        )}
        <div className="navbar-wrap-right">
          {cookies ? (
            <Fragment>
              {(userData.role === process.env.REACT_APP_CLIENT ||
                userData.role === process.env.REACT_APP_FREELANCER) && (
                <Fragment>
                  <Chat
                    className="navbar-wrap-right-detail"
                    onClick={() => navigate("/chat")}
                  />
                  <Notification
                    className="navbar-wrap-right-detail"
                    ref={buttonNotificationRef}
                    onClick={() =>
                      handleButtonClick(
                        buttonNotificationRef.current,
                        setModalNotificationPosition,
                        setIsVisibleNotification,
                        272
                      )
                    }
                  />
                </Fragment>
              )}
              <img
                src={
                  userData.fotoProfil
                    ? `${process.env.REACT_APP_API_URL}${userData.fotoProfil}`
                    : `${process.env.REACT_APP_API_URL}${defaultFotoProfil}`
                }
                alt="Foto Profil"
                ref={buttonProfileRef}
                onClick={() =>
                  handleButtonClick(
                    buttonProfileRef.current,
                    setModalProfilePosition,
                    setIsVisibleProfile,
                    172
                  )
                }
              />
            </Fragment>
          ) : media ? (
            <div className="navbar-wrap-right-noLogin">
              <button
                className="navbar-button-freelance"
                ref={buttonFreelanceRef}
                onClick={() =>
                  handleButtonClick(
                    buttonFreelanceRef.current,
                    setModalFreelancePosition,
                    setIsVisibleFreelance,
                    207
                  )
                }
              >
                <Freelance className="navbar-logo-freelance" />
                Freelance
              </button>
              <button
                className="navbar-button-client"
                ref={buttonClientRef}
                onClick={() =>
                  handleButtonClick(
                    buttonClientRef.current,
                    setModalClientPosition,
                    setIsVisibleClient,
                    172
                  )
                }
              >
                <Client className="navbar-logo-client" />
                Client
              </button>
            </div>
          ) : (
            <Link to={"/"} relative="path" reloadDocument>
              <Fakta className="navbar-fakta" />
            </Link>
          )}
        </div>
        {isVisibleFreelance && (
          <div
            ref={modalFreelanceRef}
            style={{
              ...modalStyle,
              top: `${modalFreelancePosition.top}px`,
              left: `${modalFreelancePosition.left}px`,
              width: "185px",
            }}
          >
            <div className="registerLoginModal-wrap">
              <RegisterLoginModalButton to={`/login`} label="Masuk" />
              <div className="registerLoginModal-lineBreak"></div>
              <RegisterLoginModalButton
                to={`/register-freelancer`}
                label="Daftar sebagai Freelancer"
              />
            </div>
          </div>
        )}
        {isVisibleClient && (
          <div
            ref={modalClientRef}
            style={{
              ...modalStyle,
              top: `${modalClientPosition.top}px`,
              left: `${modalClientPosition.left}px`,
              width: "150px",
            }}
          >
            <div className="registerLoginModal-wrap">
              <RegisterLoginModalButton to={`/login`} label="Masuk" />
              <div className="registerLoginModal-lineBreak"></div>
              <RegisterLoginModalButton
                to={`/register-client`}
                label="Daftar sebagai Client"
              />
            </div>
          </div>
        )}
        {isVisibleProfile && (
          <div
            ref={modalProfileRef}
            style={{
              position: "absolute",
              top: `${modalProfilePosition.top}px`,
              left: `${modalProfilePosition.left}px`,
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "10px",
              width: "150px",
              backgroundColor: "#fff",
              boxShadow: "0 5px 10px rgba(0,0,0,0.15)",
              zIndex: 1000,
            }}
          >
            {cookies && (
              <div className="registerLoginModal-wrap">
                <div className="registerLoginModal-button">
                  <img
                    src={
                      userData.fotoProfil
                        ? `${process.env.REACT_APP_API_URL}${userData.fotoProfil}`
                        : `${process.env.REACT_APP_API_URL}${defaultFotoProfil}`
                    }
                    alt="Foto Profil"
                  />
                  {userData.username}
                </div>
                {(process.env.REACT_APP_FREELANCER === userData.role ||
                  process.env.REACT_APP_CLIENT === userData.role) && (
                  <div className="registerLoginModal-lineBreak"></div>
                )}
                {(process.env.REACT_APP_FREELANCER === userData.role ||
                  process.env.REACT_APP_CLIENT === userData.role) && (
                  <Fragment>
                    <RegisterLoginModalButton
                      to={`/dashboard/profil-saya`}
                      label="Profil Saya"
                    />
                    <RegisterLoginModalButton
                      to={`/dashboard/proyek`}
                      label="Proyek"
                    />
                  </Fragment>
                )}
                {process.env.REACT_APP_FREELANCER === userData.role && (
                  <RegisterLoginModalButton
                    to={`/dashboard/dompet`}
                    label="Dompet"
                  />
                )}
                {process.env.REACT_APP_CLIENT === userData.role && (
                  <RegisterLoginModalButton
                    to={`/dashboard/upload-jobs`}
                    label="Posting Job"
                  />
                )}
                <div className="registerLoginModal-lineBreak"></div>
                <RegisterLoginModalButton
                  to={`/dashboard/pengaturan`}
                  label="Pengaturan"
                />
                <button onClick={handleLogout}>Keluar</button>
              </div>
            )}
          </div>
        )}
        {isVisibleNotification && (
          <div
            ref={modalNotificationRef}
            style={{
              position: "absolute",
              top: `${modalNotificationPosition.top}px`,
              left: `${modalNotificationPosition.left}px`,
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "10px",
              width: "250px",
              backgroundColor: "#fff",
              boxShadow: "0 5px 10px rgba(0,0,0,0.15)",
              zIndex: 1000,
            }}
          >
            {cookies && (
              <div className="registerLoginModal-wrap">
                {data?.map((item, index) => (
                  <div key={index} className="registerLoginModal-button">
                    <img src={userData.fotoProfil} alt="Foto Profil" />
                    <h5>{item.aktivitas.detail}</h5>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
