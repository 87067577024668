import React, { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";

import { ReactComponent as HapusUser } from '../../../../../assets/svg/HapusUser.svg';
import { ReactComponent as PlusWhite } from '../../../../../assets/svg/PlusWhite.svg';

import Navigasi from "../../../../../components/Navigasi";
import ModeratorModal from "../../../../../components/ModeratorModal";
import RekapAdmin from "../../../../../components/RekapAdmin";
import Pagination from "../../../../../components/Pagination";

import axios from "../../../../../config/api";
import { HEADER_NO_COOKIE } from "../../../../../config/headers";
import { convertDate } from "../../../../../config/convertDate";
import { dashboardAdminLink } from "../../../../../config/dashboardLink";

import "./index.css";

const KelolaModerator = () => {
  var limit = 10;

  const navigate = useNavigate();
  
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const [moderator, setModerator] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  }

  const closeModal = () => {
    setModalOpen(false);
  }

  const deleteHandle = async (id) => {
    try {
      await axios.delete(`/account/${id}`);
      navigate(0);
    } catch (error) {
      console.log(error);
    }
  }

  const handlePageClick = async(pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);

    try{
      const response = await axios.get(setUrl(pageNumber));
      setModerator(response.data.user);
      setPage(Math.ceil(response.data.jumlahDokumen/limit))
    } catch(error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextClick = async() => {
    if (currentPage < page) {
      setLoading(true);
      setCurrentPage(currentPage + 1);
      try{
        const response = await axios.get(setUrl(currentPage + 1));
        setModerator(response.data.user);
        setPage(Math.ceil(response.data.jumlahDokumen/limit))
      } catch(error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePreviousClick = async() => {
    if (currentPage > 1) {
      setLoading(true);
      setCurrentPage(currentPage - 1);
      try{
        const response = await axios.get(setUrl(currentPage - 1));
        setModerator(response.data.user);
        setPage(Math.ceil(response.data.jumlahDokumen/limit))
      } catch(error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const setUrl = (pageNumber) => {
    let url = `/account/moderator?page=${pageNumber}&limit=${limit}`;
    var newUrl = url;

    return newUrl;
  }

  useEffect(() => {
    const getModerator = async () => {
      await axios.get(`/account/moderator?limit=${limit}`)
        .then(response => {
          setModerator(response.data.user);
          setPage(Math.ceil(response.data.jumlahDokumen/limit))
        })
        .catch(error => {
          console.log(error);
        })
    }

    getModerator();
  }, [])

  return (
    <div className="dashboard-container">
      <div className="dashboard-wrap">
        {media ?
          <div className="dashboard-bar">
            <Navigasi
              title={"Dashboard"}
              data={dashboardAdminLink}
              location={1}
            />
          </div>
          :
          null
        }
        <div className="dashboard-detail">
          <div className="dashboard-title">
            <h3>Kelola Moderator</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <RekapAdmin />
          <div className="dashboard-title kelola-moderator-title">
            <h4>Kelola Akun Moderator</h4>
            <button onClick={openModal} >
              <PlusWhite className="dashboard-moderator-button-svg"/>
              Moderator
            </button>
          </div>
          <table className="dashboard-table">
            <thead className="dashboard-table-head">
              <tr>
                <th className="dashboard-table-head-detail">#</th>
                <th className="dashboard-table-head-detail">username</th>
                <th className="dashboard-table-head-detail">Bergabung Sejak</th>
                <th className="dashboard-table-head-detail">Verifikasi</th>
                <th className="dashboard-table-head-detail">Aksi</th>
              </tr>
            </thead>
            <tbody className="dashboard-table-body">
              {moderator.map((item, index) => (
                <tr key={item._id}>
                  <td className="dashboard-table-body-detail">{index + 1}</td>
                  <td className="dashboard-table-body-detail">{item.informasiProfil.username}</td>
                  <td className="dashboard-table-body-detail">{convertDate(item.waktu)}</td>
                  <td className="dashboard-table-body-detail">{item.verifikasiEmail ? "Active" : "Inactive"}</td>
                  <td className="dashboard-table-body-detail">
                    <div className="dashboard-table-body-detail-action">
                      <HapusUser className="dashboard-moderator-svg" onClick={() => deleteHandle(item._id)} />
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="5">
                  <div className="dashboard-moderator-pagination">
                    <Pagination
                      totalPages={page}
                      currentPage={currentPage}
                      onClickPage={handlePageClick}
                      onClickNext={handleNextClick}
                      onClickPrevious={handlePreviousClick}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ModeratorModal 
          isOpen={isModalOpen} 
          closeModal={closeModal} 
        />
      </div>
    </div>
  )
}

export default KelolaModerator;