import React, { useEffect, useState } from "react";

import { ReactComponent as DashboardAdminIcon } from '../../assets/svg/DashboardAdminIcon.svg';

import axios from "../../config/api";

import "./index.css";

const RekapAdmin = () => {
  const [userCount, setUserCount] = useState(0);
  const [jobCount, setJobCount] = useState(0);

  const getCountUser = async () => {
    await axios.get("/account/pengguna/jumlah")
      .then(response => {
        setUserCount(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  const getCountJob = async () => {
    await axios.get("/job/jumlah")
      .then(response => {
        setJobCount(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  useEffect(() => {
    getCountUser();
    getCountJob();
  },[])

  return (
    <div className="rekapAdmin">
      <div className="rekapAdmin-detail">
        <DashboardAdminIcon />
        <h3>{userCount.freelancer}</h3>
        <label>User Freelance</label>
      </div>
      <div className="rekapAdmin-detail">
        <DashboardAdminIcon />
        <h3>{userCount.client}</h3>
        <label>User Klien</label>
      </div>
      <div className="rekapAdmin-detail">
        <DashboardAdminIcon />
        <h3>{jobCount.count}</h3>
        <label>Pekerjaan Tayang</label>
      </div>
    </div>
  )
}

export default RekapAdmin;