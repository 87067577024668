import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { ReactSVG } from "react-svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as filledStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';

import AuthorCard from "../AuthorCard";
import CustomInput from "../CustomInput";
import CustomButton3 from "../CustomButton3";

import axios from "../../config/api";
import { HEADER_NO_COOKIE } from "../../config/headers";

import "./index.css";

const RatingCard = ({ item }) => {
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const navigate = useNavigate();

  const reviewRef = useRef("");

  const [rating, setRating] = useState({
    komunikasi: 0,
    ketepatanWaktu: 0,
    kualitasPekerjaan: 0
  });

  const size = media ? 70 : 45;

  const handleRatingChange = (event, type) => {
    const value = parseInt(event.target.value);
    setRating({
      ...rating,
      [type]: value
    });
  };

  const handleSubmitRating = async() => {
    try {
      await axios.post('/rating', {
        idJob: item.job._id,
        bintang: rating,
        review: reviewRef.current.value
      }, HEADER_NO_COOKIE);
      navigate(0);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="ratingCard-wrap">
      <div className="ratingCard-profile">
        <AuthorCard
          fotoProfil={item.author.informasiProfil.fotoProfil}
          nama={item.author.informasiProfil.nama}
          repeat={true}
          isRating={true}
        />
      </div>
      <div className="ratingCard-header">
        <ReactSVG
          src={`/svg/${item.job.detilJobBudget.kategori._id}/${item.job.detilJobBudget.subKategori._id}.svg`}
          beforeInjection={(svg) => {
            svg.setAttribute('style', `width: ${size}px; height: ${size}px`);
            svg.children[0].setAttribute('fill', '#AB1F2A');
          }}
        />
      </div>
      <div className="ratingCard-name">
        <label className="ratingCard-name-item">{item.job.detilJobBudget.judulPekerjaan}</label>
        <label className="ratingCard-name-item">{item.author.informasiPerusahaan.namaPerusahaan}</label>
      </div>
      <div className="ratingCard-kategori">
        <div className="ratingCard-kategori-detail">{item.job.detilJobBudget.kategori.label}</div>
        <div className="ratingCard-kategori-detail">{item.job.detilJobBudget.subKategori.label}</div>
      </div>
      <div className="ratingCard-star-rating">
        <label className="ratingCard-star-rating-label">Komunikasi: </label>
        <span className="star-rating">
          {[...Array(5)].map((_, index) => (
            <React.Fragment key={index}>
              <label htmlFor={`rate-${index + 1}-komunikasi`} style={{ '--i': index + 1 }}>
                <FontAwesomeIcon icon={index < rating.komunikasi ? filledStar : emptyStar} />
              </label>
              <input
                type="radio"
                name="rating-komunikasi"
                id={`rate-${index + 1}-komunikasi`}
                value={index + 1}
                checked={index + 1 === rating}
                onChange={(e) => handleRatingChange(e, "komunikasi")}
              />
            </React.Fragment>
          ))}
        </span>
      </div>
      <div className="ratingCard-star-rating">
        <label className="ratingCard-star-rating-label">Ketepatan Waktu: </label>
        <span className="star-rating">
          {[...Array(5)].map((_, index) => (
            <React.Fragment key={index}>
              <label htmlFor={`rate-${index + 1}-ketepatanWaktu`} style={{ '--i': index + 1 }}>
                <FontAwesomeIcon icon={index < rating.ketepatanWaktu ? filledStar : emptyStar} />
              </label>
              <input
                type="radio"
                name="rating-ketepatanWaktu"
                id={`rate-${index + 1}-ketepatanWaktu`}
                value={index + 1}
                checked={index + 1 === rating}
                onChange={(e) => handleRatingChange(e, "ketepatanWaktu")}
              />
            </React.Fragment>
          ))}
        </span>
      </div>
      <div className="ratingCard-star-rating">
        <label className="ratingCard-star-rating-label">Kualitas Pekerjaan: </label>
        <span className="star-rating">
          {[...Array(5)].map((_, index) => (
            <React.Fragment key={index}>
              <label htmlFor={`rate-${index + 1}-kualitasPekerjaan`} style={{ '--i': index + 1 }}>
                <FontAwesomeIcon icon={index < rating.kualitasPekerjaan ? filledStar : emptyStar} />
              </label>
              <input
                type="radio"
                name="rating-kualitasPekerjaan"
                id={`rate-${index + 1}-kualitasPekerjaan`}
                value={index + 1}
                checked={index + 1 === rating}
                onChange={(e) => handleRatingChange(e, "kualitasPekerjaan")}
              />
            </React.Fragment>
          ))}
        </span>
      </div>
      <CustomInput
        input={"textarea"}
        placeholder={"Ketik review anda disini"}
        rows={5}
        description={"min. 100 Karakter"}
        required={true}
        innerRef={reviewRef}
      />
      <CustomButton3
        color={true}
        onClick={handleSubmitRating}
        label={"Kirim Review"}
      />
    </div>
  )
}

export default RatingCard;