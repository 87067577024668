import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';

import DetailJobs from "./screens/DetailJobs";

import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import Footer from "../../../components/Footer";

import "./index.css";

const DetailJobsPage = () => {
  const [open, setOpen] = useState(false);

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  return (
    <div className="detailJobsPages-container">
      <Navbar open={open} setOpen={setOpen} />
      {!media &&
        <Sidebar open={open} />
      }
      <DetailJobs />
      <Footer />
    </div>
  )
}

export default DetailJobsPage;