import React, { useState, useRef } from "react";
import { useMediaQuery } from 'react-responsive';

import DeskripsiJob from "./screens/DeskripsiJob";
import DetilJobBudget from "./screens/DetilJobBudget";
import PreviewJob from "./screens/PreviewJob";

import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import NavbarIndikatorUploadJobs from "../../../components/NavbarIndikator/UploadJobs";
import Footer from "../../../components/Footer";

import { defaultDeskripsiJob, defaultDetilJobBudget } from "../../../config/defaultData";

import "./index.css";

const UploadJobsPage = () => {
  var deskripsiJobTemp = JSON.parse(JSON.stringify(defaultDeskripsiJob));
  var detilJobBudgetTemp = JSON.parse(JSON.stringify(defaultDetilJobBudget));

  const dataToSend = useRef({
    deskripsiJob: deskripsiJobTemp,
    detilJobBudget: detilJobBudgetTemp,
  })

  const [showPage, setShowPage] = useState({
    page1: {},
    page2: {},
    page3: {}
  })
  const [open, setOpen] = useState(false);
  const [style, setStyle] = useState({
    styleCircle1: {},
    styleCircle2: {},
    styleCircle3: {},
    styleLabel1: {},
    styleLabel2: {},
    styleLabel3: {}
  })
  const [fileTambahan, setFileTambahan] = useState(null);

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  return (
    <div>
      <Navbar open={open} setOpen={setOpen}/>
      <NavbarIndikatorUploadJobs style={style}/>
      {!media &&
        <Sidebar open={open} />
      }
      <div className="uploadJobsPage-wrap">
        <DeskripsiJob
          style={style}
          setStyle={setStyle}
          showPage={showPage}
          setShowPage={setShowPage}
          dataToSend={dataToSend.current}
        />
        <DetilJobBudget
          style={style}
          setStyle={setStyle}
          showPage={showPage}
          setShowPage={setShowPage}
          dataToSend={dataToSend.current}
          fileTambahan={fileTambahan}
          setFileTambahan={setFileTambahan}
        />
        <PreviewJob
          style={style}
          setStyle={setStyle}
          showPage={showPage}
          setShowPage={setShowPage}
          dataToSend={dataToSend.current}
          fileTambahan={fileTambahan}
        />
      </div>
      <Footer />
    </div>
  )
}

export default UploadJobsPage;