import React from "react";

import "./index.css";

const CustomButton3 = ({
  color,
  onClick,
  logo,
  label
}) => {
  const customButton3 = color ? "custom-button3 color" : "custom-button3 grey"

  return (
    <button 
      className={`${customButton3}`} 
      onClick={onClick}>
      {logo}
      {label}
    </button>
  )
}

export default CustomButton3;