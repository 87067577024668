import React, { useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";

import { ReactComponent as HidePassword } from "../../../../../assets/svg/HidePassword.svg";
import { ReactComponent as SeePassword } from "../../../../../assets/svg/SeePassword.svg";

import { errorAlert } from "../../../../../components/Alert";
import CustomCheckbox from "../../../../../components/CustomCheckbox";
import CustomInput from "../../../../../components/CustomInput";

import axios from "../../../../../config/api";
import { HEADER_NO_COOKIE } from "../../../../../config/headers";
import { loginSuccess } from "../../../../../config/authActions";

import "./index.css";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState({
    password: false,
  });
  const [loading, setLoading] = useState(false);

  const dataToSendRef = useRef({
    username: "",
    password: "",
  });

  const media = useMediaQuery({
    query: "(min-width: 912px)",
  });

  const togglePasswordVisibility = (e) => {
    e.type = e.type === "password" ? "text" : "password";
    setShowPassword({
      ...showPassword,
      [e.id]: !showPassword[e.id],
    });
  };

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    const inputData = {
      username: dataToSendRef.current.username.value,
      password: dataToSendRef.current.password.value,
    };
    try {
      const response = await axios.post(
        `/auth/login`,
        inputData,
        HEADER_NO_COOKIE
      );
      const from = location.state?.from;

      dispatch(loginSuccess(response.data));

      if (response.data.role === process.env.REACT_APP_ADMIN)
        navigate("/dashboard");

      if (response.data.role === process.env.REACT_APP_MODERATOR)
        navigate("/dashboard/kelola-portofolio");

      if (
        response.data.role === process.env.REACT_APP_CLIENT ||
        response.data.role === process.env.REACT_APP_FREELANCER
      )
        if (response.data.verifikasiEmail)
          if (response.data.verifikasiKelengkapanData)
            if (from) navigate(from);
            else navigate("/lihat-jobs");
          else navigate("/lengkapi-profil");
        else navigate("/verifikasi");
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="registerLogin-container">
      <div className="registerLogin-wrap">
        <form
          action="#"
          onSubmit={login}
          id="login-wrap-form"
          className="registerLogin-wrap-form"
        >
          <h1>
            Halo, <label>Selamat Datang!</label>
          </h1>
          {/* <h5>Lorem Ipsum dolor sit amet connecbetur. ac nutum in est reque grovida fau ibus magne ancu.</h5> */}
          <CustomInput
            regisLogin={true}
            label={"Username"}
            type={"text"}
            placeholder={"Masukkan username"}
            innerRef={(ref) => (dataToSendRef.current.username = ref)}
            required={true}
          />
          <CustomInput
            regisLogin={true}
            label={"Kata Sandi"}
            id={"password"}
            type={"password"}
            placeholder={"Masukkan kata sandi"}
            innerRef={(ref) => (dataToSendRef.current.password = ref)}
            required={true}
            svg={
              showPassword.password ? (
                <SeePassword
                  onClick={() =>
                    togglePasswordVisibility(dataToSendRef.current.password)
                  }
                  className="registerLogin-wrap-form-input-svg"
                />
              ) : (
                <HidePassword
                  onClick={() =>
                    togglePasswordVisibility(dataToSendRef.current.password)
                  }
                  className="registerLogin-wrap-form-input-svg"
                />
              )
            }
          />
          <div className="Login-wrap-form-section-bottom">
            <div className="Login-wrap-form-section-buttom-checkbox">
              <CustomCheckbox id={"checkBox"} />
              <label>Ingatkan saya</label>
            </div>
            <label>Lupa password?</label>
          </div>
          <div className="registerLogin-wrap-button">
            {loading ? (
              <div>
                <BeatLoader color={"#E4E4E7"} size={12} />
              </div>
            ) : (
              <input type="submit" value="Masuk" />
            )}
          </div>
          <div className="registerLogin-wrap-login">
            <p>
              Belum punya akun?{" "}
              <Link to={`/register-freelancer`} relative="path">
                <label>Daftar</label>
              </Link>
            </p>
          </div>
        </form>
        {media ? (
          <div className="registerLogin-wrap-image">
            <img src="/images/login.png" />
          </div>
        ) : null}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
