import React, { Fragment, useEffect } from "react";

import "./index.css";

const CustomInput = ({
  label,
  warning,
  id,
  type,
  placeholder,
  innerRef,
  required,
  ponsel,
  labelPonsel,
  svg,
  svgColor,
  name,
  value,
  defaultChecked,
  defaultValue,
  onChange,
  input,
  readOnly,
  disabled,
  rows,
  descriptionTextArea,
  description,
  regisLogin,
  onKeyDown,
  max,
  maxLength,
  minLength,
  currentText,
  maxText,
  onInput
}) => {
  const customInputItemtmp = type === "radio" ? "custom-input-item" : "custom-input-item non-radio";
  const customInputWrap = regisLogin ? "custom-input-wrap" : "custom-input-wrap noRegisLogin";
  const customInputLabel = regisLogin ? "custom-input-label" : "custom-input-label noRegisLogin";
  const customInputItem = regisLogin ? customInputItemtmp : `${customInputItemtmp} noRegisLogin`
  const customInputInput = input === "textarea" ? "custom-input-input textarea" : "custom-input-input";

  return (
    <div className={customInputWrap}>
      {label ?
        <div className={customInputLabel}>
          <label className="custom-input-label-title">{label}</label>
          <label className="custom-input-label-warning warning-text">{warning}</label>
        </div>
        : null
      }
      <div className={customInputItem}>
        {type === "radio" ?
          value.map((data, index) => (
            <div className="custom-input-input-radio" key={index}>
              <input
                type={type}
                name={name}
                value={data}
                ref={innerRef ? innerRef[index] : null}
                defaultChecked={defaultChecked ? defaultChecked === data : null}
                onChange={onChange}
                required={required}
                readOnly={readOnly}
                disabled={disabled}
              />
              <label>{data}</label>
            </div>
          ))
          :
          <Fragment>
            <div className={customInputInput}>
              {input === "textarea" ?
                <Fragment>
                  <textarea
                    placeholder={placeholder}
                    ref={innerRef}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    rows={rows}
                    required={required}
                    readOnly={readOnly}
                    disabled={disabled}
                    minLength={minLength}
                    maxLength={maxLength}
                  />
                  <label className="custom-input-label-warning warning-text custom-input-input-label">{descriptionTextArea}</label>
                </Fragment>
                :
                <Fragment>
                  {ponsel && <label className="custom-input-input-number">{labelPonsel}</label>}
                  <input
                    id={id}
                    type={type}
                    placeholder={placeholder}
                    ref={innerRef}
                    value={value}
                    defaultValue={defaultValue}
                    onKeyDown={onKeyDown}
                    onChange={onChange}
                    required={required}
                    readOnly={readOnly}
                    disabled={disabled}
                    max={max}
                    maxLength={maxLength}
                    onInput={onInput}
                  />
                  <div className="custom-input-input-svg" style={{backgroundColor: svgColor}}>
                    {svg}
                  </div>
                </Fragment>
              }
            </div>
            <div className="custom-input-label-detail">
              <label className="custom-input-label-warning warning-text">{description}</label>
              {maxText &&
                <label className="custom-input-label-warning detail-text">{currentText}/{maxText} Karakter</label>
              }
            </div>
          </Fragment>
        }
      </div>
    </div>
  )
}

export default CustomInput;
