import React, { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

import { ReactComponent as EditTealBlue } from '../../../../../assets/svg/EditTealBlue.svg';

import CustomButton2 from "../../../../../components/CustomButton2/index.jsx";
import { errorAlert } from "../../../../../components/Alert/index.jsx";

import { styleCircleBack, styleLabelBack} from "../../../../../config/style.js";
import axios from "../../../../../config/api.js";
import { HEADER_NO_COOKIE_FORM_DATA } from "../../../../../config/headers.js";

import "./index.css";

const PreviewJob = ({
  style,
  setStyle,
  showPage,
  setShowPage,
  dataToSend,
  fileTambahan
}) => {
  const style1 = isMobile ? { width: "100vw" } : { width: "calc(100vw - 17px)" };
  const style2 = showPage.page1;

  const combinedStyle = {
    ...style1,
    ...style2,
  };
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    const fileTambahanBlob = await fetch(fileTambahan).then(response => response.blob());

    formData.append('fileTambahan', fileTambahanBlob);
    formData.append("deskripsiJob", JSON.stringify(dataToSend.deskripsiJob));
    formData.append("detilJobBudget", JSON.stringify(dataToSend.detilJobBudget));

    try {
      await axios.post(`/job`, formData, HEADER_NO_COOKIE_FORM_DATA);
      navigate("/lihat-jobs")
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }
  }

  const handleBack = (e) => {
    e.preventDefault();
    scroll.scrollToTop();
    setShowPage({
      ...showPage,
      page1: { "transform": "translateX(-100%)" },
      page2: { "transform": "translateX(-100%)" },
      page3: { "transform": "translateX(-100%)" }
    })
    setStyle({
      ...style,
      styleCircle3: styleCircleBack,
      styleLabel3: styleLabelBack
    })
  }

  return (
    <div
      name="previewJob"
      className="lengkapiProfil-container"
      style={combinedStyle}
    >
      <div className="lengkapiProfil-wrap">
        <div className="previewJob-item">
          <div className="profilSaya-edit">
            <div
              className="profilSaya-edit-wrap"
              onClick={handleBack}
            >
              Edit
              <EditTealBlue className="profilSaya-edit-svg" />
            </div>
          </div>
          <div className="previewJob-detail">
            <h4>Judul Pekerjaan</h4>
            <h5>{dataToSend.detilJobBudget.judulPekerjaan}</h5>
          </div>
          <div className="previewJob-detail">
            <h4>Kategori</h4>
            <h5>{dataToSend.detilJobBudget.kategori_label}</h5>
          </div>
          <div className="previewJob-detail">
            <h4>Sub Kategori</h4>
            <h5>{dataToSend.detilJobBudget.subKategori_label}</h5>
          </div>
          <div className="previewJob-detail">
            <h4>Deskripsi</h4>
            <h5>{dataToSend.detilJobBudget.deskripsiPekerjaan}</h5>
          </div>
          <div className="previewJob-detail">
            <h4>Industri</h4>
            <h5>{dataToSend.detilJobBudget.industri_label}</h5>
          </div>
          <div className="previewJob-detail">
            <h4>Tanggal Job Ditutup</h4>
            <h5>{dataToSend.detilJobBudget.deadline}</h5>
          </div>
          <div className="previewJob-detail">
            <h4>Data Tambahan</h4>
            <h5><a href={fileTambahan}>Data Tambahan</a></h5>
          </div>
          <div className="lengkapiProfil-bottom noForm">
            <CustomButton2
              color={true}
              label={loading ?
                <BeatLoader
                  color={"#E4E4E7"}
                  size={10}
                />
                :
                "Tayangkan Job"
              }
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default PreviewJob;
