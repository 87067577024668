import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import { ReactComponent as Wallet } from '../../../../../assets/svg/Wallet.svg';
import { ReactComponent as PlusWhite } from '../../../../../assets/svg/PlusWhite.svg';
import { ReactComponent as TrashGreen } from '../../../../../assets/svg/TrashGreen.svg';
import { ReactComponent as CreditCard } from '../../../../../assets/svg/CreditCard.svg';
import { ReactComponent as Transaksi } from '../../../../../assets/svg/Transaksi.svg';

import { dashboardFreelanceLink, dashboardClientLink } from "../../../../../config/dashboardLink";
import { dompetDigital, riwayat, tagihan } from "../../../../../config/dompetData";

import Navigasi from "../../../../../components/Navigasi";
import Pagination from "../../../../../components/Pagination";

import "./index.css";

const DompetSaya = () => {
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })
  const { userData } = useSelector((state) => state.auth);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextClick = async () => {
    if (currentPage < 3) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousClick = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-wrap">
        {media ?
          <div className="dashboard-bar">
            <Navigasi
              title={"Dashboard"}
              data={userData.role === process.env.REACT_APP_FREELANCER ? dashboardFreelanceLink : dashboardClientLink}
              location={3}
            />
          </div>
          :
          null
        }
        <div className="dashboard-detail">
          <div className="dashboard-title">
            <h3>Dompet</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <div className="dashboard-item">
            <div className="dompetSaya-saldo">
              <div className="dompetSaya-saldo-svg">
                <Wallet className="dompetSaya-saldo-svg-item" />
              </div>
              <div className="dompetSaya-saldo-content">
                <h5>Saldo</h5>
                <h3>IDR 1,000,000</h3>
              </div>
            </div>
          </div>
          <div className="dompetSaya-saldo-button">
            <button className="dompetSaya-saldo-button-item">Tarik Saldo</button>
          </div>
          <div className="dashboard-item">
            <div className="dompetSaya-kartu-riwayat">
              <h5>Kartu Debit / Dompet Digital</h5>
              <div className="dompetSaya-kartu-riwayat-table">
                <table className="dompetSaya-kartu-riwayat-table-wrap">
                  <thead>
                    <tr>
                      <th>Jenis</th>
                      <th>Detail</th>
                      <th>Status</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dompetDigital.length > 0 ?
                      dompetDigital.map((item, index) => (
                        <tr key={index}>
                          <td>{item.jenis}</td>
                          <td>{item.detail}</td>
                          <td>
                            <div className="dompetSaya-kartu-table-wrap-status">{item.status ? "Terverifikasi" : "Belum Terverifikasi"}</div>
                          </td>
                          <td>
                            <TrashGreen className="dompetSaya-kartu-table-svg" />
                          </td>
                        </tr>
                      ))
                      :
                      <tr>
                        <td colSpan="4">
                          <div className="dompetSaya-kartu-riwayat-table-noData">
                            <CreditCard className="dompetSaya-kartu-riwayat-table-noData-svg" />
                            Belum ada debit atau dompet digital terdaftar
                          </div>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
              <div className="dompetSaya-kartu-button">
                <button className="dompetSaya-kartu-button-item">
                  <PlusWhite className="dompoetSaya-kartu-svg" />
                  Debit / Dompet Digital
                </button>
              </div>
            </div>
          </div>
          {userData.role === process.env.REACT_APP_CLIENT &&
            <div className="dashboard-item">
              <div className="dompetSaya-kartu-riwayat">
                <h5>Tagihan</h5>
                <div className="dompetSaya-kartu-riwayat-table">
                  <table className="dompetSaya-kartu-riwayat-table-wrap">
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>Keterangan</th>
                        <th>Tanggal</th>
                        <th>Jumlah</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tagihan.length > 0 ?
                        tagihan.map((item, index) => (
                          <tr key={index}>
                            <td>{item.nama}</td>
                            <td>{item.keterangan}</td>
                            <td>{item.tanggal}</td>
                            <td>{(item.status === "Dibayar" ? "+ " : "- ") + item.jumlah}</td>
                            <td>
                              <div className={`dompetSaya-tagihan-table-wrap-status ${item.status === "Dibayar" ? "dibayar" : "belumDibayar"}`}>{item.status}</div>
                            </td>
                          </tr>
                        ))
                        :
                        <tr>
                          <td colSpan="5">
                            <div className="dompetSaya-kartu-riwayat-table-noData">
                              <Transaksi className="dompetSaya-kartu-riwayat-table-noData-svg" />
                              Belum ada transaksi
                            </div>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                {riwayat.length > 0 ?
                  <Pagination
                    totalPages={3}
                    currentPage={currentPage}
                    onClickPage={handlePageClick}
                    onClickNext={handleNextClick}
                    onClickPrevious={handlePreviousClick}
                  />
                  :
                  null
                }
              </div>
            </div>
          }
          <div className="dashboard-item">
            <div className="dompetSaya-kartu-riwayat">
              <h5>Riwayat Transaksi</h5>
              <div className="dompetSaya-kartu-riwayat-table">
                <table className="dompetSaya-kartu-riwayat-table-wrap">
                  <thead>
                    <tr>
                      <th>Nama</th>
                      <th>Keterangan</th>
                      <th>Tanggal</th>
                      <th>Jumlah</th>
                      <th>{userData.role === process.env.REACT_APP_FREELANCER ? "Status" : "Cek"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {riwayat.length > 0 ?
                      riwayat.map((item, index) => (
                        <tr key={index}>
                          <td>{item.nama}</td>
                          <td>{item.keterangan}</td>
                          <td>{item.tanggal}</td>
                          <td>{(item.status === "Income" ? "+ " : "- ") + item.jumlah}</td>
                          <td>
                            <div className={`dompetSaya-riwayat-table-wrap-status ${item.status === "Income" ? "income" : "outcome"}`}>{item.status}</div>
                          </td>
                        </tr>
                      ))
                      :
                      <tr>
                        <td colSpan="5">
                          <div className="dompetSaya-kartu-riwayat-table-noData">
                            <Transaksi className="dompetSaya-kartu-riwayat-table-noData-svg" />
                            Belum ada transaksi
                          </div>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
              {riwayat.length > 0 ?
                <Pagination
                  totalPages={3}
                  currentPage={currentPage}
                  onClickPage={handlePageClick}
                  onClickNext={handleNextClick}
                  onClickPrevious={handlePreviousClick}
                />
                :
                null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DompetSaya;