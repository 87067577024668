export const convertDate = (originalDateString) => {
  const originalDate = new Date(originalDateString);

  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, '0');
  const day = String(originalDate.getDate()).padStart(2, '0');

  const formattedDateString = `${year}-${month}-${day}`;

  return formattedDateString;
}

export const convertTime = (originalDateString) => {
  const time = new Date(originalDateString);

  const GMTplus7 = time.getTime() - (7 * 60 * 60 * 1000);
  const timeInGMTplus7 = new Date(GMTplus7);

  const hours = timeInGMTplus7.getHours();
  const minutes = timeInGMTplus7.getMinutes();

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

export const reverseDate = (originalDateString) => {
  const date = new Date(originalDateString.getTime() + (7 * 60 * 60 * 1000));

  const isoTime = date.toISOString();

  return isoTime;
}