export const handleDateChange = (e, dataToSend) => {
  const inputDate = new Date(e.target.value);
  const today = new Date();

  if (inputDate > today) {
    dataToSend.informasiProfil.tanggalLahir = today.toISOString().slice(0, 10);
  } else {
    dataToSend.informasiProfil.tanggalLahir = e.target.value
  }
};

export const handleCountCharacter = (e, setInput, dataToSend, type) => {
  if(type === "deskripsiDiri") dataToSend.informasiProfil.deskripsiDiri = e.target.value;
  if(type === "tentangPerusahaan") dataToSend.informasiPerusahaan.tentangPerusahaan = e.target.value;
  if(type === "detilPekerjaan") dataToSend.detilJobBudget.deskripsiPekerjaan = e.target.value;
  
  setInput(e.target.value);
}

export const handleNikNpwpChange = (e, jenis, setInput, dataToSend) => {
  const value = e.target.value.replace(/[^0-9]/gi, '');
  
  dataToSend.informasiIdentitas[jenis].value = value;
  setInput(value);
}