import React, { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";

import { ReactComponent as LihatUser } from '../../../../../assets/svg/LihatUser.svg';
import { ReactComponent as HapusUser } from '../../../../../assets/svg/HapusUser.svg';
import { ReactComponent as TolakData } from '../../../../../assets/svg/TolakData.svg';
import { ReactComponent as TerimaData } from '../../../../../assets/svg/TerimaData.svg';
import { ReactComponent as DetailData } from '../../../../../assets/svg/DetailData.svg';

import Navigasi from "../../../../../components/Navigasi";
import RekapAdmin from "../../../../../components/RekapAdmin";
import Pagination from "../../../../../components/Pagination";

import axios from "../../../../../config/api";
import { HEADER_NO_COOKIE } from "../../../../../config/headers";
import { convertDate } from "../../../../../config/convertDate";
import { dashboardAdminLink } from "../../../../../config/dashboardLink";

import "./index.css";

const KelolaUser = () => {
  var limit = 10;

  const navigate = useNavigate();

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const [user, setUser] = useState([]);
  const [page, setPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const updateVerifikasiHandle = async (id, verifikasiProfil) => {
    try {
      await axios.put(`/account/verifikasiProfil/${id}`, {
        verifikasiProfil: verifikasiProfil
      }, HEADER_NO_COOKIE);
      handlePageClick(currentPage);
    } catch (error) {
      console.log(error);
    }
  }

  const deleteHandle = async (id) => {
    try {
      await axios.delete(`/account/${id}`);
      navigate(0);
    } catch (error) {
      console.log(error);
    }
  }

  const handlePageClick = async(pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);

    try{
      const response = await axios.get(setUrl(pageNumber));
      setUser(response.data.user);
      setPage(Math.ceil(response.data.jumlahDokumen/limit))
    } catch(error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextClick = async() => {
    if (currentPage < page) {
      setLoading(true);
      setCurrentPage(currentPage + 1);
      try{
        const response = await axios.get(setUrl(currentPage + 1));
        setUser(response.data.user);
        setPage(Math.ceil(response.data.jumlahDokumen/limit))
      } catch(error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePreviousClick = async() => {
    if (currentPage > 1) {
      setLoading(true);
      setCurrentPage(currentPage - 1);
      try{
        const response = await axios.get(setUrl(currentPage - 1));
        setUser(response.data.user);
        setPage(Math.ceil(response.data.jumlahDokumen/limit))
      } catch(error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const setUrl = (pageNumber) => {
    let url = `/account/pengguna?page=${pageNumber}&limit=${limit}`;
    var newUrl = url;

    return newUrl;
  }

  useEffect(() => {
    const getUser = async () => {
      await axios.get(`/account/pengguna?limit=${limit}`)
        .then(response => {
          setUser(response.data.user);
          setPage(Math.ceil(response.data.jumlahDokumen/limit))
        })
        .catch(error => {
          console.log(error);
        })
    }

    getUser();
  }, [])
  
  return (
    <div className="dashboard-container">
      <div className="dashboard-wrap">
        {media ?
          <div className="dashboard-bar">
            <Navigasi
              title={"Dashboard"}
              data={dashboardAdminLink}
              location={0}
            />
          </div>
          :
          null
        }
        <div className="dashboard-detail">
          <div className="dashboard-title">
            <h3>Kelola User</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <RekapAdmin />
          <div className="dashboard-title">
            <h4>Kelola Akun Pengguna</h4>
          </div>
          <table className="dashboard-table">
            <thead className="dashboard-table-head">
              <tr>
                <th className="dashboard-table-head-detail">#</th>
                <th className="dashboard-table-head-detail">username</th>
                <th className="dashboard-table-head-detail">Role</th>
                <th className="dashboard-table-head-detail">Bergabung Sejak</th>
                <th className="dashboard-table-head-detail">Verifikasi</th>
                <th className="dashboard-table-head-detail">Aksi</th>
              </tr>
            </thead>
            <tbody className="dashboard-table-body">
              {user.map((item, index) => (
                <tr key={item._id}>
                  <td className="dashboard-table-body-detail">{index + 1}</td>
                  <td className="dashboard-table-body-detail">{item.informasiProfil.username}</td>
                  <td className="dashboard-table-body-detail">{item.role}</td>
                  <td className="dashboard-table-body-detail">{convertDate(item.waktu)}</td>
                  <td className="dashboard-table-body-detail">{item.verifikasiProfil ? "Active" : "Inactive"}</td>
                  <td className="dashboard-table-body-detail">
                    <div className="dashboard-table-body-detail-action">
                      <LihatUser className="dashboard-moderator-svg" onClick={() => { }} />
                      <TerimaData className="dashboard-moderator-svg" onClick={() => updateVerifikasiHandle(item._id, true)} />
                      <TolakData className="dashboard-moderator-svg" onClick={() => updateVerifikasiHandle(item._id, false)} />
                      <HapusUser className="dashboard-moderator-svg" onClick={() => deleteHandle(item._id)} />
                      <DetailData className="dashboard-moderator-svg" onClick={() => { }} />
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="6">
                  <div className="dashboard-moderator-pagination">
                    <Pagination
                      totalPages={page}
                      currentPage={currentPage}
                      onClickPage={handlePageClick}
                      onClickNext={handleNextClick}
                      onClickPrevious={handlePreviousClick}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default KelolaUser;