import React from "react";
import { useMediaQuery } from 'react-responsive';
import {isMobile} from 'react-device-detect';

import { ReactComponent as GT} from "../../../assets/svg/GT.svg";

import "./index.css";

const NavbarIndikatorUploadJobs = ({ style }) => {
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  return (
    <div className="navbarIndikator-container" style={isMobile ? {width: "100vw"} : {width: "100%"}}>
      <div className="navbarIndikator-wrap">
        <div className="navbarIndikator-item">
          <div style={style.styleCircle1} className="navbarIndikator-circle circle-1">1</div>
          {media &&
            <label style={style.styleLabel1} className="navbarIndikator-item-label">Deskripsi Job</label>
          }
          <GT />
          <div style={style.styleCircle2} className="navbarIndikator-circle circle-2">2</div>
          {media &&
            <label style={style.styleLabel2} className="navbarIndikator-item-label">Detil Job & Budget</label>
          }
          <GT />
          <div style={style.styleCircle3} className="navbarIndikator-circle circle-3">3</div>
          {media &&
            <label style={style.styleLabel3} className="navbarIndikator-item-label">Preview Job</label>
          }
        </div>
      </div>
    </div>
  )
}

export default NavbarIndikatorUploadJobs;