import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';
import './index.css'; // Import the CSS file

import { ReactComponent as DeadLine } from '../../../../../assets/svg/Deadline.svg';
import { ReactComponent as Pelamar } from '../../../../../assets/svg/Pelamar.svg';
import { ReactComponent as Price } from '../../../../../assets/svg/Price.svg';
import { ReactComponent as Seleksi } from '../../../../../assets/svg/Seleksi.svg';
import DetailJobsTop from '../../../../../components/DetailJobsTop';
import { errorAlert } from '../../../../../components/Alert';
import { deadlineDifference } from '../../../../../config/dateDifference';

import axios from "../../../../../config/api";

const CheckStatus = () => {
  const [statusPayment, setStatusPayment] = useState(null);
  const [paymentmethod, setPaymentMethod] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState(null);

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  });

  useEffect(() => {
    const transactionId = searchParams.get('transaction_id');
    const getData = async () => {
      await axios.get(`/jobhub/${transactionId}`)
        .then(response => {
          setData(response.data);
        })
        .catch(error => {
          errorAlert(error.response.data.pesan);
        })
    }

    const getStatusPaymet = async () => {
      await axios.get(`/payment/status/${transactionId}`)
        .then(response => {
          const status = response.data.data.status;
          const payment_method = response.data.data.payment_method;
          setStatusPayment(status);
          setPaymentMethod(payment_method);
        })
        .catch(error => {
          errorAlert(error.response.data.data.pesan);
        })
    }
      getStatusPaymet();
      getData();
    }, [])

  return (
    <div className="checkJobs-container">
      <div className="checkJobs-wrap">
        <div className="checkJobs-top">
          {data ? (
            <DetailJobsTop
              data={data}
            />
          ) : null
          }
        </div>
        <div className="checkJobs-center">
          <div className="checkJobs-center-wrap">
            {media ? (
              <div className="checkJobs-center-item">
                <h3>Informasi Fitur</h3>
                <div className="checkJobs-top-detail-bottom">
                  <div className="checkJobs-top-detail-bottom-item">
                    <Price className="checkJobs-top-detail-subtitle-svg" />
                    <p>IDR {data?.job.detilJobBudget.budget}</p>
                  </div>
                  <div className="checkJobs-top-detail-bottom-item">
                    <DeadLine className="checkJobs-top-detail-subtitle-svg" />
                    <p>{data ? deadlineDifference(data.job.detilJobBudget.deadline) : null} Hari lagi</p>
                  </div>
                  <div className="checkJobs-top-detail-bottom-item">
                    <Pelamar className="checkJobs-top-detail-subtitle-svg" />
                    <p>{data?.pelamar.length} Pelamar</p>
                  </div>
                  <div className="checkJobs-top-detail-bottom-item">
                    <Seleksi className="checkJobs-top-detail-subtitle-svg" />
                    <p>{data?.job.seleksi}</p>
                  </div>
                </div>
              </div>
            )
              :
              null
            }
            <div className="checkJobs-center-item">
              <h3>Deskripsi</h3>
              <p>{data?.job.detilJobBudget.deskripsiPekerjaan}</p>
            </div>
            <div className="checkJobs-center-item">
              <h3>Industri</h3>
              <p>{data?.job.detilJobBudget.industri.label}</p>
            </div>
            <div className="checkJobs-center-item">
              <h3>Status</h3>
              <p>{statusPayment === null ? "Belum dibayar" : statusPayment}</p>
            </div>
            <div className="checkJobs-center-item">
              <h3>Payment Method</h3>
              <p>{paymentmethod === null ? "-" : statusPayment}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckStatus;