import React from "react";
import { Link } from "react-router-dom";

import "./index.css";

const RegisterLoginModalButton = ({ to, label }) => {
  return (
    <Link
      className="registerLoginModal-button"
      to={to}
      relative="path"
      reloadDocument
    >
      {label}
    </Link>
  )
}

export default RegisterLoginModalButton;