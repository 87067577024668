import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import RiwayatAktivitas from "./screens/RiwayatAktivitas";
import ProsesAktivitas from "./screens/ProsesAktivitas";

import Navigasi from "../../../../../components/Navigasi";

import { dashboardFreelanceLink, dashboardClientLink } from "../../../../../config/dashboardLink";

import "./index.css";

const Aktivitas = () => {
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })
  const { userData } = useSelector((state) => state.auth);

  const [selectedItem, setSelectedItem] = useState("Proses");

  return (
    <div className="dashboard-container">
      <div className="dashboard-wrap">
        {media ?
          <div className="dashboard-bar">
            <Navigasi
              title={"Dashboard"}
              data={userData.role === process.env.REACT_APP_FREELANCER ? dashboardFreelanceLink : dashboardClientLink}
              location={2}
            />
          </div>
          :
          null
        }
        <div className="dashboard-detail">
          <div className="dashboard-title">
            <h3>Aktivitas</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <div className="dashboard-item">
            <div className="dashboard-tab">
              <div
                className={`dashboard-tab-item ${selectedItem === "Proses" ? "clicked" : ""}`}
                onClick={() => setSelectedItem("Proses")}  
              >
                Proses
              </div>
              <div
                className={`dashboard-tab-item ${selectedItem === "Riwayat" ? "clicked" : ""}`}
                onClick={() => setSelectedItem("Riwayat")}  
              >
                Riwayat Aktivitas
              </div>
            </div>
            <div className="dashboard-tab-item-line"></div>
            <div className="aktivitas-card">
              {selectedItem === "Proses" ?
                <ProsesAktivitas />
                :
                <RiwayatAktivitas />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Aktivitas;