import React, { Fragment } from "react";
import { useMediaQuery } from 'react-responsive';
import {isMobile} from 'react-device-detect';
import { useSelector } from "react-redux";

import { ReactComponent as GT} from "../../../assets/svg/GT.svg";

import "./index.css";

const NavbarIndikatorLengkapiProfil = ({ style }) => {
  const { userData } = useSelector((state) => state.auth);

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  return (
    <div className="navbarIndikator-container" style={isMobile ? { width: "100vw" } : { width: "100%" }}>
      <div className="navbarIndikator-wrap">
        <div className="navbarIndikator-item">
          {userData.role === process.env.REACT_APP_FREELANCER && (
            <Fragment>
              <div style={style.styleCircle1} className="navbarIndikator-circle circle-1">1</div>
              {media &&
                <label style={style.styleLabel1} className="navbarIndikator-item-label">Pilih Kategori</label>
              }
              <GT />
              <div style={style.styleCircle2} className="navbarIndikator-circle circle-2">2</div>
              {media &&
                <label style={style.styleLabel2} className="navbarIndikator-item-label">Informasi Profil</label>
              }
              <GT />
              <div style={style.styleCircle3} className="navbarIndikator-circle circle-3">3</div>
              {media &&
                <label style={style.styleLabel3} className="navbarIndikator-item-label">Riwayat</label>
              }
              <GT />
              <div style={style.styleCircle4} className="navbarIndikator-circle circle-4">4</div>
              {media &&
                <label style={style.styleLabel4} className="navbarIndikator-item-label">Unggah Portofolio</label>
              }
            </Fragment>
          )}

          {userData.role === process.env.REACT_APP_CLIENT && (
            <Fragment>
              <div style={style.styleCircle1} className="navbarIndikator-circle circle-1">1</div>
              {media &&
                <label style={style.styleLabel1} className="navbarIndikator-item-label">Informasi Profil</label>
              }
            </Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default NavbarIndikatorLengkapiProfil;