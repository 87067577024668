import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import BeatLoader from "react-spinners/BeatLoader";

import { ReactComponent as HidePassword } from '../../assets/svg/HidePassword.svg';
import { ReactComponent as SeePassword } from '../../assets/svg/SeePassword.svg';

import {errorAlert} from "../Alert";
import CustomInput from "../CustomInput";
import CustomButton2 from "../CustomButton2";

import { HEADER_NO_COOKIE } from "../../config/headers";
import axios from "../../config/api";

import "./index.css";

const ModeratorModal = ({ 
  isOpen, 
  closeModal
}) => {
  const navigate = useNavigate();

  const dataToSendRef = useRef({
    nama: "",
    username: "",
    noTelepon: "",
    email: "",
    password: "",
    confirmPassword: ""
  });

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  });
  const [loading, setLoading] = useState(false);

  if(!isOpen) return null;

  const togglePasswordVisibility = (e) => {
    e.type = e.type === 'password' ? 'text' : 'password';
    setShowPassword({
      ...showPassword,
      [e.id]: !showPassword[e.id]
    });
  };

  const tambahModerator = async(e) => {
    e.preventDefault();
    setLoading(true);
    const inputData = {
      nama: dataToSendRef.current.nama.value,
      username: dataToSendRef.current.username.value,
      noTelepon: dataToSendRef.current.noTelepon.value,
      email: dataToSendRef.current.email.value,
      password: dataToSendRef.current.password.value,
      confirmPassword: dataToSendRef.current.confirmPassword.value
    }
    try {
      await axios.post(`/account/moderator`, inputData, HEADER_NO_COOKIE);
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }

    closeModal();
  }

  return (
    <div className="moderator-container">
      <div className="moderator-wrap">
        <form className="moderator-item">
          <CustomInput
            label={"Nama Lengkap"}
            warning={"*"}
            type={"text"}
            placeholder={"Masukkan nama lengkap"}
            innerRef={(ref) => (dataToSendRef.current.nama = ref)}
            required={true}
          />
          <CustomInput
            label={"Username"}
            warning={"*"}
            type={"text"}
            placeholder={"Masukkan username"}
            innerRef={(ref) => (dataToSendRef.current.username = ref)}
            required={true}
          />
          <CustomInput
            label={"Email"}
            warning={"*"}
            type={"email"}
            placeholder={"Masukkan email"}
            innerRef={(ref) => (dataToSendRef.current.email = ref)}
            required={true}
          />
          <CustomInput
            label={"Nomor Ponsel"}
            warning={"*"}
            ponsel={true}
            labelPonsel={"+62"}
            type={"number"}
            placeholder={"Masukkan nomor ponsel"}
            innerRef={(ref) => (dataToSendRef.current.noTelepon = ref)}
            required={true}
          />
          <CustomInput
            label={"Kata Sandi"}
            warning={"*"}
            id={"password"}
            type={"password"}
            placeholder={"Masukkan kata sandi"}
            innerRef={(ref) => (dataToSendRef.current.password = ref)}
            required={true}
            svg={showPassword.password ?
              <SeePassword onClick={() => togglePasswordVisibility(dataToSendRef.current.password)} className="registerLogin-wrap-form-input-svg" />
              :
              <HidePassword onClick={() => togglePasswordVisibility(dataToSendRef.current.password)} className="registerLogin-wrap-form-input-svg" />}
          />
          <CustomInput
            label={"Ulangi Kata Sandi"}
            warning={"*"}
            id={"confirmPassword"}
            type={"password"}
            placeholder={"Masukkan kata sandi"}
            innerRef={(ref) => (dataToSendRef.current.confirmPassword = ref)}
            required={true}
            svg={showPassword.confirmPassword ?
              <SeePassword onClick={() => togglePasswordVisibility(dataToSendRef.current.confirmPassword)} className="registerLogin-wrap-form-input-svg" />
              :
              <HidePassword onClick={() => togglePasswordVisibility(dataToSendRef.current.confirmPassword)} className="registerLogin-wrap-form-input-svg" />}
          />
          <div className="moderator-bottom">
            <CustomButton2
              color={true}
              label={loading ?
                <BeatLoader
                  color={"#E4E4E7"}
                  size={10}
                />
                :
                "Tambah Moderator"
              }
              onClick={tambahModerator}
            />
            <CustomButton2
              onClick={() => closeModal()}
              label={"Batal"}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default ModeratorModal;