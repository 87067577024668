import React from 'react';

const NoJobs = () => {
  return (
    <div className="lihatJobs-nothing-card">
      <div className="lihatJobs-nothing-card-label">Pekerjaan tidak ditemukan</div>
      <img src="/images/nothingJob.png" />
      <label>Maaf!</label>
      <p>Belum ada yang menayangkan pekerjaan ini, mungkin lain kali!</p>
    </div>
  );
};

export default NoJobs;