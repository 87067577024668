import React, { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';

import Navigasi from "../../../../../components/Navigasi";
import RekapAdmin from "../../../../../components/RekapAdmin";

import axios from "../../../../../config/api";
import { convertDate } from "../../../../../config/convertDate";
import { dashboardAdminLink } from "../../../../../config/dashboardLink";

import "./index.css";

const DashboardAdmin = () => {
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const [user, setUser] = useState([]);

  useEffect(() => {
    const getUser = async () => {
      await axios.get("/account")
        .then(response => {
          setUser(response.data);
        })
        .catch(error => {
          console.log(error);
        })
    }

    getUser();
  }, [])
  
  return (
    <div className="dashboard-container">
      <div className="dashboard-wrap">
        {media ?
          <div className="dashboard-bar">
            <Navigasi
              title={"Dashboard"}
              data={dashboardAdminLink}
            />
          </div>
          :
          null
        }
        <div className="dashboard-detail">
          <div className="dashboard-title">
            <h3>Dashboard Admin</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <RekapAdmin />
          <div className="dashboard-title">
            <h4>User Terbaru</h4>
          </div>
          <table className="dashboard-table">
            <thead className="dashboard-table-head">
              <tr>
                <th className="dashboard-table-head-detail">#</th>
                <th className="dashboard-table-head-detail">Username</th>
                <th className="dashboard-table-head-detail">Email</th>
                <th className="dashboard-table-head-detail">Role</th>
                <th className="dashboard-table-head-detail">Bergabung Pada</th>
              </tr>
            </thead>
            <tbody className="dashboard-table-body">
              {user.map((item, index) => (
                <tr key={item._id}>
                  <td className="dashboard-table-body-detail">{index + 1}</td>
                  <td className="dashboard-table-body-detail">{item.informasiProfil.username}</td>
                  <td className="dashboard-table-body-detail">{item.informasiProfil.email}</td>
                  <td className="dashboard-table-body-detail">{item.role}</td>
                  <td className="dashboard-table-body-detail">{convertDate(item.waktu)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default DashboardAdmin;