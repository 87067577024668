import React, { useState } from "react";

import "./index.css"

function CustomCheckbox({
  id,
  checked,
  onChange,
  required,
  flex,
  disabled,
  readOnly
}) {
  const [checkedItems, setCheckedItems] = useState(false);

  const handleCheckboxChange = () => {
    setCheckedItems(!checkedItems);
  };

  return (
    <div className={`squaredFour ${disabled ? "disabled" : ""}`}>
      <input
        style={{flex: flex}}
        type="checkbox"
        value="None"
        id={id}
        name="check"
        checked={checked ? checked : checkedItems}
        onChange={onChange ? onChange : handleCheckboxChange}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
      />
      <label htmlFor={id}></label>
    </div>
  )
}

export default CustomCheckbox;