import React, { Fragment } from "react";

import { ReactComponent as Edit } from '../../assets/svg/Edit.svg';
import { ReactComponent as Trash } from '../../assets/svg/Trash.svg';

import "./index.css";

const PortofolioCard = ({
  proses,
  gambar,
  judulProyek,
  editOnClick,
  deleteOnClick
}) => {
  return (
    <div className="portofolioCard-wrap">
      <div className="portofolioCard-view">
        <img
          src={gambar}
          alt="ini gambar"
        />
      </div>
      <div className="portofolioCard-label">
        <label>{judulProyek}</label>
        <div className="portofolioCard-svg-wrap">
          {!proses &&
            <Fragment>
              <Edit className="portofolioCard-svg" onClick={editOnClick} />
              <Trash className="portofolioCard-svg" onClick={deleteOnClick} />
            </Fragment>
          }
        </div>
      </div>
    </div>
  )
}

export default PortofolioCard;