import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import BeatLoader from "react-spinners/BeatLoader";

import {errorAlert} from "../Alert";
import CustomInput from "../CustomInput";
import CustomButton2 from "../CustomButton2";

import { HEADER_NO_COOKIE } from "../../config/headers";
import axios from "../../config/api";

import "./index.css";

const IndustriModal = ({ 
  isOpen, 
  closeModal,
  isEdit,
  dataEdit
}) => {
  const navigate = useNavigate();

  const dataToSendRef = useRef({
    industri: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEdit) {
      dataToSendRef.current.label.value = dataEdit.label;
    }
  },[isEdit])

  if(!isOpen) return null;

  const tambahIndustri = async(e) => {
    e.preventDefault();
    setLoading(true);
    const inputData = {
      industri: dataToSendRef.current.label.value,
    }
    try {
      await axios.post(`/industry`, inputData, HEADER_NO_COOKIE);
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }

    closeModal();
  }

  const ubahIndustri = async(e) => {
    e.preventDefault();
    setLoading(true);
    const inputData = {
      industri: dataToSendRef.current.label.value,
    }
    try {
      await axios.put(`/industry/${dataEdit._id}`, inputData, HEADER_NO_COOKIE);
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }

    closeModal();
  }

  return (
    <div className="moderator-container">
      <div className="moderator-wrap">
        <form className="moderator-item">
          <CustomInput
            label={"Nama Industri"}
            warning={"*"}
            type={"text"}
            placeholder={"Masukkan nama Industri "}
            innerRef={(ref) => (dataToSendRef.current.label = ref)}
            required={true}
          />
          <div className="moderator-bottom">
            <CustomButton2
              color={true}
              label={loading ?
                <BeatLoader
                  color={"#E4E4E7"}
                  size={10}
                />
                :
                isEdit ? "Ubah Industri" : "Tambah Industri"
              }
              onClick={isEdit ? ubahIndustri : tambahIndustri}
            />
            <CustomButton2
              onClick={() => closeModal()}
              label={"Batal"}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default IndustriModal;