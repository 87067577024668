import React, { Fragment } from "react";
import { useSelector } from 'react-redux';

import "./index.css";

const AuthorCard = ({ 
  fotoProfil, 
  nama,
  rating,
  review,
  repeat,
  onClick,
  isRating
}) => {
  const { userData } = useSelector((state) => state.auth);

  return (
    <div 
      className="author-card-author"
      style={repeat ? {padding: "15px 10px", borderRadius: "6px"} : {}}
      onClick={onClick}
    >
      <img src={`${process.env.REACT_APP_API_URL}${fotoProfil}`} />
      <div className="author-card-author-detail">
        <label className="author-card-author-nama">{nama}</label>
        <div className={`author-card-author-detail-item ${isRating ? "isRating" : ""}`}>
          {isRating ?
            userData.role === process.env.REACT_APP_FREELANCER ? "Client" : "Freelancer"
            :
            <Fragment>
              <label className="author-card-author-detail-rating">{rating}/5</label>
              <label className="author-card-author-detail-review">({review} Review)</label>
            </Fragment>
          }
        </div>
      </div>
    </div>
  )
}

export default AuthorCard;