import React, { useEffect, useRef, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import { isMobile } from 'react-device-detect';

import CustomInputSelect from "../../../../../components/CustomInputSelect";
import CustomButton2 from "../../../../../components/CustomButton2";

import { styleCircle, styleLabel } from "../../../../../config/style";
import { jobOffer, tipeJob } from "../../../../../config/uploadJobs";

import "./index.css";

const DeskripsiJob = ({ style, setStyle, showPage, setShowPage, dataToSend }) => {
  const style1 = isMobile ? { width: "100vw" } : { width: "calc(100vw - 17px)" };
  const style2 = showPage.page1;

  const combinedStyle = {
    ...style1,
    ...style2,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    scroll.scrollToTop({
      duration: 500,
    });
    setShowPage({
      ...showPage,
      page1: { "transform": "translateX(-100%)" },
      page2: { "transform": "translateX(-100%)" },
      page3: { "transform": "translateX(-100%)" }
    })
    setStyle({
      ...style,
      styleCircle2: styleCircle,
      styleLabel2: styleLabel
    })
  }

  useEffect(() => {
    setStyle({
      ...style,
      styleCircle1: styleCircle,
      styleLabel1: styleLabel
    })
  }, [])

  return (
    <div
      name="deskripsiJob"
      className="lengkapiProfil-container informasiProfilIdentitas"
      style={combinedStyle}
    >
      <div className="lengkapiProfil-wrap">
        <form action="#" onSubmit={handleSubmit} className="informasiProfilIdentitas-item">
          <CustomInputSelect
            label={"Job Offer"}
            warning={"*"}
            firstOnChange={(e) => dataToSend.deskripsiJob.jobOffer = e.target.value}
            firstData={jobOffer}
          />
          <CustomInputSelect
            label={"Tipe Job"}
            warning={"*"}
            firstOnChange={(e) => dataToSend.deskripsiJob.tipeJob = e.target.value}
            firstData={tipeJob}
          />
          <div className="lengkapiProfil-bottom noForm">
            <CustomButton2
              color={true}
              onClick={handleSubmit}
              label={"Selanjutnya"}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default DeskripsiJob;