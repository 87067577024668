export const dompetDigital = [
  { 
    jenis: "Gopay", 
    detail: 'Reza - 082154475282', 
    status: true 
  }
];

export const riwayat = [
  { 
    nama: "Reza Pratama", 
    keterangan: 'Pemasukan Dana', 
    tanggal: "Senin, 10/12/2023", 
    jumlah: "IDR 1,000,000",
    status: "Income"
  },
  { 
    nama: "Reza Pratama", 
    keterangan: 'Penarikan Dana', 
    tanggal: "Selasa, 11/12/2023", 
    jumlah: "IDR 1,000,000",
    status: "Outcome"
  }
];

export const tagihan = [
  { 
    nama: "Reza Pratama", 
    keterangan: 'Landing Page Responsive Company Profile', 
    tanggal: "Senin, 10/12/2023", 
    jumlah: "IDR 1,000,000",
    status: "Dibayar"
  }
];