export const jobOffer = [
  {
    value: "diTawarkan",
    label: "Job Baru untuk ditawarkan"
  }
]

export const tipeJob = [
  {
    value: "paruhWaktu",
    label: "Paruh Waktu"
  }, {
    value: "penuhWaktu",
    label: "Penuh Waktu"
  }, {
    value: "pekerjaLepas",
    label: "Pekerja Lepas"
  }, {
    value: "magang",
    label: "Magang"
  }, {
    value: "kontak",
    label: "Kontak"
  }
]