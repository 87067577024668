import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useMediaQuery } from "react-responsive";
import { ReactSVG } from "react-svg";

import AuthorCard from "../AuthorCard";
import CustomButton3 from "../CustomButton3";


import "./index.css";

const ProyekCard = ({ item }) => {
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const size = media ? 70 : 45;

  const navigate = useNavigate();

  const { userData } = useSelector((state) => state.auth);


  return (
    <div className="proyekCard-wrap">
      <div className="proyekCard-header">
        <div
          className="proyekCard-status"
          style={
            item.job.status === "Proses" ?
              { backgroundColor: "#ADD8E6", color: "#00008B" }
              : item.job.status === "Dibuka" ?
                item.pelamar.some(pelamar => pelamar.status === "Diterima") ? 
                  { backgroundColor: "#DCFFDB", color: "#04C700" } 
                  : { backgroundColor: "#FFFACF", color: "#D1BD00" } 
                : item.job.status === "Ditolak" ? 
                  { backgroundColor: "#EBC9CC", color: "#AD1F2A" }
                  : { backgroundColor: "#E0E0E0", color: "#666666" }
          }
        >
          {
            item.job.status === "Dibuka" ?
              item.pelamar.some(pelamar => pelamar.status === "Diterima") ? 
                "Berjalan" 
                : userData.role === process.env.REACT_APP_FREELANCER ? 
                  "Menunggu" 
                  : "Tayang" 
              : item.job.status
          }
        </div>
        <ReactSVG
          src={`/svg/${item.job.detilJobBudget.kategori._id}/${item.job.detilJobBudget.subKategori._id}.svg`}
          beforeInjection={(svg) => {
            svg.setAttribute('style', `width: ${size}px; height: ${size}px`);
            svg.children[0].setAttribute('fill', '#AB1F2A');
          }}
        />
      </div>
      <label className="proyekCard-nama">{item.job.detilJobBudget.judulPekerjaan}</label>
      <label className="proyekCard-nama">{item.author.informasiPerusahaan.namaPerusahaan}</label>
      <AuthorCard 
        fotoProfil={item.author.informasiProfil.fotoProfil}
        nama={item.author.informasiProfil.nama}
        rating={item.author.ratingTotal.bintang}
        review={item.author.ratingTotal.review}
        repeat={true}
      />
      <div className="proyekCard-kategori">
        <div className="proyekCard-kategori-detail">{item.job.detilJobBudget.kategori.label}</div>
        <div className="proyekCard-kategori-detail">{item.job.detilJobBudget.subKategori.label}</div>
      </div>
      <CustomButton3
        color={true}
        onClick={() => navigate(`/lihat-jobs/${item.job._id}`)}
        label={"Lihat Proyek"}
      />
    </div>
  )
}

export default ProyekCard;