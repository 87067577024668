import React from "react";
import { Link } from 'react-router-dom';

import "./index.css";
import { getCookie } from "../../../../../config/cookies";

const VerifikasiACC = () => {
  const cookie = getCookie("connect");
  return (
    <div className="verifikasi-container">
      <div className="verifikasi-wrap">
          <div className="verifikasi-wrap-image">
            <img className="verifikasi-wrap-image-2" src="/images/emailVerifikasiACC.png" />
          </div>
        <h1>Yeay, Berhasil!</h1>
        <h5>Selamat akun Anda sudah terverifikasi. Untuk mendapatkan job, Anda harus mengisi data terlebih dahulu, agar klien lebih percaya dengan Anda.</h5>
        {cookie ?
          (
            <Link to={"/lengkapi-profil"} className="verifikasi-wrap-button">
              Lengkapi Data Profil
            </Link>
          ) :
          (
            <Link to={"/login"} className="verifikasi-wrap-button">
              Login
            </Link>
          )}
      </div>
    </div>
  );
}

export default VerifikasiACC;