import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import RekomendasiCard from "../../../../components/RekomendasiCard";
import CustomButton from "../../../../components/CustomButton";

import axios from "../../../../config/api";

import "./index.css";

const Rekomendasi = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get("/jobhub");
        setData(response.data.rekomendasiJob);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, []);

  return (
    <div className="rekomendasi-container">
      <div className="rekomendasi-wrap">
        <h3>REKOMENDASI</h3>
        <div className="rekomendasi-card-container">
          {data.map((data, index) => (
            <RekomendasiCard
              key={index}
              data={data}
              onClick={() => navigate(`/lihat-jobs/${data.job._id}`)}
            />
          ))}
        </div>
        <CustomButton
          label={"Rekomendasi Lainnya"}
          onClick={() => navigate("/lihat-jobs")}
        />
      </div>
    </div>
  );
};

export default Rekomendasi;
