import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Search } from "../../assets/svg/Search.svg";
import { ReactComponent as FreelanceWhite} from "../../assets/svg/FreelanceWhite.svg";
import { ReactComponent as Client} from "../../assets/svg/Client.svg";

import axios from "../../config/api";
import { dashboardClientLink, dashboardFreelanceLink, dashboardAdminLink, dashboardModeratorLink } from "../../config/dashboardLink";
import { logout } from "../../config/authActions";
import { handleEnter } from "../../config/handleEnter";

import "./index.css";
import { getCookie } from "../../config/cookies";

const Sidebar = ({ open }) => {
  const sidebarClass = open ? 'sidebar-container open' : 'sidebar-container';
  const cookies = getCookie("connect");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const searchRef = useRef("");

  const { userData } = useSelector((state) => state.auth);

  const [isVisibleKategori, setIsVisibleKategori] = useState(false);
  const [isVisibleDashboard, setIsVisibleDashboard] = useState(false);
  const [kategori, setKategori] = useState([]);

  const handleLogout = async () => {
    await axios.get(`/auth/logout`)
      .then(response => {
        dispatch(logout());
        navigate('/login');
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
      })
  }

  const searchData = async() => {
    try {
      navigate("/lihat-jobs", { state: { from: { search: searchRef.current.value } }});
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const getData = async() => {
      try{
        const response = await axios.get("/category");
        setKategori(response.data.kategori);
      } catch(error) {
        console.log(error);
      }
    }
    
    getData();
  },[])

  return (
    <div className={sidebarClass}>
      <div className="sidebar-wrap">
        <div className="sidebar-wrap-search">
          <input
            className="sidebar-input-search"
            placeholder="Cari..."
            type="text"
            ref={searchRef}
            onKeyDown={(e) => handleEnter(e, searchData)}
          />
          <Search className="sidebar-logo-search" />
        </div>
        <div className="sidebar-wrap-header">
          <Link to={"/lihat-jobs"} className="sidebar-wrap-footer-item" reloadDocument>Lihat Job</Link>
          <div onClick={() => setIsVisibleKategori(!isVisibleKategori)} className="sidebar-dropdown">
            <p className="sidebar-wrap-footer-item">Kategori</p>
            <p>^</p>
          </div>
          <div className={`sidebar-dropdown-detail ${isVisibleKategori ? 'show' : ''}`}>
            {kategori.map((item, index) => (
              <Link 
                key={index}
                to={"/lihat-jobs"}
                state={{ from: { index: index } }}
                relative="path"
                className="sidebar-wrap-footer-item"
              >
                {item.label}
              </Link>
            ))}
          </div>
          {cookies &&
            <Fragment>
              <div onClick={() => setIsVisibleDashboard(!isVisibleDashboard)} className="sidebar-dropdown">
                <p className="sidebar-wrap-footer-item">Dashboard</p>
                <p>^</p>
              </div>
              <div className={`sidebar-dropdown-detail ${isVisibleDashboard ? 'show' : ''}`}>
                {(userData.role === process.env.REACT_APP_ADMIN ? dashboardAdminLink : userData.role === process.env.REACT_APP_MODERATOR ? dashboardModeratorLink : userData.role === process.env.REACT_APP_CLIENT ? dashboardClientLink : dashboardFreelanceLink).map((item, index) => (
                  <Link 
                    key={index} 
                    to={item.navigasi} 
                    className="sidebar-wrap-footer-item dashboard"
                  >
                    {item.logoLight}
                    {item.label}
                  </Link>
                ))}
              </div>
            </Fragment>
          }
        </div>
        <div className="sidebar-wrap-loginregister">
          {cookies ?
            <button
              className="sidebar-button-login"
              onClick={handleLogout}
            >
              Keluar
            </button>
            :
            <Fragment>
              Daftar sebagai
              <Link
                to={`/register-freelancer`}
                relative="path"
                className="sidebar-button-register"
                reloadDocument
              >
                <FreelanceWhite className="sidebar-logo-register" />
                Freelance
              </Link>
              <Link
                to={`/register-client`}
                relative="path"
                className="sidebar-button-register"
                reloadDocument
              >
                <Client className="sidebar-logo-register" />
                Client
              </Link>
              <Link
                to={`/login`}
                relative="path"
                className="sidebar-button-login"
                reloadDocument
              >
                Masuk
              </Link>
            </Fragment>
          }
        </div>
        <div className="sidebar-wrap-footer">
          <Link className="sidebar-wrap-footer-item">Tentang Kami</Link>
          <Link className="sidebar-wrap-footer-item">Peraturan</Link>
          <Link className="sidebar-wrap-footer-item">Kebijakan Privasi</Link>
          <Link className="sidebar-wrap-footer-item">Syarat & Ketentuan</Link>
          <Link className="sidebar-wrap-footer-item">Karier</Link>
        </div>
      </div>
    </div>
  )
}

export default Sidebar;