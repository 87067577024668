import { ReactComponent as Profil} from "../../src/assets/svg/Profil.svg";
import { ReactComponent as ProfilLight} from "../../src/assets/svg/ProfilLight.svg";
import { ReactComponent as Proyek} from "../../src/assets/svg/Proyek.svg";
import { ReactComponent as ProyekLight} from "../../src/assets/svg/ProyekLight.svg";
import { ReactComponent as Email} from "../../src/assets/svg/Email.svg";
import { ReactComponent as EmailLight} from "../../src/assets/svg/EmailLight.svg";
import { ReactComponent as Dompet} from "../../src/assets/svg/Dompet.svg";
import { ReactComponent as DompetLight} from "../../src/assets/svg/DompetLight.svg";
import { ReactComponent as Portofolio} from "../../src/assets/svg/Portofolio.svg";
import { ReactComponent as PortofolioLight} from "../../src/assets/svg/PortofolioLight.svg";
import { ReactComponent as Pengaturan} from "../../src/assets/svg/Pengaturan.svg";
import { ReactComponent as PengaturanLight} from "../../src/assets/svg/PengaturanLight.svg";
import { ReactComponent as Rating} from "../../src/assets/svg/Rating.svg";
import { ReactComponent as RatingLight} from "../../src/assets/svg/RatingLight.svg";

export const dashboardFreelanceLink = [
  {
    label: "Profil Saya",
    logo: <Profil />,
    logoLight: <ProfilLight />,
    navigasi: "/dashboard/profil-saya"
  },
  {
    label: "Proyek",
    logo: <Proyek />,
    logoLight: <ProyekLight />,
    navigasi: "/dashboard/proyek"
  },
  {
    label: "Aktivitas",
    logo: <Email />,
    logoLight: <EmailLight />,
    navigasi: "/dashboard/aktivitas"
  },
  {
    label: "Dompet",
    logo: <Dompet />,
    logoLight: <DompetLight />,
    navigasi: "/dashboard/dompet"
  },
  {
    label: "Portofolio",
    logo: <Portofolio />,
    logoLight: <PortofolioLight />,
    navigasi: "/dashboard/portofolio"
  },
  {
    label: "Rating",
    logo: <Rating />,
    logoLight: <RatingLight />,
    navigasi: "/dashboard/rating"
  },
  {
    label: "Pengaturan",
    logo: <Pengaturan />,
    logoLight: <PengaturanLight />,
    navigasi: "/dashboard/pengaturan"
  }
]

export const dashboardClientLink = [
  {
    label: "Profil Saya",
    logo: <Profil />,
    logoLight: <ProfilLight />,
    navigasi: "/dashboard/profil-saya"
  },
  {
    label: "Proyek",
    logo: <Proyek />,
    logoLight: <ProyekLight />,
    navigasi: "/dashboard/proyek"
  },
  {
    label: "Aktivitas",
    logo: <Email />,
    logoLight: <EmailLight />,
    navigasi: "/dashboard/aktivitas"
  },
  {
    label: "Keuangan",
    logo: <Dompet />,
    logoLight: <DompetLight />,
    navigasi: "/dashboard/keuangan"
  },
  {
    label: "Rating",
    logo: <Rating />,
    logoLight: <RatingLight />,
    navigasi: "/dashboard/rating"
  },
  {
    label: "Pengaturan",
    logo: <Pengaturan />,
    logoLight: <PengaturanLight />,
    navigasi: "/dashboard/pengaturan"
  }
]

export const dashboardModeratorLink = [
  {
    label: "Kelola Portofolio",
    logo: <Portofolio />,
    logoLight: <PortofolioLight />,
    navigasi: "/dashboard/kelola-portofolio"
  },
  {
    label: "Kelola Job",
    logo: <Proyek />,
    logoLight: <ProyekLight />,
    navigasi: "/dashboard/kelola-job"
  },
  {
    label: "Pengaturan",
    logo: <Pengaturan />,
    logoLight: <PengaturanLight />,
    navigasi: "/dashboard/pengaturan"
  }
]

export const dashboardAdminLink = [
  {
    label: "Kelola User",
    logo: <Profil />,
    logoLight: <ProfilLight />,
    navigasi: "/dashboard/kelola-user"
  },
  {
    label: "Kelola Moderator",
    logo: <Profil />,
    logoLight: <ProfilLight />,
    navigasi: "/dashboard/kelola-moderator"
  },
  {
    label: "Kelola Portofolio",
    logo: <Portofolio />,
    logoLight: <PortofolioLight />,
    navigasi: "/dashboard/kelola-portofolio"
  },
  {
    label: "Kelola Job",
    logo: <Proyek />,
    logoLight: <ProyekLight />,
    navigasi: "/dashboard/kelola-job"
  },
  {
    label: "Kelola Kategori & Sub Kategori",
    logo: <Proyek />,
    logoLight: <ProyekLight />,
    navigasi: "/dashboard/kelola-kategori-subKategori"
  },
  {
    label: "Kelola Industri",
    logo: <Proyek />,
    logoLight: <ProyekLight />,
    navigasi: "/dashboard/kelola-industri"
  },
  {
    label: "Pengaturan",
    logo: <Pengaturan />,
    logoLight: <PengaturanLight />,
    navigasi: "/dashboard/pengaturan"
  }
]