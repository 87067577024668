import React, { useEffect, useRef, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import {isMobile} from 'react-device-detect';

import { ReactComponent as Upload } from '../../../../assets/svg/Upload.svg';

import CustomButton2 from "../../../../components/CustomButton2";
import CustomInput from "../../../../components/CustomInput";
import CustomInputImage from "../../../../components/CustomInputImage";
import CustomInputButton from "../../../../components/CustomInputButton";

import { styleCircleBack, styleLabelBack, styleCircle, styleLabel } from "../../../../config/style";
import { getProfile } from "../../../../config/getProfile";
import { changeImage } from "../../../../config/changeImage";
import { handleDateChange, handleCountCharacter, handleNikNpwpChange } from "../../../../config/handleChange";

import "./index.css";

const InformasiProfilIdentitas = ({ 
  style, 
  setStyle, 
  showPage, 
  setShowPage, 
  dataToSend, 
  profilePicture, 
  setProfilePicture,
  nik, 
  setNik,
  npwp, 
  setNpwp
}) => {
  const style1 = isMobile ? { width: "100vw" } : { width: "calc(100vw - 17px)" };
  const style2 = showPage.page1;

  const combinedStyle = {
    ...style1,
    ...style2,
  };

  const fileInputRef = useRef(null);
  const nikInputRef = useRef(null);
  const npwpInputRef = useRef(null);
  const skillRef = useRef(null);

  const [dekripsiDiri, setDeskripsiDiri] = useState("");
  const [nikUI, setNikUI] = useState("");
  const [npwpUI, setNPWPUI] = useState("");

  const handleChangeImageClick = (ref) => {
    ref.click();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (dataToSend.informasiProfil.skills.split(',').length < 3) {
      skillRef.current.setCustomValidity('Please enter at least 3 items separated by commas (",").');
    } else {
      scroll.scrollTo(0);
      setShowPage({
        ...showPage,
        page1: { "transform": "translateX(-200%)" },
        page2: { "transform": "translateX(-200%)" },
        page3: { "transform": "translateX(-200%)" },
        page4: { "transform": "translateX(-200%)" }
      })
      setStyle({
        ...style,
        styleCircle3: styleCircle,
        styleLabel3: styleLabel
      })
    }
  }

  const handleBack = (e) => {
    e.preventDefault();
    scroll.scrollToTop();
    setShowPage({
      ...showPage,
      page1: {"transform": "translateX(0)"},
      page2: {"transform": "translateX(0)"},
      page3: {"transform": "translateX(0)"},
      page4: {"transform": "translateX(0)"}
    })
    setStyle({
      ...style,
      styleCircle2: styleCircleBack,
      styleLabel2: styleLabelBack
    })
  }

  useEffect(() => {
    const getData = async() => {
      await getProfile("informasiProfil")
      .then(response => {
        if(dataToSend.informasiProfil) {
          dataToSend.informasiProfil.nama = response.data.nama;
          dataToSend.informasiProfil.username = response.data.username;
          dataToSend.informasiProfil.email = response.data.email;
          dataToSend.informasiProfil.noTelepon = response.data.noTelepon;
        };
      })
      .catch(error => {
        console.log(error);
      })
    }

    getData();
  },[])

  return (
    <div 
      name="informasiProfilIdentitas" 
      className="lengkapiProfil-container informasiProfilIdentitas"
      style={combinedStyle}
    >
      <div className="lengkapiProfil-wrap">
        <form action="#" onSubmit={handleSubmit} className="informasiProfilIdentitas-item">
          <div className="lengkapiProfil-title">
            <label>Informasi Profil</label>
          </div>
          <div className="informasiProfilIdentitas-item-foto" >
            <div className="informasiProfilIdentitas-item-foto-label">
              <label className="informasiProfilIdentitas-item-foto-title">Foto Profil</label>
              <label className="informasiProfilIdentitas-item-foto-label-warning warning-text">*Maks. 5mb</label>
            </div>
            <div className="informasiProfilIdentitas-item-foto-img">
              <img
                src={profilePicture}
                alt="Foto Profil"
                onClick={() => handleChangeImageClick(fileInputRef.current)}
              />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={(e) => changeImage(e, setProfilePicture, 5, true)}
                required={true}
              />
            </div>
          </div>
          <CustomInput
            label={"Nama Lengkap"}
            warning={"*"}
            type={"text"}
            placeholder={"Masukkan nama lengkap"}
            onChange={(e) => dataToSend.informasiProfil.nama = e.target.value}
            value={dataToSend.informasiProfil.nama}
            required={true}
            readOnly={true}
            disabled={true}
          />
          <CustomInput
            label={"Username"}
            warning={"*"}
            type={"text"}
            placeholder={"Masukkan username"}
            onChange={(e) => dataToSend.informasiProfil.username = e.target.value}
            value={dataToSend.informasiProfil.username}
            required={true}
            readOnly={true}
            disabled={true}
          />
          <CustomInput
            label={"Email"}
            warning={"*"}
            type={"email"}
            placeholder={"Masukkan Email"}
            onChange={(e) => dataToSend.informasiProfil.email = e.target.value}
            value={dataToSend.informasiProfil.email}
            required={true}
            readOnly={true}
            disabled={true}
          />
          <CustomInput
            label={"Nomor Ponsel"}
            warning={"*"}
            ponsel={true}
            labelPonsel={"+62"}
            type={"number"}
            placeholder={"Masukkan nomor ponsel"}
            onChange={(e) => dataToSend.informasiProfil.noTelepon = e.target.value}
            value={dataToSend.informasiProfil.noTelepon}
            required={true}
            readOnly={true}
            disabled={true}
          />
          <CustomInput
            label={"Tanggal Lahir"}
            warning={"*"}
            type={"date"}
            onChange={(e) => handleDateChange(e, dataToSend)}
            required={true}
            max={new Date().toISOString().slice(0, 10)}
          />
          <CustomInput
            label={"Kota Tempat Tinggal"}
            warning={"*"}
            type={"text"}
            placeholder={"Contoh: Jakarta Selatan"}
            onChange={(e) => dataToSend.informasiProfil.kota = e.target.value}
            required={true}
          />
          <CustomInput
            label={"Jenis Kelamin"}
            warning={"*"}
            type={"radio"}
            name={"jenisKelamin"}
            value={["Pria", "Wanita"]}
            defaultChecked={dataToSend.informasiProfil.jenisKelamin}
            onChange={(e) => dataToSend.informasiProfil.jenisKelamin = e.target.value}
            required={true}
          />
          <CustomInput
            label={"Posisi Pekerjaan/Keahlian"}
            warning={"*"}
            type={"text"}
            placeholder={"Contoh: Front End Developer"}
            onChange={(e) => dataToSend.informasiProfil.keahlian = e.target.value}
            required={true}
          />
          <CustomInput
            input={"textarea"}
            label={"Deskripsi Diri"}
            warning={"*"}
            placeholder={"1. Jelasikan berapa lama pengalaman anda untuk setiap kategori yang dipilih.\n2. Informasikan jenis pekerjaan yang sudah diselesaikan sebelumnya.\n3. Informasikan spesialisasi untuk setiap kategori yang dipilh.\n4. Jelaskan mengapa anda layak untuk dipilih oleh klien."}
            descriptionTextArea={"*Dilarang mencantumkan data pribadi seperti nomor telepon, media sosial, dan sejenisnya."}
            rows={10}
            description={"min. 150 Karakter"}
            currentText={dekripsiDiri.length}
            maxText={2000}
            onChange={(e) => handleCountCharacter(e, setDeskripsiDiri, dataToSend, "deskripsiDiri")}
            required={true}
            minLength={150}
            maxLength={2000}
          />
          <CustomInput
            input={"textarea"}
            label={"Skills"}
            warning={"*"}
            placeholder={"Contoh: Visual Studio, Bahasa Pemrograman, Framework"}
            descriptionTextArea={"*Pisah dengan tanda ( , )"}
            innerRef={skillRef}
            rows={2}
            description={"min. 3 Skill / Tools"}
            onChange={(e) => {
              skillRef.current.setCustomValidity('');
              dataToSend.informasiProfil.skills = e.target.value
            }}
            required={true}
          />
          <div className="lengkapiProfil-title">
            <label>Informasi Identitas</label>
          </div>
          <CustomInput
            label={"Nomor NIK"}
            warning={"*"}
            type={"text"}
            placeholder={"Masukkan 16 digit nomor kartu identitas anda"}
            description={"Dibutuhkan untuk melakukan penarikan/pencairan dana"}
            onChange={(e) => handleNikNpwpChange(e, "nik", setNikUI, dataToSend)}
            value={nikUI}
            required={true}
            maxLength={16}
          />
          <CustomInputImage
            onClick={() => handleChangeImageClick(nikInputRef.current)}
            src={nik}
            logo={<Upload className="lengkapiProfil-tambah-svg"/>}
            label={"Upload berkas foto KTP"}
            innerRef={nikInputRef}
            onChange={(e) => changeImage(e, setNik, 5, true)}
            required={true}
            description={"Format (JPG, JPEG, PNG) Maks. 5mb"}
            isImage={true}
          />
          <CustomInput
            label={"Nomor NPWP"}
            warning={"*"}
            type={"text"}
            placeholder={"Masukkan 15 digit nomor kartu NPWP anda"}
            description={"Jika mempunya wajib diisi, jika tidak silahkan diberi angka 0 saja"}
            onChange={(e) => handleNikNpwpChange(e, "npwp", setNPWPUI, dataToSend)}
            value={npwpUI}
            required={true}
            maxLength={15}
          />
          <CustomInputImage
            onClick={() => handleChangeImageClick(npwpInputRef.current)}
            src={npwp}
            logo={<Upload className="lengkapiProfil-tambah-svg"/>}
            label={"Upload berkas foto NPWP"}
            innerRef={npwpInputRef}
            onChange={(e) => changeImage(e, setNpwp, 5, true)}
            required={true}
            description={"Format (JPG, JPEG, PNG) Maks. 5mb"}
            isImage={true}
          />
          <div className="lengkapiProfil-bottom">
            <CustomInputButton
              color={true}
              value={"Selanjutnya"}
            />
            <CustomButton2
              onClick={handleBack}
              label={"Kembali"}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default InformasiProfilIdentitas;