import { useState } from "react";
import { useMediaQuery } from 'react-responsive';

import DaftarChat from "./screens/DaftarChat";

import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";

const ChatPage = () => {
  const [open, setOpen] = useState(false);

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  return (
    <div className="dashboardPage-container">
      <Navbar open={open} setOpen={setOpen}/>
      {!media && (
        <Sidebar open={open} />
      )}
      <DaftarChat />
      <Footer />
    </div>
  )
}

export default ChatPage;