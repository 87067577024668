import React, { useState, useRef, Fragment } from 'react';
import AuthorCard from '../AuthorCard';

import './index.css';

const HorizontalScroll = ({ 
  items, 
  onItemClick,
  author,
  card
 }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const scrollContainerRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 1.5;

    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleItemClick = (item) => {
    if (onItemClick) {
      onItemClick(item);
    }
  };

  return (
    <div
      className={`horizontal-scroll-container ${card ? "card" : ""}`}
      ref={scrollContainerRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUp}
      onTouchStart={(e) => handleMouseDown(e.touches[0])}
      onTouchEnd={handleMouseUp}
      onTouchMove={(e) => handleMouseMove(e.touches[0])}
    >
      <div className={`horizontal-scroll-wrap ${card ? "card" : ""}`}>
        {Array.isArray(items) && items.length > 0 ? (
          items.map((item, index) => (
            author ?
              <AuthorCard
                key={index}
                fotoProfil={item.user.informasiProfil.fotoProfil}
                nama={item.user.informasiProfil.nama}
                rating={item.user.ratingTotal.bintang}
                review={item.user.ratingTotal.review}
                repeat={true}
                onClick={() => handleItemClick(item._id)}
              />
              :
              <div
                key={index}
                className="kategori-scroll-item"
                onClick={() => handleItemClick(index)}
              >
                {item.label}
              </div>
          ))) : (
            <AuthorCard
              fotoProfil={items.user.informasiProfil.fotoProfil}
              nama={items.user.informasiProfil.nama}
              rating={items.user.ratingTotal.bintang}
              review={items.user.ratingTotal.review}
              repeat={true}
              onClick={() => handleItemClick(items._id)}
            />
        )}
      </div>
    </div>
  );
};

export default HorizontalScroll;
