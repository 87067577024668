import { useState } from "react";
import { useMediaQuery } from 'react-responsive';

import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import VerifikasiTerkirim from "./screens/VerifikasiTerkirim";
import Footer from "../../../components/Footer";

const VerifikasiTerkirimPage = () => {
  const [open, setOpen] = useState(false);

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  return (
    <div>
      <Navbar open={open} setOpen={setOpen}/>
      {!media && (
        <Sidebar open={open} />
      )}
      <VerifikasiTerkirim />
      <Footer />
    </div>
  )
}

export default VerifikasiTerkirimPage;