import React, { Fragment, useEffect, useRef, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { isMobile } from 'react-device-detect';
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from "react-router-dom";

import { ReactComponent as SearchWhite } from '../../../../../assets/svg/SearchWhite.svg';
import { ReactComponent as TrashWhite } from '../../../../../assets/svg/TrashWhite.svg';

import CustomInput from "../../../../../components/CustomInput";
import CustomInputSelect from "../../../../../components/CustomInputSelect";
import LihatJobsCard from "../../../../../components/LihatJobsCard";
import HorizontalScroll from "../../../../../components/HorizontalScroll";
import Pagination from "../../../../../components/Pagination";
import NoJobs from "../../../../../components/NoJobs";

import { defaultDiPosting, defaultBudget } from "../../../../../config/defaultData";
import axios from "../../../../../config/api";
import { scrollToTop } from "../../../../../config/scrollTo";
import { handleEnter } from "../../../../../config/handleEnter";

import "./index.css";

const LihatJobs = () => {
  const location = useLocation();

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const dataToSendRef = useRef("");

  const [kategori, setKategori] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedSubItem, setSelectedSubItem] = useState(null);
  const [budget, setBudget] = useState("");
  const [time, setTime] = useState("");
  const [data, setData] = useState([]);
  const [dataRekomendasi, setDataRekomendasi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getData = async () => {
    setLoading(true);

    try {
      const response = await axios.get("/jobhub");
      setData(response.data.job);
      setDataRekomendasi(response.data.rekomendasiJob);
      setPage(Math.ceil(response.data.jumlahDokumen / 5))
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  const handleKategori = async (index) => {
    setLoading(true);
    setSelectedItem(index);
    setSelectedSubItem(null);

    try {
      const response = await axios.get(setUrl(index));
      setData(response.data.job);
      setDataRekomendasi(response.data.rekomendasiJob);
      setPage(Math.ceil(response.data.jumlahDokumen / 5))
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleSubKategori = async (index, indexSelectedItem) => {
    setLoading(true);
    setSelectedSubItem(index);

    try {
      const response = await axios.get(setUrl(indexSelectedItem, index));
      setData(response.data.job);
      setDataRekomendasi(response.data.rekomendasiJob);
      setPage(Math.ceil(response.data.jumlahDokumen / 5));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleSortByBudget = async (e) => {
    setLoading(true);
    setBudget(e.target.value);
    try {
      const response = await axios.get(setUrl(null, null, e.target.value));
      setData(response.data.job);
      setDataRekomendasi(response.data.rekomendasiJob);
      setPage(Math.ceil(response.data.jumlahDokumen / 5));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleTime = async (e) => {
    setLoading(true);
    setTime(e.target.value);

    try {
      const response = await axios.get(setUrl(null, null, null, null, e.target.value));
      setData(response.data.job);
      setDataRekomendasi(response.data.rekomendasiJob);
      setPage(Math.ceil(response.data.jumlahDokumen / 5))
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const searchData = async () => {
    setLoading(true);

    try {
      const response = await axios.get(setUrl());
      setData(response.data.job);
      setDataRekomendasi(response.data.rekomendasiJob);
      setPage(Math.ceil(response.data.jumlahDokumen / 5))
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const clearFilter = () => {
    getData();
    setSelectedItem(null);
    setSelectedSubItem(null);
    setBudget("");
    setTime("");
    dataToSendRef.current.value = "";
  }

  const handlePageClick = async (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);

    try {
      const response = await axios.get(setUrl(null, null, null, pageNumber));
      setData(response.data.job);
      setDataRekomendasi(response.data.rekomendasiJob);
      setPage(Math.ceil(response.data.jumlahDokumen / 5))
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextClick = async () => {
    if (currentPage < page) {
      setLoading(true);
      setCurrentPage(currentPage + 1);

      try {
        const response = await axios.get(setUrl(null, null, null, currentPage + 1));
        setData(response.data.job);
        setDataRekomendasi(response.data.rekomendasiJob);
        setPage(Math.ceil(response.data.jumlahDokumen / 5))
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePreviousClick = async () => {
    if (currentPage > 1) {
      setLoading(true);
      setCurrentPage(currentPage - 1);

      try {
        const response = await axios.get(setUrl(null, null, null, currentPage - 1));
        setData(response.data.job);
        setDataRekomendasi(response.data.rekomendasiJob);
        setPage(Math.ceil(response.data.jumlahDokumen / 5))
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const url = (oldUrl, newUrl) => {
    if(oldUrl === "/jobhub?") return oldUrl+newUrl
    else return `${oldUrl}&${newUrl}`
  }

  const setUrl = (kategoriIndex, subKategoriIndex, sort, pageNumber, timeTmp) => {
    let newUrl = "/jobhub?";

    if (kategoriIndex !== null && kategoriIndex !== undefined) {
      newUrl = url(newUrl, `kategori=${kategori[kategoriIndex]._id}`);
      if (subKategoriIndex !== null && subKategoriIndex !== undefined)
        newUrl = url(newUrl, `subKategori=${kategori[kategoriIndex].subKategori[subKategoriIndex]._id}`);
    } else
      if (selectedItem !== null && selectedItem !== undefined) {
        newUrl = url(newUrl, `kategori=${kategori[selectedItem]._id}`);
        if (subKategoriIndex !== null && subKategoriIndex !== undefined)
          newUrl = url(newUrl, `subKategori=${kategori[selectedItem].subKategori[subKategoriIndex]._id}`);
        else
          if (selectedSubItem !== null && selectedSubItem !== undefined)
            newUrl = url(newUrl, `subKategori=${kategori[selectedItem].subKategori[selectedSubItem]._id}`);
      }

    if (sort !== null && sort !== undefined)
      newUrl = url(newUrl, `sort=${sort}`);
    else
      if (budget !== "")
        newUrl = url(newUrl, `sort=${budget}`);

    if (dataToSendRef.current.value !== "")
      newUrl = url(newUrl, `search=${dataToSendRef.current.value}`);

    if (pageNumber !== null && pageNumber !== undefined)
      newUrl = url(newUrl,`page=${pageNumber}`);

    if (timeTmp !== null && timeTmp !== undefined)
      newUrl = url(newUrl, `time=${timeTmp}`);
    else
      if (time !== "")
        newUrl = url(newUrl, `time=${time}`);

    return newUrl;
  }

  useEffect(() => {
    const getCategory = async() => {
      try{
        const response = await axios.get("/category");
        setKategori(response.data.kategori);
      } catch(error) {
        console.log(error);
      }
    }

    getCategory();
  }, [])

  useEffect(() => {
    if (kategori.length > 0) {
      if (location.state) {
        const from = location.state?.from;

        if (from.search !== null && from.search !== undefined) {
          dataToSendRef.current.value = from.search;
          searchData();
        } else
          if (from.indexSub !== null && from.indexSub !== undefined) {
            handleSubKategori(from.indexSub, from.index);
            setSelectedItem(from.index);
          } else {
            handleKategori(from.index);
            setSelectedItem(from.index);
          }
      } else {
        getData();
      }

      scrollToTop();
      window.history.replaceState(null, document.title, window.location.href);
    }
  }, [kategori])

  return (
    <div className="lihatJobs-container" style={isMobile ? { width: "100vw" } : { width: "calc(100vw - 17px)" }}>
      <div className="lihatJobs-wrap">
        <div className="lihatJobs-wrap-bar">
          <div className="lihatJobs-navigasi">
            <div className="lihatJobs-navigasi-title">Kategori</div>
            {kategori.map((item, index) => (
              <div key={index} onClick={() => handleKategori(index)} className={`lihatJobs-navigasi-subTitle ${selectedItem === index ? 'clicked' : ''}`}>
                {item.label}
              </div>
            ))}
          </div>
          {selectedItem !== null ?
            media ? (
              <div className="lihatJobs-subNavigasi">
                {kategori[selectedItem].subKategori.map((optionDetail, indexDetail) => (
                  <div
                    key={indexDetail}
                    onClick={() => handleSubKategori(indexDetail)}
                    className={`lihatJobs-subNavigasi-item ${selectedSubItem === indexDetail ? 'clicked' : ''}`}
                  >{optionDetail.label}</div>
                ))}
              </div>
            ) : (
              <div className="lihatJobs-subNavigasi-scroll">
                <HorizontalScroll items={kategori[selectedItem].subKategori} onItemClick={(item) => handleSubKategori(item)} />
              </div>
            )
            : null
          }
        </div>
        <div className="lihatJobs-wrap-detail">
          <div className="lihatJobs-filter">
            <CustomInput
              regisLogin={true}
              type={"text"}
              placeholder={"Jenis pekerjaan yang anda cari?"}
              innerRef={(ref) => (dataToSendRef.current = ref)}
              required={true}
              svg={<SearchWhite
                className="lihatJobs-filter-svg"
                onClick={(e) => searchData(e)}
              />}
              svgColor={"#AB1F2A"}
              onKeyDown={(e) => handleEnter(e, searchData)}
            />
            <div className="lihatJobs-filter-option">
              <div className="lihatJobs-filter-option-select">
                <CustomInputSelect
                  regisLogin={true}
                  firstOnChange={(e) => handleSortByBudget(e)}
                  firstData={defaultBudget}
                  firstValue={budget}
                  secondOnChange={(e) => handleTime(e)}
                  secondData={defaultDiPosting}
                  secondValue={time}
                  double={true}
                />
              </div>
              <TrashWhite
                className="lihatJobs-filter-option-svg"
                onClick={clearFilter}
              />
            </div>
          </div>
          {data.length > 0 || dataRekomendasi.length > 0 ? (
            loading ?
              <div className="lihatJobs-card">
                <ClipLoader
                  color={"#AB1F2A"}
                  size={24}
                />
              </div>
              :              
              <Fragment>
                {dataRekomendasi.length > 0 &&
                  <div className="lihatJobs-card" >
                    {dataRekomendasi.map((data, index) => (
                      <Fragment key={index}>
                        <LihatJobsCard
                          data={data}
                        />
                        <div className="lihatJobs-card-line"></div>
                      </Fragment>
                    ))}
                  </div>
                }
                {data.length > 0 &&
                  <Fragment>
                    <div className="lihatJobs-card" >
                      {data.map((data, index) => (
                        <Fragment key={index}>
                          <LihatJobsCard
                            data={data}
                          />
                          <div className="lihatJobs-card-line"></div>
                        </Fragment>
                      ))}
                    </div>
                    <Pagination
                      totalPages={page}
                      currentPage={currentPage}
                      onClickPage={handlePageClick}
                      onClickNext={handleNextClick}
                      onClickPrevious={handlePreviousClick}
                    />
                  </Fragment>
                }
              </Fragment>
          ) :
            <NoJobs />
          }
        </div>
      </div>
    </div>
  )
}

export default LihatJobs;