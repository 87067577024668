import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { useSelector } from "react-redux";

import ProfilSaya from "./screens/ProfilSaya";

import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import Footer from "../../../components/Footer";

import { getProfile } from "../../../config/getProfile";
import { defaultInformasiIdentitas, defaultInformasiPerusahaan, defaultInformasiProfil } from "../../../config/defaultData";
import { convertDate } from "../../../config/convertDate";

import "./index.css";

const ProfilSayaPage = () => {
  const { userData } = useSelector((state) => state.auth);

  var informasiProfilEdit = JSON.parse(JSON.stringify(defaultInformasiProfil));
  informasiProfilEdit.jenisKelamin = [];

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const dataToSendRef = useRef({
    informasiProfil: informasiProfilEdit,
    informasiIdentitas: defaultInformasiIdentitas,
    informasiPerusahaan: defaultInformasiPerusahaan
  })
  const dataToSend = useRef({
    informasiProfil: defaultInformasiProfil,
    informasiIdentitas: defaultInformasiIdentitas,
    informasiPerusahaan: defaultInformasiPerusahaan,
    role: "",
  })

  const [open, setOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState('/fotoProfil/blank-profile.png');
  const [deskripsiDiri, setDeskripsiDiri] = useState("");
  const [nikUI, setNikUI] = useState("");
  const [npwpUI, setNPWPUI] = useState("");
  const [tentangPerusahaan, setTentangPerusahaan] = useState("");

  useEffect(() => {
    const getData = async () => {
      await getProfile("informasiProfil")
        .then(response => {
          if (dataToSendRef.current) {
            const tanggalLahir = convertDate(response.data.tanggalLahir);

            dataToSendRef.current.informasiProfil.nama.value = response.data.nama;
            dataToSendRef.current.informasiProfil.username.value = response.data.username;
            dataToSendRef.current.informasiProfil.email.value = response.data.email;
            dataToSendRef.current.informasiProfil.noTelepon.value = response.data.noTelepon;
            dataToSendRef.current.informasiProfil.tanggalLahir.value = tanggalLahir;
            dataToSendRef.current.informasiProfil.kota.value = response.data.kota;
            dataToSendRef.current.informasiProfil.jenisKelamin[0].checked = dataToSendRef.current.informasiProfil.jenisKelamin[0].value === response.data.jenisKelamin;
            dataToSendRef.current.informasiProfil.jenisKelamin[1].checked = dataToSendRef.current.informasiProfil.jenisKelamin[1].value === response.data.jenisKelamin;

            if (process.env.REACT_APP_FREELANCER === userData.role) {
              dataToSendRef.current.informasiProfil.keahlian.value = response.data.keahlian;
              dataToSendRef.current.informasiProfil.deskripsiDiri.value = response.data.deskripsiDiri;
              dataToSendRef.current.informasiProfil.skills.value = response.data.skills;
              
              setDeskripsiDiri(response.data.deskripsiDiri);
            }

            dataToSend.current.informasiProfil = response.data;

            setProfilePicture(`${process.env.REACT_APP_API_URL}${response.data.fotoProfil}`)
          };
        })
        .catch(error => {
          console.log(error);
        })

      await getProfile("informasiIdentitas")
        .then(response => {
          if (dataToSendRef.current) {
            dataToSendRef.current.informasiIdentitas.nik.value.value = response.data.nik.value;
            setNikUI(response.data.nik.value);

            if (process.env.REACT_APP_FREELANCER === userData.role) {
              dataToSendRef.current.informasiIdentitas.npwp.value.value = response.data.npwp.value;
              setNPWPUI(response.data.npwp.value);
            }

            dataToSend.current.informasiIdentitas = response.data;
          };
        })
        .catch(error => {
          console.log(error);
        })

      if (process.env.REACT_APP_CLIENT === userData.role) {
        await getProfile("informasiPerusahaan")
          .then(response => {
            if (dataToSendRef.current) {
              dataToSendRef.current.informasiPerusahaan.namaPerusahaan.value = response.data.namaPerusahaan;
              dataToSendRef.current.informasiPerusahaan.tentangPerusahaan.value = response.data.tentangPerusahaan;
              dataToSendRef.current.informasiPerusahaan.industri.value = response.data.industri;

              setTentangPerusahaan(response.data.tentangPerusahaan);

              dataToSend.current.informasiPerusahaan = response.data;
            };
          })
          .catch(error => {
            console.log(error);
          })
      }
    }

    getData();
  }, [])

  return (
    <div className="dashboardPage-container">
      <div>
        <Navbar open={open} setOpen={setOpen} />
        {!media && (
          <Sidebar open={open} />
        )}
        <ProfilSaya
          dataToSendRef={dataToSendRef}
          dataToSend={dataToSend}
          profilePicture={profilePicture}
          setProfilePicture={setProfilePicture}
          deskripsiDiri={deskripsiDiri}
          setDeskripsiDiri={setDeskripsiDiri}
          nikUI={nikUI}
          setNikUI={setNikUI}
          npwpUI={npwpUI}
          setNPWPUI={setNPWPUI}
          tentangPerusahaan={tentangPerusahaan}
          setTentangPerusahaan={setTentangPerusahaan}
        />
      </div>
      <Footer />
    </div>
  )
}

export default ProfilSayaPage;