import React from "react";
import { useSelector } from 'react-redux';

import { convertTime } from "../../config/convertDate";

import "./index.css";

const DetailChatCard = ({item}) => {
  const { userData } = useSelector((state) => state.auth);

  return (
    <div className={`detailChatCard-container ${userData._id === item.pengirim ? "pengirim" : ""}`}>
      <div className="detailChatCard-wrap">
        {
          item.dokumen && (
            <div className="detailChatCard-wrap-dokumen">
              <button><a href={`${process.env.REACT_APP_API_URL}/chat/download${item.dokumen}`} download>Unduh Dokumen</a></button>
            </div>
          )
        }
        <p className="detailChatCard-wrap-content">{item.content}</p>
        <div className="detailChatCard-wrap-time">
          <p>{convertTime(item.waktu)}</p>
        </div>
      </div>
    </div>
  )
}

export default DetailChatCard;