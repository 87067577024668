import React, { Fragment, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as Upload } from '../../../../../assets/svg/Upload.svg';
import { ReactComponent as EditTealBlue } from '../../../../../assets/svg/EditTealBlue.svg';

import { errorAlert } from "../../../../../components/Alert";
import CustomButton2 from "../../../../../components/CustomButton2";
import CustomInput from "../../../../../components/CustomInput";
import CustomInputImage from "../../../../../components/CustomInputImage";
import CustomInputSelect from "../../../../../components/CustomInputSelect";
import Navigasi from "../../../../../components/Navigasi";

import { dashboardFreelanceLink, dashboardClientLink } from "../../../../../config/dashboardLink";
import { HEADER_NO_COOKIE_FORM_DATA } from "../../../../../config/headers";
import axios from "../../../../../config/api";
import { updateProfile } from "../../../../../config/authActions";
import { changeImage } from "../../../../../config/changeImage";
import { handleDateChange, handleCountCharacter, handleNikNpwpChange } from "../../../../../config/handleChange";
import CustomInputButton from "../../../../../components/CustomInputButton";

import "./index.css";

const ProfilSaya = ({
  dataToSendRef,
  dataToSend,
  profilePicture,
  setProfilePicture,
  deskripsiDiri, 
  setDeskripsiDiri,
  nikUI, 
  setNikUI,
  npwpUI, 
  setNPWPUI,
  tentangPerusahaan,
  setTentangPerusahaan
}) => {
  const dispatch = useDispatch();

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const navigate = useNavigate();

  const fileInputRef = useRef(null);
  const nikInputRef = useRef(null);
  const npwpInputRef = useRef(null);

  const { userData } = useSelector((state) => state.auth);

  const [loadingProfil, setLoadingProfil] = useState(false);
  const [loadingIdentitas, setLoadingIdentitas] = useState(false);
  const [loadingPerusahaan, setLoadingPerusahaan] = useState(false);
  const [editableProfil, setEditableProfil] = useState(true);
  const [editableIdentitas, setEditableIdentitas] = useState(true);
  const [editablePerusahaan, setEditablePerusahaan] = useState(true);
  const [nik, setNik] = useState(null);
  const [npwp, setNpwp] = useState(null);
  const [industri, setIndustri] = useState([]);

  const handleChangeImageClick = (ref) => {
    ref.click();
  }

  const handleSubmitProfil = async (e) => {
    e.preventDefault();
    if (process.env.REACT_APP_FREELANCER === userData.role && dataToSend.current.informasiProfil.skills.split(',').length < 3) {
      dataToSendRef.current.informasiProfil.skills.setCustomValidity('Please enter at least 3 items separated by commas (",").');
    } else {
      setLoadingProfil(true);
      const formData = new FormData();

      const profilePictureBlob = await fetch(profilePicture).then(response => response.blob());

      formData.append('fotoProfil', profilePictureBlob);
      formData.append("informasiProfil", JSON.stringify(dataToSend.current.informasiProfil));

      try {
        const response = await axios.put(`/account/${process.env.REACT_APP_CLIENT === userData.role ? "client" : "freelancer"}/profil`, formData, HEADER_NO_COOKIE_FORM_DATA);

        dispatch(updateProfile(response.data));

        navigate(0);
      } catch (error) {
        errorAlert(error.response.data.pesan);
      } finally {
        setLoadingProfil(false);
      }
    }
  }

  const handleSubmitPerusahaan = async () => {
    setLoadingPerusahaan(true);
    const formData = new FormData();

    formData.append("informasiPerusahaan", JSON.stringify(dataToSend.current.informasiPerusahaan));

    try {
      await axios.put(`/account/client/perusahaan`, formData, HEADER_NO_COOKIE_FORM_DATA);
      navigate(0);
      window.location.reload();
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoadingPerusahaan(false);
    }
  }

  const handleSubmitIdentitas = async () => {
    setLoadingIdentitas(true);
    const formData = new FormData();

    if(nik) {
      const nikBlob = await fetch(nik).then(response => response.blob());
      formData.append('nik', nikBlob);
    }

    if(npwp) {
      const npwpBlob = await fetch(npwp).then(response => response.blob());
      formData.append('npwp', npwpBlob);
    }
    
    formData.append("informasiIdentitas", JSON.stringify(dataToSend.current.informasiIdentitas));

    try {
      await axios.put(`/account/${process.env.REACT_APP_CLIENT === userData.role ? "client" : "freelancer"}/identitas`, formData, HEADER_NO_COOKIE_FORM_DATA);
      navigate(0);
      window.location.reload();
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoadingIdentitas(false);
    }
  }

  const handleBack = (e, setEditable) => {
    e.preventDefault();
    setEditable(true);
  }

  useEffect(() => {
    const getData = async() => {
      try{
        const response = await axios.get("/industry");
        setIndustri(response.data.industri);
      } catch(error) {
        console.log(error);
      }
    }
    
    getData();
  },[])

  return (
    <div className="dashboard-container">
      <div className="dashboard-wrap">
        {media ?
          <div className="dashboard-bar">
            <Navigasi
              title={"Dashboard"}
              data={userData.role === process.env.REACT_APP_FREELANCER ? dashboardFreelanceLink : dashboardClientLink}
              location={0}
            />
          </div>
          :
          null
        }
        <div className="dashboard-detail">
          <div className="dashboard-title">
            <h3>Informasi Profil</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <div className="dashboard-item">
            <form action="#" onSubmit={handleSubmitProfil} className="informasiProfilIdentitas-item">
              <div className="profilSaya-edit">
                <div
                  className="profilSaya-edit-wrap"
                  onClick={() => setEditableProfil(false)}
                >
                  Edit
                  <EditTealBlue className="profilSaya-edit-svg" />
                </div>
              </div>
              <div className="informasiProfilIdentitas-item-foto" >
                <div className="informasiProfilIdentitas-item-foto-label">
                  <label className="informasiProfilIdentitas-item-foto-title">Foto Profil</label>
                  <label className="informasiProfilIdentitas-item-foto-label-warning warning-text">*Maks. 5mb</label>
                </div>
                <div className="informasiProfilIdentitas-item-foto-img">
                  <img
                    src={profilePicture}
                    alt="Foto Profil"
                    onClick={() => handleChangeImageClick(fileInputRef.current)}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={(e) => changeImage(e, setProfilePicture, 5, true)}
                    readOnly={editableProfil}
                    disabled={editableProfil}
                  />
                  <p style={userData.verifikasiProfil ? {backgroundColor: "#28a745"} : {backgroundColor: "#dc3545"}}>
                    {userData.verifikasiProfil ? "Verified" : "Not Verified"}
                  </p>
                </div>
              </div>
              <CustomInput
                label={"Nama Lengkap"}
                warning={"*"}
                type={"text"}
                placeholder={"Masukkan nama lengkap"}
                onChange={(e) => dataToSend.current.informasiProfil.nama = e.target.value}
                required={true}
                readOnly={editableProfil}
                disabled={editableProfil}
                innerRef={(ref) => dataToSendRef.current.informasiProfil.nama = ref}
              />
              <CustomInput
                label={"Username"}
                warning={"*"}
                type={"text"}
                placeholder={"Masukkan username"}
                onChange={(e) => dataToSend.current.informasiProfil.username = e.target.value}
                required={true}
                readOnly={true}
                disabled={true}
                innerRef={(ref) => dataToSendRef.current.informasiProfil.username = ref}
              />
              <CustomInput
                label={"Email"}
                warning={"*"}
                type={"email"}
                placeholder={"Masukkan Email"}
                onChange={(e) => dataToSend.current.informasiProfil.email = e.target.value}
                required={true}
                readOnly={true}
                disabled={true}
                innerRef={(ref) => dataToSendRef.current.informasiProfil.email = ref}
              />
              <CustomInput
                label={"Nomor Ponsel"}
                warning={"*"}
                ponsel={true}
                labelPonsel={"+62"}
                type={"number"}
                placeholder={"Masukkan nomor ponsel"}
                onChange={(e) => dataToSend.current.informasiProfil.noTelepon = e.target.value}
                required={true}
                readOnly={editableProfil}
                disabled={editableProfil}
                innerRef={(ref) => dataToSendRef.current.informasiProfil.noTelepon = ref}
              />
              <CustomInput
                label={"Tanggal Lahir"}
                warning={"*"}
                type={"date"}
                onChange={(e) => handleDateChange(e, dataToSend.current)}
                required={true}
                readOnly={editableProfil}
                disabled={editableProfil}
                innerRef={(ref) => dataToSendRef.current.informasiProfil.tanggalLahir = ref}
                max={new Date().toISOString().slice(0, 10)}
              />
              <CustomInput
                label={"Kota Tempat Tinggal"}
                warning={"*"}
                type={"text"}
                placeholder={"Contoh: Jakarta Selatan"}
                onChange={(e) => dataToSend.current.informasiProfil.kota = e.target.value}
                required={true}
                readOnly={editableProfil}
                disabled={editableProfil}
                innerRef={(ref) => dataToSendRef.current.informasiProfil.kota = ref}
              />
              <CustomInput
                label={"Jenis Kelamin"}
                warning={"*"}
                type={"radio"}
                name={"jenisKelamin"}
                value={["Pria", "Wanita"]}
                onChange={(e) => dataToSend.current.informasiProfil.jenisKelamin = e.target.value}
                required={true}
                readOnly={editableProfil}
                disabled={editableProfil}
                innerRef={[(ref) => dataToSendRef.current.informasiProfil.jenisKelamin[0] = ref, (ref) => dataToSendRef.current.informasiProfil.jenisKelamin[1] = ref]}
              />
              {process.env.REACT_APP_FREELANCER === userData.role &&
                <Fragment>
                  <CustomInput
                    label={"Posisi Pekerjaan/Keahlian"}
                    warning={"*"}
                    type={"text"}
                    placeholder={"Contoh: Front End Developer"}
                    onChange={(e) => dataToSend.current.informasiProfil.keahlian = e.target.value}
                    required={true}
                    readOnly={editableProfil}
                    disabled={editableProfil}
                    innerRef={(ref) => dataToSendRef.current.informasiProfil.keahlian = ref}
                  />
                  <CustomInput
                    input={"textarea"}
                    label={"Deskripsi Diri"}
                    warning={"*"}
                    placeholder={"1. Jelasikan berapa lama pengalaman anda untuk setiap kategori yang dipilih.\n2. Informasikan jenis pekerjaan yang sudah diselesaikan sebelumnya.\n3. Informasikan spesialisasi untuk setiap kategori yang dipilh.\n4. Jelaskan mengapa anda layak untuk dipilih oleh klien."}
                    descriptionTextArea={"*Dilarang mencantumkan data pribadi seperti nomor telepon, media sosial, dan sejenisnya."}
                    rows={10}
                    description={"min. 150 Karakter"}
                    onChange={(e) => handleCountCharacter(e, setDeskripsiDiri, dataToSend.current, "deskripsiDiri")}
                    currentText={deskripsiDiri.length}
                    maxText={2000}
                    required={true}
                    readOnly={editableProfil}
                    disabled={editableProfil}
                    innerRef={(ref) => dataToSendRef.current.informasiProfil.deskripsiDiri = ref}
                    minLength={150}
                    maxLength={2000}
                  />
                  <CustomInput
                    input={"textarea"}
                    label={"Skills"}
                    warning={"*"}
                    placeholder={"Contoh: Visual Studio, Bahasa Pemrograman, Framework"}
                    descriptionTextArea={"*Pisah dengan tanda ( , )"}
                    rows={2}
                    description={"min. 3 Skill / Tools"}
                    onChange={(e) => {
                      dataToSendRef.current.informasiProfil.skills.setCustomValidity('');
                      dataToSend.current.informasiProfil.skills = e.target.value
                    }}
                    required={true}
                    readOnly={editableProfil}
                    disabled={editableProfil}
                    innerRef={(ref) => dataToSendRef.current.informasiProfil.skills = ref}
                  />
                </Fragment>
              }
              {!editableProfil &&
                <div className="lengkapiProfil-bottom">
                  {loadingProfil ?
                    <CustomButton2
                      color={true}
                      label={
                        <BeatLoader
                          color={"#E4E4E7"}
                          size={10}
                        />
                      }
                    />
                    :
                    <CustomInputButton
                      color={true}
                      value={"Simpan"}
                    />
                  }
                  <CustomButton2
                    onClick={(e) => handleBack(e, setEditableProfil)}
                    label={"Batal"}
                  />
                </div>
              }
            </form>
          </div>
          {process.env.REACT_APP_CLIENT === userData.role &&
            <Fragment>
              <div className="dashboard-title">
                <h3>Informasi Perusahaan</h3>
              </div>
              <div className="dashboard-item">
                <form action="#" onSubmit={handleSubmitPerusahaan} className="informasiProfilIdentitas-item">
                  <div className="profilSaya-edit">
                    <div
                      className="profilSaya-edit-wrap"
                      onClick={() => setEditablePerusahaan(false)}
                    >
                      Edit
                      <EditTealBlue className="profilSaya-edit-svg" />
                    </div>
                  </div>
                  <CustomInput
                    label={"Nama Perusahaan"}
                    warning={"*"}
                    type={"text"}
                    placeholder={"Masukkan nama perusahaan"}
                    onChange={(e) => dataToSend.current.informasiPerusahaan.namaPerusahaan = e.target.value}
                    required={true}
                    readOnly={true}
                    disabled={true}
                    innerRef={(ref) => dataToSendRef.current.informasiPerusahaan.namaPerusahaan = ref}
                  />
                  <CustomInput
                    input={"textarea"}
                    label={"Tentang Perusahaan"}
                    warning={"*"}
                    placeholder={"Tentang Perusahaan ..."}
                    rows={10}
                    description={"min. 100 Karakter"}
                    currentText={tentangPerusahaan.length}
                    maxText={2000}
                    onChange={(e) => handleCountCharacter(e, setTentangPerusahaan, dataToSend.current, "tentangPerusahaan")}
                    required={true}
                    readOnly={editablePerusahaan}
                    disabled={editablePerusahaan}
                    innerRef={(ref) => dataToSendRef.current.informasiPerusahaan.tentangPerusahaan = ref}
                    minLength={100}
                    maxLength={2000}
                  />
                  <CustomInputSelect
                    label={"Industri"}
                    warning={"*"}
                    firstOnChange={(e) => (dataToSend.current.informasiPerusahaan.industri = e.target.value)}
                    firstData={industri}
                    firstDisabled={editablePerusahaan}
                    firstRef={(ref) => dataToSendRef.current.informasiPerusahaan.industri = ref}
                  />
                  {!editablePerusahaan &&
                    <div className="lengkapiProfil-bottom">
                      {loadingPerusahaan ?
                        <CustomButton2
                          color={true}
                          label={
                            <BeatLoader
                              color={"#E4E4E7"}
                              size={10}
                            />
                          }
                        />
                        :
                        <CustomInputButton
                          color={true}
                          value={"Simpan"}
                        />
                      }
                      <CustomButton2
                        onClick={(e) => handleBack(e, setEditablePerusahaan)}
                        label={"Batal"}
                      />
                    </div>
                  }
                </form>
              </div>
            </Fragment>
          }
          <div className="dashboard-title">
            <h3>Informasi Identitas</h3>
          </div>
          <div className="dashboard-item">
            <form action="#" className="informasiProfilIdentitas-item">
              <div className="profilSaya-edit">
                <div
                  className="profilSaya-edit-wrap"
                  onClick={() => setEditableIdentitas(false)}
                >
                  Edit
                  <EditTealBlue className="profilSaya-edit-svg" />
                </div>
              </div>
              <CustomInput
                label={"Nomor NIK"}
                warning={"*"}
                type={"text"}
                placeholder={"Masukkan 16 digit nomor kartu identitas anda"}
                description={"Dibutuhkan untuk melakukan penarikan/pencairan dana"}
                onChange={(e) => handleNikNpwpChange(e, "nik", setNikUI, dataToSend.current)}
                value={nikUI}
                required={true}
                readOnly={editableIdentitas}
                disabled={editableIdentitas}
                innerRef={(ref) => dataToSendRef.current.informasiIdentitas.nik.value = ref}
                maxLength={16}
              />
              <CustomInputImage
                onClick={() => handleChangeImageClick(nikInputRef.current)}
                src={nik}
                logo={<Upload className="lengkapiProfil-tambah-svg" />}
                label={"Upload berkas foto KTP"}
                innerRef={nikInputRef}
                onChange={(e) => changeImage(e, setNik, 5, true)}
                required={true}
                description={"Format (JPG, JPEG, PNG) Maks. 5mb"}
                readOnly={editableIdentitas}
                disabled={editableIdentitas}
              />
              {process.env.REACT_APP_FREELANCER === userData.role &&
                <Fragment>
                  <CustomInput
                    label={"Nomor NPWP"}
                    warning={"*"}
                    type={"text"}
                    placeholder={"Masukkan 15 digit nomor kartu NPWP anda"}
                    description={"Jika mempunya wajib diisi, jika tidak silahkan diberi angka 0 saja"}
                    onChange={(e) => handleNikNpwpChange(e, "npwp", setNPWPUI, dataToSend.current)}
                    value={npwpUI}
                    required={true}
                    readOnly={editableIdentitas}
                    disabled={editableIdentitas}
                    innerRef={(ref) => dataToSendRef.current.informasiIdentitas.npwp.value = ref}
                    maxLength={15}
                  />
                  <CustomInputImage
                    onClick={() => handleChangeImageClick(npwpInputRef.current)}
                    src={npwp}
                    logo={<Upload className="lengkapiProfil-tambah-svg" />}
                    label={"Upload berkas foto NPWP"}
                    innerRef={npwpInputRef}
                    onChange={(e) => changeImage(e, setNpwp, 5, true)}
                    required={true}
                    description={"Format (JPG, JPEG, PNG) Maks. 5mb"}
                    readOnly={editableIdentitas}
                    disabled={editableIdentitas}
                  />
                </Fragment>
              }
            </form>
            {!editableIdentitas &&
              <div className="lengkapiProfil-bottom">
                <CustomButton2
                  color={true}
                  label={loadingIdentitas ?
                    <BeatLoader
                      color={"#E4E4E7"}
                      size={10}
                    />
                    :
                    "Simpan"
                  }
                  onClick={handleSubmitIdentitas}
                />
                <CustomButton2
                  onClick={(e) => handleBack(e, setEditableIdentitas)}
                  label={"Batal"}
                />
              </div>
            }
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default ProfilSaya;