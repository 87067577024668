import React, { Fragment, useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { getFileType } from "../../config/fileType";

import "./index.css";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const CustomInputImage = ({
  title,
  warning,
  onClick,
  src,
  logo,
  label,
  innerRef,
  onChange,
  required,
  description,
  disabled,
  readOnly,
  regisLogin,
  file
}) => {
  const customInputImageContainer = regisLogin ? "custom-input-image-container" : "custom-input-image-container noRegisLogin";
  const customInputLabel = regisLogin ? "custom-input-label" : "custom-input-label noRegisLogin";
  const customInputImageWrap = regisLogin ? "custom-input-image-wrap" : "custom-input-image-wrap noRegisLogin";

  const [fileType, setFileType] = useState(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const fetchFileType = async () => {
      if (src) {
        const extension = await getFileType(src);
        setFileType(extension);
      }
    };

    fetchFileType();
  }, [src]);

  return (
    <div className={customInputImageContainer}>
      <div className={customInputLabel}>
        <label className="custom-input-label-title">{title}</label>
        <label className="custom-input-label-warning warning-text">{warning}</label>
      </div>
      <div className={customInputImageWrap}>
        <div className="custom-input-image-input" onClick={onClick}>
          <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
          {src && fileType ?
            fileType === "pdf" ? (
              <Document
                file={src}
                onLoadError={(error) => console.error("Error loading document:", error)}
              >
                <Page pageNumber={1} height={140} />
              </Document>
            ) : fileType === "word" ||
              fileType === "excel" ||
              fileType === "powerpoint" ? (
              file && <img src={file} alt="Uploaded File" />
            ) : fileType === "video" ? (
              <video controls height="140">
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) :
              (
                <img src={src} alt="Uploaded File" />
              )
            : (
              <Fragment>
                {logo}
                {label}
              </Fragment>
            )}
        </div>
        <label className="custom-input-label-warning warning-text">{description}</label>
        <input
          type="file"
          ref={innerRef}
          style={{ display: 'none' }}
          onChange={onChange}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default CustomInputImage;