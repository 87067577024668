import React, { useState, useEffect} from "react";
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';

import DetailChat from "../DetailChat";

import { ReactComponent as SearchGrey } from '../../../../assets/svg/SearchGrey.svg';
import { ReactComponent as Filter } from '../../../../assets/svg/Filter.svg';

import AktivitasCard from "../../../../components/AktivitasCard";
import ChatCard from "../../../../components/ChatCard";

import axios from "../../../../config/api";

import "./index.css";

const DaftarChat = () => {
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const { userData } = useSelector((state) => state.auth);

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const openModal = (index) => {
    setSelectedItem(index);
    setModalOpen(true);
  }

  const closeModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  }

  useEffect(() => {
    const getData = async() => {
      setLoading(true);
      try{
        const response = await axios.get("/chat");
        setData(response.data);
      } catch(error) {
        console.log(error);
      } finally {
        setLoading(false)
      }
    }
    
    getData();
  },[])

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL);

    socket.on('connect', () => {
      console.log('Connected to server');
    });

    data?.forEach(data => {
      socket.on(data._id, (newMessage) => {
          setData(prevData => prevData.map(item => {
              if (item._id === newMessage._id) {
                  return {
                      ...item,
                      pesan: [...item.pesan, newMessage.pesan]
                  };
              }
              return item;
          }));
      });
  });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      socket.disconnect();
    };
  },[data])

  return (
    <div className="dashboard-container">
      <div className="dashboard-wrap daftarChat-wrap">
        <div className="dashboard-detail daftarChat-detail-top">
          <div className="dashboard-title daftarChat-title">
            <h3>Chat</h3>
          </div>
          {/* <div className="dashboard-item">
            <PesanCard chat={true}/>
          </div> */}
        </div>
        <div className="daftarChat-detail">
          <div className="daftarChat-item">
            <div className="daftarChat-item-search">
              <input
                type="text"
                placeholder="Cari pesan/kontak"
              />
              <SearchGrey className="daftarChat-item-search-svg" />
            </div>
            <div className="daftarChat-item-filter">
              <div className="daftarChat-item-filter-detail">
                Filter
                <Filter className="daftarChat-item-filter-svg" />
              </div>
            </div>
            <div className="daftarChat-item-card">
              {data?.map((item, index) => {
                const participantIndex = userData.role === process.env.REACT_APP_FREELANCER ? 0 : 1;
                const lastMessage = item.pesan.length > 0 ? item.pesan[item.pesan.length - 1] : null;

                return (
                  <ChatCard
                    key={index}
                    profile={item.partisipasi[participantIndex]}
                    lastChat={lastMessage ? lastMessage.content : null}
                    lastChatTime={lastMessage ? lastMessage.waktu : null}
                    onClick={() => openModal(index)}
                  />
                );
              })}
            </div>
          </div>
          {media ?
            selectedItem === null ?
              <DetailChat
                isOpen={true}
              />
              :
              data && data[selectedItem] && (
                <DetailChat
                  isOpen={true}
                  id={data[selectedItem]._id}
                  profile={data[selectedItem].partisipasi[userData.role === process.env.REACT_APP_FREELANCER ? 0 : 1]}
                  message={data[selectedItem].pesan}
                  status={data[selectedItem].status}
                />
              )
            :
            null
          }
        </div>
        {!media && data && data[selectedItem] && (
          <DetailChat
            isOpen={isModalOpen}
            closeModal={closeModal}
            id={data[selectedItem]._id}
            profile={data[selectedItem].partisipasi[userData.role === process.env.REACT_APP_FREELANCER ? 0 : 1]}
            message={data[selectedItem].pesan}
          />
        )}
      </div>
    </div>
  )
}

export default DaftarChat