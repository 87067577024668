import React from "react";

import { defaultFotoProfil } from "../../config/defaultData";
import { convertTime } from "../../config/convertDate";

import "./index.css";

const ChatCard = ({
  profile, 
  lastChat,
  lastChatTime,
  onClick
}) => {
  return (
    <div 
      className="chatCard-wrap"
      onClick={onClick}>
      <div className="chatCard-detail">
        <img
          src={profile ? `${process.env.REACT_APP_API_URL}${profile.informasiProfil.fotoProfil}` : `${process.env.REACT_APP_API_URL}${defaultFotoProfil}`}
          alt="Foto Profil"
        />
        <div className="chatCard-detail-item">
          <div className="chatCard-detail-item-title">
            <h4>{profile.informasiProfil.username}</h4>
            <p>{convertTime(lastChatTime)}</p>
          </div>
          {/* <h5>{lastChat}</h5> */}
        </div>
      </div>
    </div>
  )
}

export default ChatCard;