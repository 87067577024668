import React, { useEffect, useState } from "react";

import axios from "../../../../../../../config/api";

import AktivitasCard from "../../../../../../../components/AktivitasCard";

import "./index.css";
import "../../index.css";

const ProsesAktivitas = () => {
  const [proses, setProses] = useState([]);

  useEffect(() => {
    const getData = async () => {
      await axios.get("/activity?type=proses")
        .then(response => {
          setProses(response.data);
        })
        .catch(error => {
          console.log(error);
        })
    }

    getData();
  }, [])
  
  return (
    proses?.map((item, index) => 
      <AktivitasCard
        key={index}
        item={item.aktivitas}
      />
    )
  )
}

export default ProsesAktivitas;