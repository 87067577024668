import React, { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import { ReactComponent as DetailData } from '../../../../../assets/svg/DetailData.svg';
import { ReactComponent as TolakData } from '../../../../../assets/svg/TolakData.svg';
import { ReactComponent as TerimaData } from '../../../../../assets/svg/TerimaData.svg';

import Navigasi from "../../../../../components/Navigasi";
import Pagination from "../../../../../components/Pagination";
import CustomCheckbox from "../../../../../components/CustomCheckbox";

import axios from "../../../../../config/api";
import { HEADER_NO_COOKIE } from "../../../../../config/headers";
import { dashboardAdminLink, dashboardModeratorLink } from "../../../../../config/dashboardLink";

import "./index.css";

const KelolaJob = () => {
  var limit = 10;

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const { userData } = useSelector((state) => state.auth);

  const [job, setJob] = useState([]);
  const [page, setPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const updateStatusHandle = async (id, status) => {
    try {
      await axios.put(`/job/status/${id}`, {
        status: status
      }, HEADER_NO_COOKIE);
      handlePageClick(currentPage);
    } catch (error) {
      console.log(error);
    }
  }

  const handlePageClick = async(pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);

    try{
      const response = await axios.get(setUrl(pageNumber));
      setJob(response.data.job);
      setPage(Math.ceil(response.data.jumlahDokumen/limit))
    } catch(error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextClick = async() => {
    if (currentPage < page) {
      setLoading(true);
      setCurrentPage(currentPage + 1);
      try{
        const response = await axios.get(setUrl(currentPage + 1));
        setJob(response.data.job);
        setPage(Math.ceil(response.data.jumlahDokumen/limit))
      } catch(error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const checkJobs = (id) => {
    const url = `/lihat-jobs/${id}`
    window.open(url);
  }

  const handlePreviousClick = async() => {
    if (currentPage > 1) {
      setLoading(true);
      setCurrentPage(currentPage - 1);
      try{
        const response = await axios.get(setUrl(currentPage - 1));
        setJob(response.data.job);
        setPage(Math.ceil(response.data.jumlahDokumen/limit))
      } catch(error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const setUrl = (pageNumber) => {
    let url = `/jobhub?page=${pageNumber}&limit=${limit}`;
    var newUrl = url;

    return newUrl;
  }

  const updateRekomendasiHandle = async(id, rekomendasi) => {
    try {
      await axios.put(`/job/rekomendasi/${id}`, {
        rekomendasi: rekomendasi
      }, HEADER_NO_COOKIE);
      handlePageClick(currentPage);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const getJob = async () => {
      await axios.get(`/jobhub?limit=${limit}`)
        .then(response => {
          setJob(response.data.job);
          setPage(Math.ceil(response.data.jumlahDokumen/limit))
        })
        .catch(error => {
          console.log(error);
        })
    }

    getJob();
  }, [])

  return (
    <div className="dashboard-container">
      <div className="dashboard-wrap">
        {media ?
          <div className="dashboard-bar">
            <Navigasi
              title={"Dashboard"}
              data={userData.role === process.env.REACT_APP_ADMIN ? dashboardAdminLink : dashboardModeratorLink}
              location={userData.role === process.env.REACT_APP_ADMIN ? 3 : 1}
            />
          </div>
          :
          null
        }
        <div className="dashboard-detail">
          <div className="dashboard-title">
            <h3>Kelola Job</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <div className="dashboard-title">
            <h4>Job Posting User Klien</h4>
          </div>
          <table className="dashboard-table">
            <thead className="dashboard-table-head">
              <tr>
                <th className="dashboard-table-head-detail">#</th>
                <th className="dashboard-table-head-detail">Nama</th>
                <th className="dashboard-table-head-detail">Nama Perusahaan</th>
                <th className="dashboard-table-head-detail">Judul Pekerjaan</th>
                <th className="dashboard-table-head-detail">Status</th>
                <th className="dashboard-table-head-detail">Negosiasi</th>
                <th className="dashboard-table-head-detail">Pembayaran</th>
                <th className="dashboard-table-head-detail">Aksi</th>
                <th className="dashboard-table-head-detail">Rekomendasi</th>
              </tr>
            </thead>
            <tbody className="dashboard-table-body">
              {job.map((item, index) => (
                <tr key={item._id}>
                  <td className="dashboard-table-body-detail">{((currentPage-1) * limit) + (index + 1)}</td>
                  <td className="dashboard-table-body-detail">{item.author.informasiProfil.nama}</td>
                  <td className="dashboard-table-body-detail">{item.author.informasiPerusahaan.namaPerusahaan}</td>
                  <td className="dashboard-table-body-detail">{item.job.detilJobBudget.judulPekerjaan}</td>
                  <td className="dashboard-table-body-detail">{item.job.status}</td>
                  <td className="dashboard-table-body-detail">{item.job.detilJobBudget.freelanceNegosiasi === true ? <TerimaData/> : <TolakData/>}</td>
                  <td className="dashboard-table-body-detail">{item.job.payment_info.status === null ? "Belum Proses" : item.job.payment_info.status}</td>
                  <td className="dashboard-table-body-detail">
                    <div className="dashboard-table-body-detail-action">
                      {item.job.status === "Proses" ? (
                        <>
                          <TerimaData className="dashboard-moderator-svg" onClick={() => updateStatusHandle(item.job._id, "Dibuka")} />
                          <TolakData className="dashboard-moderator-svg" onClick={() => updateStatusHandle(item.job._id, "Ditolak")} />
                          <DetailData className="dashboard-moderator-svg" onClick={() => checkJobs(item.job._id)} />
                        </>
                      ) : (
                        <DetailData className="dashboard-moderator-svg" onClick={() => checkJobs(item.job._id)} />
                      )}
                    </div>
                  </td>
                  <td className="dashboard-table-body-detail kelolaJob-table-body-detail">
                    <div className="dashboard-table-body-detail-action">
                      <CustomCheckbox
                        id={index}
                        checked={item.job.rekomendasi}
                        onChange={() => updateRekomendasiHandle(item.job._id, !item.job.rekomendasi)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="10">
                  <div className="dashboard-moderator-pagination">
                    <Pagination
                      totalPages={page}
                      currentPage={currentPage}
                      onClickPage={handlePageClick}
                      onClickNext={handleNextClick}
                      onClickPrevious={handlePreviousClick}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default KelolaJob;