export const HEADER_NO_COOKIE = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true
};

export const HEADER_NO_COOKIE_FORM_DATA = {
  headers:{
    "Access-Control-Allow-Origin": true,
    "Content-Type": "multipart/form-data"
  }
}