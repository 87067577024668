const initialState = {
  userData: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, userData: action.payload.userData };
    case 'LOGOUT':
      return { ...state, userData: null };
    case 'UPDATE_PROFILE':
      return { ...state, userData: action.payload.userData };
    case 'UPDATE_VERIFICATION':
      return {
        ...state, userData: {
          ...state.userData,
          verifikasiEmail: action.payload.userData.verifikasiEmail,
          verifikasiKelengkapanData: action.payload.userData.verifikasiKelengkapanData
        }
      };
    default:
      return state;
  }
};

export default authReducer;
