export const getFileType = async(fileName) => {
  const fileBlob = await fetch(fileName).then(response => response.blob());
  const extension = fileBlob.type.split("/")[1];
  if (extension === "pdf") {
    return "pdf";
  } else if (extension === "msword" || extension === "vnd.openxmlformats-officedocument.wordprocessingml.document") {
    return "word";
  } else if (extension === "vnd.ms-excel" || extension === "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
    return "excel";
  } else if (extension === "vnd.ms-powerpoint" || extension === "vnd.openxmlformats-officedocument.presentationml.presentation") {
    return "powerpoint";
  } else if (extension === "mp4" || extension === "webm" || extension === "ogg") {
    return "video";
  } else {
    return "other";
  }
};