import React, { useState, useRef } from "react";
import { useMediaQuery } from 'react-responsive';
import { useSelector } from "react-redux";

import Minat from "./screens/Minat";
import InformasiProfilIdentitas from "./screens/InformasiProfilIdentitas";
import InformasiProfilIdentitasPerusahaan from "./screens/InformasiProfilIdentitasPerusahaan";
import PengalamanPendidikan from "./screens/PengalamanPendidikan";
import Portofolio from "./screens/Portofolio";

import Navbar from "../../components/Navbar";
import NavbarIndikatorLengkapiProfil from "../../components/NavbarIndikator/LengkapiProfil";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";

import { defaultInformasiProfil, defaultInformasiIdentitas, defaultInformasiPerusahaan, defaultDataPengalaman, defaultDataPendidikan } from "../../config/defaultData";

import "./index.css";

const LengkapiProfilPage = () => {
  const { userData } = useSelector((state) => state.auth);

  var pengalamanTemp = JSON.parse(JSON.stringify(defaultDataPengalaman));
  var pendidikanTemp = JSON.parse(JSON.stringify(defaultDataPendidikan));

  const dataToSend = useRef(userData.role === process.env.REACT_APP_FREELANCER ? {
    minat: new Array(),
    informasiProfil: defaultInformasiProfil,
    informasiIdentitas: defaultInformasiIdentitas,
    pengalaman: [pengalamanTemp],
    pendidikan: [pendidikanTemp],
    portofolio: new Array()
  } : userData.role === process.env.REACT_APP_CLIENT ? {
    informasiProfil: defaultInformasiProfil,
    informasiIdentitas: defaultInformasiIdentitas,
    informasiPerusahaan: defaultInformasiPerusahaan
  } : null)

  const [showPage, setShowPage] = useState(userData.role === process.env.REACT_APP_FREELANCER ? {
    page1: {},
    page2: {},
    page3: {},
    page4: {}
  } : userData.role === process.env.REACT_APP_CLIENT ? {
    page1: {}
  } : null)
  const [open, setOpen] = useState(false);
  const [style, setStyle] = useState(userData.role === process.env.REACT_APP_FREELANCER ? {
    styleCircle1: {},
    styleCircle2: {},
    styleCircle3: {},
    styleCircle4: {},
    styleLabel1: {},
    styleLabel2: {},
    styleLabel3: {},
    styleLabel4: {}
  } : userData.role === process.env.REACT_APP_CLIENT ? {
    styleCircle1: {},
    styleLabel1: {}
  } : null)
  const [profilePicture, setProfilePicture] = useState('/images/blank-profile.png');
  const [nik, setNik] = useState(null);
  const [npwp, setNpwp] = useState(null);
  const [thumbnail, setThumbnail] = useState([]);
  const [fileProyek, setFileProyek] = useState([]);

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  return (
    <div>
      <Navbar open={open} setOpen={setOpen}/>
      <NavbarIndikatorLengkapiProfil style={style}/>
      {!media && (
        <Sidebar open={open} />
      )}
      {userData.role === process.env.REACT_APP_FREELANCER &&
        <div className="lengkapiProfilFreelance-wrap">
          <Minat
            style={style}
            setStyle={setStyle}
            showPage={showPage}
            setShowPage={setShowPage}
            dataToSend={dataToSend.current}
          />
          <InformasiProfilIdentitas
            style={style}
            setStyle={setStyle}
            showPage={showPage}
            setShowPage={setShowPage}
            dataToSend={dataToSend.current}
            profilePicture={profilePicture}
            setProfilePicture={setProfilePicture}
            nik={nik}
            setNik={setNik}
            npwp={npwp}
            setNpwp={setNpwp}
          />
          <PengalamanPendidikan
            style={style}
            setStyle={setStyle}
            showPage={showPage}
            setShowPage={setShowPage}
            dataToSend={dataToSend.current}
          />
          <Portofolio
            style={style}
            setStyle={setStyle}
            showPage={showPage}
            setShowPage={setShowPage}
            dataToSend={dataToSend.current}
            profilePicture={profilePicture}
            nik={nik}
            npwp={npwp}
            thumbnail={thumbnail}
            setThumbnail={setThumbnail}
            fileProyek={fileProyek}
            setFileProyek={setFileProyek}
          />
        </div>
      }
      {userData.role === process.env.REACT_APP_CLIENT &&
        <div className="lengkapiProfilClient-wrap">
          <InformasiProfilIdentitasPerusahaan
            style={style}
            setStyle={setStyle}
            showPage={showPage}
            setShowPage={setShowPage}
            dataToSend={dataToSend.current}
            profilePicture={profilePicture}
            setProfilePicture={setProfilePicture}
            nik={nik}
            setNik={setNik}
          />
        </div>
      }
      <Footer />
    </div>
  )
}

export default LengkapiProfilPage;