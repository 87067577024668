import React, { Fragment, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactSVG } from "react-svg";

import { ReactComponent as Clock } from '../../assets/svg/Clock.svg';
import { ReactComponent as DeadLine } from '../../assets/svg/Deadline.svg';
import { ReactComponent as Pelamar } from '../../assets/svg/Pelamar.svg';
import { ReactComponent as Price } from '../../assets/svg/Price.svg';
import { ReactComponent as Seleksi } from '../../assets/svg/Seleksi.svg';

import { dateDifference, deadlineDifference } from "../../config/dateDifference";

import "./index.css";

const DetailJobsTop = ({ data }) => {
	const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

	const media1200 = useMediaQuery({
    query: '(min-width: 1200px)'
  })

	const size = media ? 75 : 65;
	const deadline = deadlineDifference(data.job.detilJobBudget.deadline);
	const date = dateDifference(data.job.waktu, media);

	const [containerWidth, setContainerWidth] = useState(0);

	useEffect(() => {
    const handleResize = () => {
      setContainerWidth(media ? media1200 ? 1005 : document.body.clientWidth - 270 : document.body.clientWidth - 110);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [media, media1200]);

	return (
		<table className="detailJobs-top-container">
			<tbody className="detailJobs-top-wrap">
				<tr>
					<td style={{ height: "100%", paddingRight: "10px" }}>
						<ReactSVG
							src={`/svg/${data.job.detilJobBudget.kategori._id}/${data.job.detilJobBudget.subKategori._id}.svg`}
							beforeInjection={(svg) => {
								svg.setAttribute('style', `width: ${size}px; height: ${size}px`);
								svg.children[0].setAttribute('fill', '#AB1F2A');
							}}
						/>
					</td>
					<td>
							<div className="detailJobs-top-top-detail-item">
								{media ? (
									<p style={{width: `${containerWidth}px`}} className="detailJobs-top-top-detail-title">{data.job.detilJobBudget.judulPekerjaan}</p>
								) : (
									<p className="detailJobs-top-top-detail-kategori">{data.job.detilJobBudget.kategori.label}</p>
								)}
								<div 
									className="detailJobs-top-top-detail-status" 
									style={data.job.status === "Proses" ? 
										{ backgroundColor: "#ADD8E6", color: "#00008B" } 
										: data.job.status === "Dibuka" ? 
											{ backgroundColor: "#DCFFDB", color: "#04C700" } 
											: data.job.status === "Ditolak" ? 
												{ backgroundColor: "#EBC9CC", color: "#AD1F2A" }
												: { backgroundColor: "#E0E0E0", color: "#666666" }}
								>
									{data.job.status}
								</div>
							</div>
						{media ? (
							<Fragment>
								<p className="detailJobs-top-top-detail-kategori">{data.job.detilJobBudget.kategori.label}</p>
								<div className="detailJobs-top-bottom-detail-item">
									<Clock className="detailJobs-top-bottom-detail-svg" />
									Dibuat {date}
								</div>
							</Fragment>
						) : (
							<p style={{ width: `${containerWidth}px`}} className="detailJobs-top-top-detail-title">{data.job.detilJobBudget.judulPekerjaan}</p>
								)}
					</td>
				</tr>
				{media ? null : (
					<Fragment>
						<tr>
							<td className="detailJobs-top-middle-detail">Diposting</td>
							<td className="detailJobs-top-middle-detail">Fitur Informasi</td>
						</tr>
						<tr>
							<td>
								<div className="detailJobs-top-bottom-detail-item">
									<Clock className="detailJobs-top-bottom-detail-svg" />
									{date}
								</div>
							</td>
							<td>
								<div className="detailJobs-top-bottom-detail">
									<div className="detailJobs-top-bottom-detail-item">
										<Price className="detailJobs-top-bottom-detail-svg" />
										IDR {data.job.detilJobBudget.budget}
									</div>
									<div className="detailJobs-top-bottom-detail-item">
										<DeadLine className="detailJobs-top-bottom-detail-svg" />
										{deadline} hr lagi
									</div>
									<div className="detailJobs-top-bottom-detail-item">
										<Pelamar className="detailJobs-top-bottom-detail-svg" />
										{data.pelamar.length} Pelamar
									</div>
									<div className="detailJobs-top-bottom-detail-item">
										<Seleksi className="detailJobs-top-bottom-detail-svg" />
										{data.job.seleksi}
									</div>
								</div>
							</td>
						</tr>
					</Fragment>
				)}
			</tbody>
		</table>
	)
}

export default DetailJobsTop;