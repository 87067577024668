import React, { useEffect, useRef, useState } from "react";
import {isMobile} from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

import { ReactComponent as Upload } from '../../../../assets/svg/Upload.svg';

import CustomButton2 from "../../../../components/CustomButton2";
import CustomInput from "../../../../components/CustomInput";
import CustomInputImage from "../../../../components/CustomInputImage";
import CustomInputSelect from "../../../../components/CustomInputSelect";
import { errorAlert } from "../../../../components/Alert";

import { styleCircle, styleLabel } from "../../../../config/style";
import { getProfile } from "../../../../config/getProfile";
import axios from "../../../../config/api";
import { HEADER_NO_COOKIE_FORM_DATA } from "../../../../config/headers";
import { updateProfile } from "../../../../config/authActions";
import { defaultInformasiIdentitas, defaultInformasiPerusahaan, defaultInformasiProfil } from "../../../../config/defaultData";
import { changeImage } from "../../../../config/changeImage";
import { handleCountCharacter, handleDateChange, handleNikNpwpChange } from "../../../../config/handleChange";

import "./index.css";

const InformasiProfilIdentitasPerusahaan = ({ 
  style, 
  setStyle, 
  showPage, 
  dataToSend, 
  profilePicture, 
  setProfilePicture,
  nik, 
  setNik,
}) => {
  const style1 = isMobile ? { width: "100vw" } : { width: "calc(100vw - 17px)" };
  const style2 = showPage.page1;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const combinedStyle = {
    ...style1,
    ...style2,
  };

  const fileInputRef = useRef(null);
  const nikInputRef = useRef(null);
  const dataToSendRef = useRef({
    informasiProfil: defaultInformasiProfil,
    informasiIdentitas: defaultInformasiIdentitas,
    informasiPerusahaan: defaultInformasiPerusahaan
  })

  const [loading, setLoading] = useState(false);
  const [tentangPerusahaan, setTentangPerusahaan] = useState("");
  const [nikUI, setNikUI] = useState("");
  const [industri, setIndustri] = useState([]);

  const handleChangeImageClick = (ref) => {
    ref.click();
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    const profilePictureBlob = await fetch(profilePicture).then(response => response.blob());
    const nikBlob = await fetch(nik).then(response => response.blob());

    formData.append('fotoProfil', profilePictureBlob);
    formData.append('nik', nikBlob);
    formData.append("informasiProfil", JSON.stringify(dataToSend.informasiProfil));
    formData.append("informasiIdentitas", JSON.stringify(dataToSend.informasiIdentitas));
    formData.append("informasiPerusahaan", JSON.stringify(dataToSend.informasiPerusahaan));

    try{
      const response = await axios.put(`/account/client`, formData, HEADER_NO_COOKIE_FORM_DATA);
      dispatch(updateProfile(response.data));
      navigate("/lihat-jobs")
    }catch (error){
      errorAlert(error.response.data.pesan);
    }finally{
      setLoading(false);
    }
  }

  useEffect(() => {
    const getData = async() => {
      await getProfile("informasiProfil")
      .then(response => {
        if(dataToSend.informasiProfil) {
          dataToSendRef.current.informasiProfil.nama.value = response.data.nama;
          dataToSendRef.current.informasiProfil.username.value = response.data.username;
          dataToSendRef.current.informasiProfil.email.value = response.data.email;
          dataToSendRef.current.informasiProfil.noTelepon.value = response.data.noTelepon;

          dataToSend.informasiProfil = response.data;
          dataToSend.informasiProfil = {
            ...dataToSend.informasiProfil,
            tanggalLahir: "",
            kota: "",
            jenisKelamin: "Pria"
          }
          
          const { npwp, ...data } = dataToSend.informasiIdentitas;
          dataToSend.informasiIdentitas = data;
        };
      }).catch(error => {
        console.log(error);
      })

      await getProfile("informasiPerusahaan")
      .then(response => {
        if(dataToSend.informasiPerusahaan) {
          dataToSendRef.current.informasiPerusahaan.namaPerusahaan.value = response.data.namaPerusahaan;
          
          dataToSend.informasiPerusahaan = response.data;
        };
      }).catch(error => {
        console.log(error);
      })

      await axios.get("/industry")
      .then(response => {
        dataToSend.informasiPerusahaan.industri = response.data.industri[0]._id;
        setIndustri(response.data.industri);
      }).catch(error => {
        console.log(error);
      })
    }

    getData();
    setStyle({
      ...style,
      styleCircle1: styleCircle,
      styleLabel1: styleLabel
    })
  },[])

  return (
    <div 
      name="informasiProfilIdentitasPerusahaan" 
      className="lengkapiProfil-container informasiProfilIdentitas"
      style={combinedStyle}
    >
      <div className="lengkapiProfil-wrap">
        <form action="#" className="informasiProfilIdentitas-item">
          <div className="lengkapiProfil-title">
            <label>Informasi Profil</label>
          </div>
          <div className="informasiProfilIdentitas-item-foto" >
            <div className="informasiProfilIdentitas-item-foto-label">
              <label className="informasiProfilIdentitas-item-foto-title">Foto Profil</label>
              <label className="informasiProfilIdentitas-item-foto-label-warning warning-text">*Maks. 5mb</label>
            </div>
            <div className="informasiProfilIdentitas-item-foto-img">
              <img
                src={profilePicture}
                alt="Foto Profil"
                onClick={() => handleChangeImageClick(fileInputRef.current)}
              />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={(e) => changeImage(e, setProfilePicture, 5, true)}
                required={true}
              />
            </div>
          </div>
          <CustomInput
            label={"Nama Lengkap"}
            warning={"*"}
            type={"text"}
            placeholder={"Masukkan nama lengkap"}
            onChange={(e) => dataToSend.informasiProfil.nama = e.target.value}
            required={true}
            readOnly={true}
            disabled={true}
            innerRef={(ref) => dataToSendRef.current.informasiProfil.nama = ref}
          />
          <CustomInput
            label={"Username"}
            warning={"*"}
            type={"text"}
            placeholder={"Masukkan username"}
            onChange={(e) => dataToSend.informasiProfil.username = e.target.value}
            required={true}
            readOnly={true}
            disabled={true}
            innerRef={(ref) => dataToSendRef.current.informasiProfil.username = ref}
          />
          <CustomInput
            label={"Email"}
            warning={"*"}
            type={"email"}
            placeholder={"Masukkan Email"}
            onChange={(e) => dataToSend.informasiProfil.email = e.target.value}
            required={true}
            readOnly={true}
            disabled={true}
            innerRef={(ref) => dataToSendRef.current.informasiProfil.email = ref}
          />
          <CustomInput
            label={"Nomor Ponsel"}
            warning={"*"}
            ponsel={true}
            labelPonsel={"+62"}
            type={"number"}
            placeholder={"Masukkan nomor ponsel"}
            onChange={(e) => dataToSend.informasiProfil.noTelepon = e.target.value}
            required={true}
            readOnly={true}
            disabled={true}
            innerRef={(ref) => dataToSendRef.current.informasiProfil.noTelepon = ref}
          />
          <CustomInput
            label={"Tanggal Lahir"}
            warning={"*"}
            type={"date"}
            onChange={(e) => handleDateChange(e, dataToSend)}
            required={true}
            max={new Date().toISOString().slice(0, 10)}
          />
          <CustomInput
            label={"Kota Tempat Tinggal"}
            warning={"*"}
            type={"text"}
            placeholder={"Contoh: Jakarta Selatan"}
            onChange={(e) => dataToSend.informasiProfil.kota = e.target.value}
            required={true}
          />
          <CustomInput
            label={"Jenis Kelamin"}
            warning={"*"}
            type={"radio"}
            name={"jenisKelamin"}
            value={["Pria", "Wanita"]}
            defaultChecked={dataToSend.informasiProfil.jenisKelamin}
            onChange={(e) => dataToSend.informasiProfil.jenisKelamin = e.target.value}
            required={true}
          />
          <div className="lengkapiProfil-title">
            <label>Informasi Perusahaan</label>
          </div>
          <CustomInput
            label={"Nama Perusahaan"}
            warning={"*"}
            type={"text"}
            placeholder={"Masukkan nama perusahaan"}
            onChange={(e) => dataToSend.informasiPerusahaan.namaPerusahaan = e.target.value}
            required={true}
            readOnly={true}
            disabled={true}
            innerRef={(ref) => dataToSendRef.current.informasiPerusahaan.namaPerusahaan = ref}
          />
          <CustomInput
            input={"textarea"}
            label={"Tentang Perusahaan"}
            warning={"*"}
            placeholder={"Tentang Perusahaan ..."}
            rows={10}
            description={"min. 100 Karakter"}
            currentText={tentangPerusahaan.length}
            maxText={2000}
            onChange={(e) => handleCountCharacter(e, setTentangPerusahaan, dataToSend, "tentangPerusahaan")}
            required={true}
            minLength={100}
            maxLength={2000}
          />
          <CustomInputSelect
            label={"Industri"}
            warning={"*"}
            firstOnChange={(e) => (dataToSend.informasiPerusahaan.industri = e.target.value)}
            firstData={industri}
          />
          <div className="lengkapiProfil-title">
            <label>Informasi Identitas</label>
          </div>
          <CustomInput
            label={"Nomor NIK"}
            warning={"*"}
            type={"text"}
            placeholder={"Masukkan 16 digit nomor kartu identitas anda"}
            description={"Dibutuhkan untuk melakukan penarikan/pencairan dana"}
            onChange={(e) => handleNikNpwpChange(e, "nik", setNikUI, dataToSend)}
            required={true}
            value={nikUI}
            maxLength={16}
          />
          <CustomInputImage
            onClick={() => handleChangeImageClick(nikInputRef.current)}
            src={nik}
            logo={<Upload className="lengkapiProfil-tambah-svg"/>}
            label={"Upload berkas foto KTP"}
            innerRef={nikInputRef}
            onChange={(e) => changeImage(e, setNik, 5, true)}
            required={true}
            description={"Format (JPG, JPEG, PNG) Maks. 5mb"}
          />
          <div className="lengkapiProfil-bottom">
          <CustomButton2
            color={true}
            label={loading ?
              <BeatLoader
                color={"#E4E4E7"}
                size={10}
              />
              :
              "Selanjutnya"
            }
            onClick={handleSubmit}
          />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default InformasiProfilIdentitasPerusahaan;