export const styleCircleBack = {
  "backgroundPosition": "0 0",
  "color": "#BCBCC2",
  "borderColor": "#E4E4E7"
}

export const styleLabelBack = {
  "color" : "#BCBCC2",
  "fontWeight" : "500"
}

export const styleCircle = {
  "backgroundPosition": "-100% 0",
  "color": "#fff",
  "borderColor": "#AB1F2A"
}

export const styleLabel = {
  "color": "#AB1F2A",
  "fontWeight" : "500"
}