import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useMediaQuery } from 'react-responsive';

import "./index.css";

const KategoriOption = ({ items, index }) => {
  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const navigate = useNavigate();

  const size = media ? 80 : 50

  const handleMouseEnter = (event) => {
    event.target.children[0].setAttribute('fill', '#AB1F2A');
    event.target.children[0].setAttribute('style', 'transition: fill 0.5s ease');
  };

  const handleMouseLeave = (event) => {
    event.target.children[0].setAttribute('fill', '#E4E4E7');
  };

  return (
    <>
      {items[index].subKategori.map((item, indexSub) => (
        <div className="kategori-item-detail" key={indexSub}>
          <ReactSVG 
            src={`/svg/${items[index]._id}/${item._id}.svg`}
            className="kategori-item-svg"
            beforeInjection={(svg) => {
              svg.setAttribute('style', `width: ${size}px; height: ${size}px`)
            }}
            afterInjection={(svg) => {
              svg.addEventListener('mouseenter', handleMouseEnter);
              svg.addEventListener('mouseleave', handleMouseLeave);
            }}
            onClick={() => navigate('/lihat-jobs', { state: { from: { index: index, indexSub: indexSub}}})}
          />
          {item.label}
        </div>
      ))}
    </>
  );
};

export default KategoriOption;