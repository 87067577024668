import React from "react";
import { Link } from 'react-router-dom';

import "./index.css";
import { getCookie } from "../../../../../config/cookies";

const VerifikasiGagal = () => {
  const cookie = getCookie("connect");
  return (
    <div className="verifikasi-container">
      <div className="verifikasi-wrap">
          <div className="verifikasi-wrap-image">
            <img className="verifikasi-wrap-image-2" src="/images/emailVerifikasiGagal.png" />
          </div>
        <h1>Oopps! Verifikasi Gagal!</h1>
        <h5>Maaf, untuk token verifikasi tidak valid. Silahkan untuk melakukan verifikasi email kembali. Terima kasih!</h5>
        {cookie ?
          (
            <Link to={"/verifikasi"} className="verifikasi-wrap-button">
              Verifikasi Ulang
            </Link>
          ) :
          (
            <Link to={"/login"} className="verifikasi-wrap-button">
              Login
            </Link>
          )}
      </div>
    </div>
  );
}

export default VerifikasiGagal;