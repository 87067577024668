export const loginSuccess = (userData) => ({
  type: 'LOGIN_SUCCESS',
  payload: { userData },
});

export const logout = () => ({
  type: 'LOGOUT',
  payload: {}
});

export const updateProfile = (userData) => ({
  type: 'UPDATE_PROFILE',
  payload: { userData },
});

export const updateVerifikasi = (userData) => ({
  type: 'UPDATE_VERIFICATION',
  payload: { userData },
});
