import React, { Fragment, useEffect, useState } from "react";

import HorizontalScroll from "../../../../components/HorizontalScroll";
import KategoriOption from "../../../../components/KategoriOption";

import axios from "../../../../config/api";

import "./index.css";

const Kategori = () => {
  const [data, setData] = useState(null);
  const [kategoriIndex, setKategoriIndex] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get("/category");
        setData(response.data.kategori);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, []);

  return (
    <div className="kategori-container">
      <div className="kategori-wrap">
        <h3 className="lead">KATEGORI</h3>
        {data ? (
          <Fragment>
            <HorizontalScroll
              items={data}
              onItemClick={(item) => setKategoriIndex(item)}
            />
            <div className="kategori-item">
              <KategoriOption items={data} index={kategoriIndex} />
            </div>
          </Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default Kategori;
