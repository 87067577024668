export const defaultInformasiProfil = {
  nama: "",
  username: "",
  email: "",
  noTelepon: "",
  tanggalLahir: "",
  kota: "",
  jenisKelamin: "Pria",
  keahlian: "",
  deskripsiDiri: "",
  skills: "",
}

export const defaultInformasiPerusahaan = {
  namaPerusahaan: "",
  tentangPerusahaan: "",
  industri: "",
}

export const defaultInformasiIdentitas = {
  nik: {
    value: ""
  },
  npwp: {
    value: ""
  }
}

export const defaultDataPengalaman = {
  jabatan: "",
  perusahaan: "",
  lokasi: "",
  tipeKerja: "",
  deskripsi: "",
  tanggalMulai: {
    bulan: "Januari",
    tahun: "1900"
  },
  tanggalBerakhir: {
    bulan: "Januari",
    tahun: "1900"
  },
  pekerjaanSekarang: false
}

export const defaultDataPendidikan = {
  perguruanTinggi: "",
  jurusan: "",
  lokasi: "",
  gelar: "",
  deskripsi: "",
  tanggalMulai: {
    bulan: "Januari",
    tahun: "1900"
  },
  tanggalLulus: {
    bulan: "Januari",
    tahun: "1900"
  },
  pendidikanSekarang: false
}

export const defaultPortofolio = {
  judulProyek: "",
  deskripsi: "",
  kategori: "",
  subKategori: "",
  deadline: ""
}

export const defaultDiPosting = [{
  _id: 0,
  label: "Diposting"
}, {
  _id: 1,
  label: "24 Jam terakhir"
}, {
  _id: 3,
  label: "3 Hari lalu"
}, {
  _id: 7,
  label: "Minggu lalu"
}]

export const defaultBudget = [{
  _id: "",
  label: "Budget"
}, {
  _id: "1",
  label: "Terendah"
}, {
  _id: "-1",
  label: "Tertinggi"
}]

export const defaultDeskripsiJob = {
  jobOffer: "diTawarkan",
  tipeJob: "paruhWaktu"
}

export const defaultDetilJobBudget = {
  judulPekerjaan: "",
  kategori: "",
  kategori_label: "",
  subKategori: "",
  subKategori_label: "",
  deskripsiPekerjaan: "",
  industri: "",
  industri_label: "",
  deadline: "",
  budget: "",
  freelanceNegosiasi: "false"
}

export const defaultPenawaran = {
  penawaran: "",
  alasan: ""
}

export const defaultFotoProfil = '/fotoProfil/blank-profile.png'