import React, { Fragment, useState } from "react";

import { useMediaQuery } from 'react-responsive';

import { ReactComponent as Fakta} from "../../assets/svg/Fakta.svg";
import { ReactComponent as Facebook} from "../../assets/svg/Facebook.svg"
import { ReactComponent as Twitter} from "../../assets/svg/Twitter.svg"
import { ReactComponent as Linkedin} from "../../assets/svg/Linkedin.svg"
import { ReactComponent as Instagram} from "../../assets/svg/Instagram.svg"
import { ReactComponent as Email} from "../../assets/svg/Email.svg"
import { ReactComponent as HP} from "../../assets/svg/HP.svg"
import { ReactComponent as SSL} from "../../assets/svg/SSL.svg"

import "./index.css"

const Footer = () => {
  const [isVisibleTentang, setIsVisibleTentang] = useState(false);
  const [isVisibleCara, setIsVisibleCara] = useState(false);

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  return (
    <div className="footer-container">
      {media ?
        null :
        <div className="footer-wrap-top">
          <div onClick={() => setIsVisibleTentang(!isVisibleTentang)} className="footer-wrap-dropdown">
            <p className="footer-wrap-bottom-detail-slice-title">Tentang</p>
            <p>^</p>
          </div>
          <div className={`footer-wrap-dropdown-detail ${isVisibleTentang ? 'show' : ''}`}>
            <p>Tentang Kami</p>
            <p>Peraturan</p>
            <p>Kebijakan Privasi</p>
            <p>Syarat & Ketentuan</p>
            <p>Karier</p>
          </div>
          <div onClick={() => setIsVisibleCara(!isVisibleCara)} className="footer-wrap-dropdown footer-wrap-dropdown-cara">
            <p className="footer-wrap-bottom-detail-slice-title">Cara Penggunaan</p>
            <p>^</p>
          </div>
          <div className={`footer-wrap-dropdown-detail ${isVisibleCara ? 'show' : ''}`}>
            <p>Cara Kerja</p>
            <p>Cara Bayar</p>
            <p>Cara Persetujuan</p>
            <p>Partner</p>
            <p>Kerjasama</p>
          </div>
        </div>
      }
      <div className="footer-wrap-bottom">
        <div className="footer-wrap-bottom-detail">
          <div className="footer-wrap-bottom-detail-slice">
            <div className="footer-wrap-bottom-detail-slice-logo">
              <Fakta className="footer-wrap-bottom-detail-slice-logo-svg"/>
            </div>
            <p>Jl. Duren Tiga Selatan No. 4 RT.5/RW.2, Duren Tiga, Kec. Pancoran, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12510</p>
            <div className="footer-wrap-bottom-detail-slice-share">
              <Facebook className="footer-wrap-bottom-detail-slice-share-svg"/>
              <Twitter className="footer-wrap-bottom-detail-slice-share-svg"/>
              <Linkedin className="footer-wrap-bottom-detail-slice-share-svg"/>
              <Instagram className="footer-wrap-bottom-detail-slice-share-svg"/>
            </div>
          </div>
          {media ?
            <Fragment>
              <div className="footer-wrap-bottom-detail-slice footer-wrap-bottom-detail-slice-fragment">
                <p className="footer-wrap-bottom-detail-slice-title">Tentang</p>
                <p>Tentang Kami</p>
                <p>Peraturan</p>
                <p>Kebijakan Privasi</p>
                <p>Syarat & Ketentuan</p>
                <p>Karier</p>
              </div>
              <div className="footer-wrap-bottom-detail-slice footer-wrap-bottom-detail-slice-fragment">
                <p className="footer-wrap-bottom-detail-slice-title">Cara Penggunaan</p>
                <p>Cara Kerja</p>
                <p>Cara Bayar</p>
                <p>Cara Persetujuan</p>
                <p>Partner</p>
                <p>Kerjasama</p>
              </div>
            </Fragment>
            :
            null
          }
          <div className="footer-wrap-bottom-detail-slice">
            <p className="footer-wrap-bottom-detail-slice-title">Hubungi Kami</p>
            <div className="footer-wrap-bottom-detail-slice-item">
              <Email className="footer-wrap-bottom-detail-slice-item-svg"/>
              fakta.kolaborasi@gmail.com
            </div>
            <div className="footer-wrap-bottom-detail-slice-item">
              <HP className="footer-wrap-bottom-detail-slice-item-svg"/>
              +62 821 8289 9023
            </div>
            <p className="footer-wrap-bottom-detail-slice-title">Keamanan</p>
            <div className="footer-wrap-bottom-detail-slice-item footer-wrap-bottom-detail-slice-item-secure">
              <div className="footer-wrap-bottom-detail-slice-item-secure-detail">
                <SSL className="footer-wrap-bottom-detail-slice-item-svg"/>
                SSL Secured
              </div>
              <div className="footer-wrap-bottom-detail-slice-item-secure-detail">
                <SSL className="footer-wrap-bottom-detail-slice-item-svg"/>
                SSL Secured
              </div>
            </div>
          </div>
        </div>
        <div className="footer-wrap-copyright">
          Copyright 2023. Fakta & Kolaborasi.com | All Rights Reserved.
        </div>
      </div>
    </div>
  )
}

export default Footer;