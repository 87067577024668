import React, { useState, useRef, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

import { ReactComponent as Warning } from '../../../../assets/svg/Warning.svg';
import { ReactComponent as Plus } from '../../../../assets/svg/Plus.svg';

import { errorAlert } from "../../../../components/Alert";
import CustomButton2 from "../../../../components/CustomButton2";
import CustomButton3 from "../../../../components/CustomButton3";
import PortofolioCard from "../../../../components/PortofolioCard";
import PortofolioModal from "../../../../components/PortofolioModal";

import axios from "../../../../config/api";
import { styleCircleBack, styleLabelBack } from "../../../../config/style";
import { HEADER_NO_COOKIE_FORM_DATA } from "../../../../config/headers";
import { defaultPortofolio } from "../../../../config/defaultData";
import { convertDate } from "../../../../config/convertDate";
import { updateProfile } from "../../../../config/authActions";

import "./index.css";

const Portofolio = ({ 
  style, 
  setStyle, 
  showPage, 
  setShowPage, 
  dataToSend,
  profilePicture, 
  nik, 
  npwp, 
  thumbnail,
  setThumbnail,
  fileProyek,
  setFileProyek
}) => {
  const style1 = isMobile ? { width: "100vw" } : { width: "calc(100vw - 17px)" };
  const style2 = showPage.page1;

  const combinedStyle = {
    ...style1,
    ...style2,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  var dataTmpRef = JSON.parse(JSON.stringify(defaultPortofolio));
  var dataTmp = JSON.parse(JSON.stringify(defaultPortofolio));

  dataTmpRef.judulProyek = {};
  dataTmpRef.deskripsi = {};
  dataTmpRef.kategori = {};
  dataTmpRef.subKategori = {};
  dataTmpRef.deadline = {};

  const dataToSendRef = useRef(dataTmpRef);
  const dataToSendTmp = useRef(dataTmp);

  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dataIndex, setDataIndex] = useState(0);
  const [subKategoriIndex, setSubKategoriIndex] = useState(0);
  const [isAfterEdit, setIsAfterEdit] = useState(false);
  const [minimum, setMinimum] = useState(<></>);
  const [kategori, setKategori] = useState([]);

  const openModal = (edit, index) => {
    setModalOpen(true);
    setIsEdit(edit);
    setDataIndex(index ? index : 0);
  }

  const closeModal = () => {
    setModalOpen(false);
    setIsEdit(false);
  }

  const deletePortofolio = (index) => {
    const newDataThumbnail = [...thumbnail];
    newDataThumbnail.splice(index, 1);
    setThumbnail(newDataThumbnail);

    const newDatafileProyek = [...fileProyek];
    newDatafileProyek.splice(index, 1);
    setFileProyek(newDatafileProyek);

    dataToSend.portofolio.splice(index, 1);
  }

  const handleSubmit = async () => {
    if (dataToSend.portofolio.length >= 2) {
      setLoading(true);
      const formData = new FormData();

      const profilePictureBlob = await fetch(profilePicture).then(response => response.blob());
      const nikBlob = await fetch(nik).then(response => response.blob());
      const npwpBlob = await fetch(npwp).then(response => response.blob());
      for (let i = 0; i < dataToSend.portofolio.length; i++) {
        const thumbnailBlob = await fetch(thumbnail[i]).then(response => response.blob());
        const fileProyekBlob = await fetch(fileProyek[i]).then(response => response.blob());

        formData.append('thumbnail', thumbnailBlob);
        formData.append('fileProyek', fileProyekBlob);
      }

      formData.append('fotoProfil', profilePictureBlob);
      formData.append('nik', nikBlob);
      formData.append('npwp', npwpBlob);
      formData.append("minat", JSON.stringify(dataToSend.minat));
      formData.append("informasiProfil", JSON.stringify(dataToSend.informasiProfil));
      formData.append("informasiIdentitas", JSON.stringify(dataToSend.informasiIdentitas));
      formData.append("pengalaman", JSON.stringify(dataToSend.pengalaman));
      formData.append("pendidikan", JSON.stringify(dataToSend.pendidikan));
      formData.append("portofolio", JSON.stringify(dataToSend.portofolio));

      try {
        const response = await axios.put(`/account/freelancer`, formData, HEADER_NO_COOKIE_FORM_DATA);
        dispatch(updateProfile(response.data));
        navigate("/lihat-jobs")
      } catch (error) {
        errorAlert(error.response.data.pesan);
      } finally {
        setLoading(false);
      }
    }else {
      setMinimum(<label className="lengkapiProfil-wrap-error warning-text">*Minimal 2 Portofolio!</label>)
    }
  }

  const handleBack = (e) => {
    e.preventDefault();
    scroll.scrollToTop();
    setShowPage({
      ...showPage,
      page1: { "transform": "translateX(-200%)" },
      page2: { "transform": "translateX(-200%)" },
      page3: { "transform": "translateX(-200%)" },
      page4: { "transform": "translateX(-200%)" }
    })
    setStyle({
      ...style,
      styleCircle4: styleCircleBack,
      styleLabel4: styleLabelBack
    })
  }

  useEffect(() => {
    if (isEdit && dataToSendRef.current) {
      const newDataToSendPortofolio = JSON.parse(JSON.stringify(dataToSend.portofolio[dataIndex]))
      const index = kategori.findIndex(item => item._id === newDataToSendPortofolio.kategori);
      setSubKategoriIndex(index);
      setIsAfterEdit(true);
    }
  },[isEdit])

  useEffect(() => {
    if (isEdit && dataToSendRef.current && isAfterEdit) {
      const newDataToSendPortofolio = JSON.parse(JSON.stringify(dataToSend.portofolio[dataIndex]))

      const deadline = convertDate(newDataToSendPortofolio.deadline);

      dataToSendRef.current.judulProyek.value = newDataToSendPortofolio.judulProyek;
      dataToSendRef.current.deskripsi.value = newDataToSendPortofolio.deskripsi;
      dataToSendRef.current.kategori.value = newDataToSendPortofolio.kategori;
      dataToSendRef.current.subKategori.value = newDataToSendPortofolio.subKategori;
      dataToSendRef.current.deadline.value = deadline;

      dataToSendTmp.current = newDataToSendPortofolio;
    }
  },[isAfterEdit])

  useEffect(() => {
    const getData = async() => {
      try{
        const response = await axios.get("/category");
        setKategori(response.data.kategori);
      } catch(error) {
        console.log(error);
      }
    }
    
    getData();
  },[])

  return (
    <div
      name="portofolio"
      className="lengkapiProfil-container"
      style={combinedStyle}
    >
      <div className="lengkapiProfil-wrap">
        <div className="portofolio-warning">
          <Warning className="portofolio-warning-svg"/>
          <label>Perhatian!</label>
          <p>Portofolio yang tampil di profil adalah portofolio yang sudah melewati tahap verifikasi oleh Admin.</p>
        </div>
        <div className="lengkapiProfil-title">
          <label>Portofolio</label>
          <p className="warning-text">*Unggah minimal 2 portofolio!</p>
        </div>
        <div className="portofolio-portofolioCard-wrap">
          {dataToSend.portofolio.map((item, index) => (
            <PortofolioCard
              key={index}
              gambar={thumbnail[index]}
              judulProyek={item.judulProyek}
              editOnClick={() => openModal(true, index)}
              deleteOnClick={() => deletePortofolio(index)}
            />
          ))}
        </div>
        <CustomButton3
          logo={<Plus className="lengkapiProfil-tambah-svg" />}
          onClick={() => openModal(false)}
          label={"Tambah Portofolio"}
        />
        <div className="lengkapiProfil-bottom noForm">
          <CustomButton2
            color={true}
            label={loading ?
              <BeatLoader
                color={"#E4E4E7"}
                size={10}
              />
              :
              "Selanjutnya"
            }
            onClick={handleSubmit}
          />
          <CustomButton2
            onClick={handleBack}
            label={"Kembali"}
          />
        </div>
        {minimum}
        <PortofolioModal
          isOpen={isModalOpen}
          isEdit={isEdit}
          closeModal={closeModal}
          dataIndex={dataIndex}
          isIsiData={true}
          dataToSend={dataToSend}
          dataToSendRef={dataToSendRef.current}
          dataToSendTmp={dataToSendTmp}
          thumbnail={thumbnail}
          setThumbnail={setThumbnail}
          fileProyek={fileProyek}
          setFileProyek={setFileProyek}
          subKategoriIndex={subKategoriIndex}
          setSubKategoriIndex={setSubKategoriIndex}
          setIsAfterEdit={setIsAfterEdit}
        />
      </div>
      <ToastContainer />
    </div>
  )
}

export default Portofolio;