import React, { useState, useRef, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as DeadLine } from '../../../../../assets/svg/Deadline.svg';
import { ReactComponent as Pelamar } from '../../../../../assets/svg/Pelamar.svg';
import { ReactComponent as Price } from '../../../../../assets/svg/Price.svg';
import { ReactComponent as Seleksi } from '../../../../../assets/svg/Seleksi.svg';

import AuthorCard from "../../../../../components/AuthorCard";
import CustomButton2 from "../../../../../components/CustomButton2";
import DetailJobsTop from "../../../../../components/DetailJobsTop";
import Penawaran from "../../../../../components/Penawaran";
import { errorAlert } from "../../../../../components/Alert";

import axios from "../../../../../config/api";
import { deadlineDifference } from "../../../../../config/dateDifference";
import { defaultPenawaran } from "../../../../../config/defaultData";
import { HEADER_NO_COOKIE } from "../../../../../config/headers";

import "./index.css";
import HorizontalScroll from "../../../../../components/HorizontalScroll";

const DetailJobs = () => {
  var dataPenawaran = JSON.parse(JSON.stringify({ ...defaultPenawaran, dokumenPenawaran: "" }));

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })
  const { id } = useParams();
  const { userData } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const dataToSendRef = useRef(dataPenawaran);
  const paymentStatusFetched = useRef(false);

  const [selectedItem, setSelectedItem] = useState("pelamar");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState(null);
  const [statusPayment, setStatusPayment] = useState(null);
  const [idPelamar, setIdPelamar] = useState(null);
  const [isDirekrut, setIsDirekrut] = useState(null);
  const [dataDetilPembayaran, setDataDetilPembayaran] = useState({
    nilaiPekerjaan: "",
    ppn: "",
    saldoAkhir: ""
  })
  const [pemohonNotAllowed, setPemohonNotAllowed] = useState(true);
  const [dokumenPenawaran, setDokumenPenawaran] = useState(null);

  const editStatus = async () => {
    try {
      await axios.put(`/job/${id}`, {}, HEADER_NO_COOKIE);
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    }
  }

  const editKonfirmasi = async () => {
    try {
      await axios.put(`/job/konfirmasi/${id}`, {}, HEADER_NO_COOKIE);
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    }
  }

  const payment = async (id) => {
    try {
      const fullname = data.author.informasiProfil.nama;
      const nameParts = fullname.split(' ');
      let first_name;
      let last_name;
  
      if (nameParts.length >= 3) {
        first_name = nameParts.slice(0, 2).join(' ');
        last_name = nameParts.slice(2).join(' ');
      } else {
        first_name = nameParts[0];
        last_name = nameParts.length > 1 ? nameParts[1] : '';
      }
  
      const data_send = {
        first_name: first_name,
        last_name: last_name,
        email: data.author.informasiProfil.email,
        phone: data.author.informasiProfil.noTelepon,
      };
  
      const response = await axios.post(`/payment/${id}`, data_send, HEADER_NO_COOKIE);
      const token = response.data.token;
      window.snap.pay(token);
    } catch (error) {
      errorAlert(error.response.data.error || "Terjadi kesalahan pada pembayaran.");
    }
  };

  const openModal = (edit, idPelamar) => {
    if (!edit) {
      if (userData) {
        if (data.job.status === "Dibuka") {
          if (isDirekrut) {
            if (userData.username === data.author.informasiProfil.username || userData.username === isDirekrut[0].user.informasiProfil.username) {
              if (!data.job[userData.role === process.env.REACT_APP_CLIENT ? 'konfirmasiClient' : 'konfirmasiFreelancer']) editKonfirmasi();
            }
          } else {
            if (userData.username === data.author.informasiProfil.username) editStatus();
            else if (!pemohonNotAllowed) setModalOpen(true);
          }
        }
      } else {
        navigate('/login', { state: { from: window.location.pathname } })
      }
    } else {
      if (userData.username === data.author.informasiProfil.username) {
        setModalOpen(true);
        setIsEdit(true);
        setIdPelamar(idPelamar);
      }
    }
  }

  const closeModal = () => {
    setModalOpen(false);
    setIsEdit(false);
    setIdPelamar(null);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.get(`/jobhub/${id}`)
          .then(response => {
            setData(response.data);
            if (userData) {
              const direkrut = response.data.pelamar.filter(pelamar => pelamar.status === "Diterima");
              if (direkrut.length > 0) {
                setIsDirekrut(direkrut);
                setPemohonNotAllowed(true);
              } else {
                setPemohonNotAllowed(
                  userData.role === process.env.REACT_APP_CLIENT || 
                  response.data.pelamar.some(item => item.user.informasiProfil.username === userData.username)
                );
              }
            }
          })
          .catch(error => {
            errorAlert(error.response.data.pesan);
          });
  
        if (userData?.role === process.env.REACT_APP_CLIENT && !paymentStatusFetched.current) {
          paymentStatusFetched.current = true;
          
          await axios.get(`/payment/status/${id}`)
            .then(response => {
              const status = response.data.data.status;
              setStatusPayment(status);
            })
            .catch(error => {
              errorAlert(error.response.data.pesan);
            });
  
          const script = document.createElement('script');
          const clientKey = process.env.REACT_APP_CLIENT_KEY;
          const snapScriptUrl = process.env.REACT_APP_MIDTRANS_URL;
          script.src = snapScriptUrl;
          script.setAttribute('data-client-key', clientKey);
          script.async = true;
          document.body.appendChild(script);
  
          return () => {
            document.body.removeChild(script);
          };
        }
      } catch (error) {
        console.error('Error fetching data or setting up payment:', error);
      }
    };

  
    fetchData();
  }, [id, userData?.role, userData?.username]);

  useEffect(() => {
    if (isEdit && dataToSendRef.current) {
      const pelamar = data.pelamar.filter(item => item._id === idPelamar);
      dataToSendRef.current.penawaran.value = pelamar[0].penawaran;
      dataToSendRef.current.alasan.value = pelamar[0].alasan;
      setDataDetilPembayaran(pelamar[0].detilPembayaran);
      setDokumenPenawaran(`${process.env.REACT_APP_API_URL}${pelamar[0].dokumenPenawaran}`);
    }
  }, [isEdit])

  return (
    <div className="detailJobs-container">
      <div className="detailJobs-wrap">
        <div className="detailJobs-top">
          {data ? (
            <DetailJobsTop
              data={data}
            />
          ) : null
          }
        </div>
        <div className="detailJobs-center">
          <div className="detailJobs-center-wrap">
            {media ? (
              <div className="detailJobs-center-item">
                <h3>Informasi Fitur</h3>
                <div className="detailJobs-top-detail-bottom">
                  <div className="detailJobs-top-detail-bottom-item">
                    <Price className="detailJobs-top-detail-subtitle-svg" />
                    <p>IDR {data?.job.detilJobBudget.budget}</p>
                  </div>
                  <div className="detailJobs-top-detail-bottom-item">
                    <DeadLine className="detailJobs-top-detail-subtitle-svg" />
                    <p>{data ? deadlineDifference(data.job.detilJobBudget.deadline) : null} Hari lagi</p>
                  </div>
                  <div className="detailJobs-top-detail-bottom-item">
                    <Pelamar className="detailJobs-top-detail-subtitle-svg" />
                    <p>{data?.pelamar.length} Pelamar</p>
                  </div>
                  <div className="detailJobs-top-detail-bottom-item">
                    <Seleksi className="detailJobs-top-detail-subtitle-svg" />
                    <p>{data?.job.seleksi}</p>
                  </div>
                </div>
              </div>
            )
              :
              null
            }
            <div className="detailJobs-center-item">
              <h3>Deskripsi</h3>
              <p>{data?.job.detilJobBudget.deskripsiPekerjaan}</p>
            </div>
            <div className="detailJobs-center-item">
              <h3>Industri</h3>
              <p>{data?.job.detilJobBudget.industri.label}</p>
            </div>
            <div className="detailJobs-center-item">
              <h3>Data Tambahan</h3>
              <p><a href={data ? `${process.env.REACT_APP_API_URL}/job/download${data.job.fileTambahan}` : null} download>Data Tambahan</a></p>
            </div>
            <div className="detailJobs-center-item">
              <h3>Check Status Pembayaran</h3>
              <p><a href={data ? `/check-status?transaction_id=${id}` : null}>Check</a></p>
            </div>
          </div>
        </div>
        <div className="detailJobs-bottom">
          <div className="detailJobs-bottom-wrap">
            <AuthorCard
              fotoProfil={data?.author.informasiProfil.fotoProfil}
              nama={data?.author.informasiProfil.nama}
              rating={data?.author.ratingTotal.bintang}
              review={data?.author.ratingTotal.review}
              repeat={true}
            />
            <div className="detailJobs-bottom-price">
              <label>IDR {data?.job.detilJobBudget.budget}</label>
              {userData?.role === process.env.REACT_APP_CLIENT &&
                  statusPayment === null ? (
                  <CustomButton2
                    color={true}
                    onClick={() => payment(id)}
                    label="Bayar"
                    disabled={data?.job.konfirmasiFreelancer ? data?.job.konfirmasiFreelancer : false}
                  />
                )  : (
                (data?.job.status === "Dibuka" || data?.job.status === "Selesai") && (
                  <CustomButton2
                    color={true}
                    onClick={() => openModal(false)}
                    label={
                      isDirekrut ? (
                        userData?.role === process.env.REACT_APP_FREELANCER ? (
                          data?.job.konfirmasiFreelancer ? (
                            data?.job.konfirmasiClient ? (
                              "Selesai"
                            ) : (
                              "Ajukan Selesai"
                            )
                          ) : (
                            "Ajukan Selesai"
                          )
                        ) : (
                          "Selesai"
                        )
                      ) : (
                        userData?.role === process.env.REACT_APP_CLIENT ? (
                          "Tutup"
                        ) : (
                          "Daftar Job"
                        )
                      )
                    }
                    notAllowed={
                      data?.job.status === "Dibuka" ? (
                        userData ? (
                          isDirekrut ? (
                            (userData.username === data.author.informasiProfil.username || userData.username === isDirekrut[0].user.informasiProfil.username) ?
                              data.job[userData.role === process.env.REACT_APP_CLIENT ? 'konfirmasiClient' : 'konfirmasiFreelancer'] :
                              true
                          ) : (
                            userData.username === data.author.informasiProfil.username ? 
                              false :
                              pemohonNotAllowed
                          )
                        ) : (
                          false
                        )
                      ) : (
                        true
                      )
                    }
                  />
                )
              )}
            </div>
          </div>
        </div>
        <div className="detailJobs-addons">
          <div className="detailJobs-addons-bar">
            <div
              className={`detailJobs-addons-pelamar ${selectedItem === "pelamar" ? 'clicked' : ''}`}
              onClick={() => setSelectedItem("pelamar")}
            >Pelamar ({data?.pelamar.length})
            </div>
            <div
              className={`detailJobs-addons-direkrut ${selectedItem === "direkrut" ? 'clicked' : ''}`}
              onClick={() => setSelectedItem("direkrut")}
            >Direkrut ({isDirekrut ? 1 : 0})
            </div>
          </div>
          {selectedItem === "pelamar" ?
            data?.pelamar.length > 0 ?
              <HorizontalScroll
                items={data.pelamar}
                author={true}
                card={true}
                onItemClick={(item) => openModal(true, item)}
              />
              : null
            :
            isDirekrut ? (
              <HorizontalScroll
                items={isDirekrut}
                author={true}
                card={true}
                onItemClick={(item) => openModal(true, item)}
              />
            )
              : null
          }
        </div>
        {data ?
          <Penawaran
            id={data.job._id}
            idPelamar={idPelamar}
            penyedia={data.author._id}
            isOpen={isModalOpen}
            isEdit={isEdit}
            isDirekrut={isDirekrut}
            closeModal={closeModal}
            dataToSendRef={dataToSendRef.current}
            dataDetilPembayaran={dataDetilPembayaran}
            dokumenPenawaran={dokumenPenawaran}
          />
          :
          null
        }
      </div>
      <ToastContainer />
    </div>
  )
}

export default DetailJobs;