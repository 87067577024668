import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { ToastContainer } from 'react-toastify';
import BeatLoader from "react-spinners/BeatLoader";

import { ReactComponent as Edit } from '../../../../../assets/svg/Edit.svg';
import { ReactComponent as TrashRed } from '../../../../../assets/svg/TrashRed.svg';
import { ReactComponent as DetailData } from '../../../../../assets/svg/DetailData.svg';
import { ReactComponent as Plus } from '../../../../../assets/svg/Plus.svg';
import { ReactComponent as PlusWhite } from '../../../../../assets/svg/PlusWhite.svg';

import Navigasi from "../../../../../components/Navigasi";
import Pagination from "../../../../../components/Pagination";
import KategoriModal from "../../../../../components/KategoriModal";

import { dashboardAdminLink } from "../../../../../config/dashboardLink";
import { scrollToTop } from "../../../../../config/scrollTo";
import axios from "../../../../../config/api";
import { errorAlert } from "../../../../../components/Alert";

import "./index.css";

const KelolaKategoriSubKategori = () => {
  var limit = 3;

  const media = useMediaQuery({
    query: '(min-width: 912px)'
  })

  const navigate = useNavigate();

  const [kategori, setKategori] = useState([]);
  const [kategoriId, setKategoriId] = useState(null)
  const [type, setType] = useState(null);
  const [dataEdit, setDataEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const openModal = (type, edit, dataToEdit, id) => {
    setIsModalOpen(true);
    setType(type);
    if (id) setKategoriId(id);
    if (dataToEdit !== undefined) setDataEdit(dataToEdit);
    if (edit) setIsEdit(true);;
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setType(null);
    setDataEdit(null);
    setKategoriId(null);
    if (isEdit) setIsEdit(false);
  }

  const hapusKategori = async(e, id) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.delete(`/category/${id}`);
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }
  }

  const hapusSubKategori = async(e, id, subId) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.delete(`/category/subCategory/${id}/${subId}`);
      navigate(0);
    } catch (error) {
      errorAlert(error.response.data.pesan);
    } finally {
      setLoading(false);
    }
  }

  const handlePageClick = async (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);

    try {
      const response = await axios.get(setUrl(pageNumber));
      setKategori(response.data.kategori);
      setPage(Math.ceil(response.data.jumlahDokumen / limit))
    } catch (error) {
      console.log(error);
    } finally {
      scrollToTop();
      setLoading(false);
    }
  };

  const handleNextClick = async () => {
    if (currentPage < page) {
      setLoading(true);
      setCurrentPage(currentPage + 1);
      try {
        const response = await axios.get(setUrl(currentPage + 1));
        setKategori(response.data.kategori);
        setPage(Math.ceil(response.data.jumlahDokumen / limit))
      } catch (error) {
        console.log(error);
      } finally {
        scrollToTop();
        setLoading(false);
      }
    }
  };

  const handlePreviousClick = async () => {
    if (currentPage > 1) {
      setLoading(true);
      setCurrentPage(currentPage - 1);
      try {
        const response = await axios.get(setUrl(currentPage - 1));
        setKategori(response.data.kategori);
        setPage(Math.ceil(response.data.jumlahDokumen / limit))
      } catch (error) {
        console.log(error);
      } finally {
        scrollToTop();
        setLoading(false);
      }
    }
  };

  const setUrl = (pageNumber) => {
    let url = `/category?page=${pageNumber}&limit=${limit}`;
    var newUrl = url;

    return newUrl;
  }

  useEffect(() => {
    const getData = async () => {
      await axios.get(`/category?limit=${limit}`)
        .then(response => {
          setKategori(response.data.kategori);
          setPage(Math.ceil(response.data.jumlahDokumen / limit))
        })
        .catch(error => {
          console.log(error);
        })
    }

    getData();
  }, [])

  return (
    <div className="dashboard-container">
      <div className="dashboard-wrap">
        {media ?
          <div className="dashboard-bar">
            <Navigasi
              title={"Dashboard"}
              data={dashboardAdminLink}
              location={4}
            />
          </div>
          :
          null
        }
        <div className="dashboard-detail">
          <div className="dashboard-title">
            <h3>Kelola Kategori & Sub Kategori</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <div className="dashboard-title kelola-moderator-title">
            <h4>Kategori dan Sub Kategori</h4>
            <button onClick={() => openModal("kategori")} >
              <PlusWhite className="dashboard-moderator-button-svg" />
              Kategori
            </button>
          </div>
          <table className="dashboard-table">
            <thead className="dashboard-table-head">
              <tr>
                <th className="dashboard-table-head-detail">#</th>
                <th className="dashboard-table-head-detail">Kategori</th>
                <th className="dashboard-table-head-detail">Aksi</th>
                <th className="dashboard-table-head-detail">Sub Kategori</th>
                <th className="dashboard-table-head-detail">Aksi</th>
              </tr>
            </thead>
            <tbody className="dashboard-table-body">
              {kategori.map((item, index) => (
                <Fragment key={index}>
                  {item.subKategori.length > 0 ? (
                    item.subKategori.map((subKategori, subIndex) => (
                      <tr key={subIndex}>
                        {subIndex === 0 && (
                          <Fragment>
                            <td rowSpan={item.subKategori.length} className="dashboard-table-body-detail">{((currentPage - 1) * limit) + (index + 1)}</td>
                            <td rowSpan={item.subKategori.length} className="dashboard-table-body-detail">{item.label}</td>
                            <td rowSpan={item.subKategori.length} className="dashboard-table-body-detail">
                              <div className="dashboard-table-body-detail-action">
                                <Plus className="dashboard-moderator-svg" onClick={() => openModal("subKategori", false, item)} />
                                <Edit className="dashboard-moderator-svg" onClick={() => openModal("kategori", true, item)} />
                                <TrashRed className="dashboard-moderator-svg" onClick={(e) => hapusKategori(e, item._id)} />
                              </div>
                            </td>
                          </Fragment>
                        )}
                        <td className="dashboard-table-body-detail">{subKategori.label}</td>
                        <td className="dashboard-table-body-detail">
                          <div className="dashboard-table-body-detail-action">
                            <DetailData className="dashboard-moderator-svg" onClick={() => { }} />
                            <Edit className="dashboard-moderator-svg" onClick={() => openModal("subKategori", true, subKategori, item._id)} />
                            <TrashRed className="dashboard-moderator-svg" onClick={(e) => hapusSubKategori(e, item._id, subKategori._id)} />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="dashboard-table-body-detail">{((currentPage - 1) * limit) + (index + 1)}</td>
                      <td className="dashboard-table-body-detail">{item.label}</td>
                      <td className="dashboard-table-body-detail">
                        <div className="dashboard-table-body-detail-action">
                          <Plus className="dashboard-moderator-svg" onClick={() => openModal("subKategori", false, item)} />
                          <Edit className="dashboard-moderator-svg" onClick={() => openModal("kategori", true, item)} />
                          <TrashRed className="dashboard-moderator-svg" onClick={(e) => hapusKategori(e, item._id)} />
                        </div>
                      </td>
                      <td className="dashboard-table-body-detail"></td>
                      <td className="dashboard-table-body-detail"></td>
                    </tr>
                  )}
                </Fragment>
              ))}
              <tr>
                <td colSpan="5">
                  <div className="dashboard-moderator-pagination">
                    <Pagination
                      totalPages={page}
                      currentPage={currentPage}
                      onClickPage={handlePageClick}
                      onClickNext={handleNextClick}
                      onClickPrevious={handlePreviousClick}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <KategoriModal
          type={type}
          isOpen={isModalOpen}
          closeModal={closeModal}
          isEdit={isEdit}
          dataEdit={dataEdit}
          kategoriId={kategoriId}
        />
      </div>
      <ToastContainer />
    </div>
  )
}

export default KelolaKategoriSubKategori;