import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"

import CustomToggle from "../../../../../../../components/CustomToggle";
import { errorAlert } from "../../../../../../../components/Alert";


import axios from "../../../../../../../config/api";
import { HEADER_NO_COOKIE } from "../../../../../../../config/headers";
import { getProfile } from "../../../../../../../config/getProfile";


import "./index.css"
import "../../index.css";

const Notifikasi = ({ selectedItem }) => {
  const navigate = useNavigate();

  const [pengaturan, setPengaturan] = useState({
    proyek: false,
    banding: false,
    saldoMasuk: false,
    saldoKeluar: false
  })

  const handleUpdatePengaturan = async(type) => {
    try {
      const inputData = {
        type: type,
        value: !pengaturan[type]
      }

      await axios.put(`/account/pengaturan`, inputData, HEADER_NO_COOKIE);

      const queryString = new URLSearchParams("state=Notifikasi").toString();
      navigate(`?${queryString}`, { replace: true });
      
      window.location.reload();
    } catch (error) {
      errorAlert(error.response.data.pesan);
    }
  }

  useEffect(() => {
    const getData = async () => {
      await getProfile("pengaturan")
        .then(response => {
          setPengaturan(response.data);
        })
        .catch(error => {
          console.log(error)
        })
    }

    getData();
  }, [selectedItem])

  return (
    <div className="pengaturan-detail notifikasi-wrap">
      <div className="notifikasi-item">
        <label>Proyek</label>
        <CustomToggle
          toggled={pengaturan.proyek}
          setToggled={() => handleUpdatePengaturan("proyek")}
        />
      </div>
      <div className="notifikasi-item">
        <label>Banding</label>
        <CustomToggle
          toggled={pengaturan.banding}
          setToggled={() => handleUpdatePengaturan("banding")}
        />
      </div>
      <div className="notifikasi-item">
        <label>Saldo Masuk</label>
        <CustomToggle
          toggled={pengaturan.saldoMasuk}
          setToggled={() => handleUpdatePengaturan("saldoMasuk")}
        />
      </div>
      <div className="notifikasi-item">
        <label>Saldo Keluar</label>
        <CustomToggle
          toggled={pengaturan.saldoKeluar}
          setToggled={() => handleUpdatePengaturan("saldoKeluar")}
        />
      </div>
    </div>
  )
}

export default Notifikasi;