import React from "react";
import { useNavigate } from "react-router-dom";

import "./index.css";

const Navigasi = ({
  title,
  data,
  location
}) => {
  const navigate = useNavigate();
  
  const handleNavigasi = (item) => {
    navigate(item.navigasi);
  }

  return (
    <div className="navigasi-wrap">
      <div className="navigasi-title">{title}</div>
      {data.map((item, index) => (
        <div key={index} onClick={() => handleNavigasi(item)} className={`navigasi-subTitle ${location === index ? 'clicked' : ''}`}>
          {item.logo}
          {item.label}
        </div>
      ))}
    </div>
  )
}

export default Navigasi;