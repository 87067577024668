import React , { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CustomCheckbox from "../../../../../../../components/CustomCheckbox";

import { ReactComponent as EditTealBlue } from '../../../../../../../assets/svg/EditTealBlue.svg';

import CustomButton2 from "../../../../../../../components/CustomButton2";
import { errorAlert } from "../../../../../../../components/Alert";

import axios from "../../../../../../../config/api";
import { getProfile } from "../../../../../../../config/getProfile";
import { HEADER_NO_COOKIE } from "../../../../../../../config/headers";

import "./index.css";
import "../../index.css";

const Kategori = ({ selectedItem }) => {
  const navigate = useNavigate();

  const optionKategoriRef = useRef(new Array());

  const [loading, setLoading] = useState(false);
  const [nonEditable, setNonEditable] = useState(true);
  const [optionKategori, setOptionKategori] = useState([]);
  const [kategori, setKategori] = useState([]);

  const convertData = (dataAsli, data) => {
    return data.map((itemData) => {
      const matchingCategory = dataAsli.find((itemAsli) => itemAsli._id === itemData.kategori._id);
      if (matchingCategory) {
        const subKategoriId = itemData.subKategori.map(item => item._id);
        const subKategori = matchingCategory.subKategori.map((subItemAsli) => ({
          checked: subKategoriId.includes(subItemAsli._id),
          label: subItemAsli.label,
          _id: subItemAsli._id,
        }));

        return {
          label: matchingCategory.label,
          subKategori,
          _id: matchingCategory._id,
        };
      }

      return null;
    })
    .filter(Boolean);
  };

  const handleKategori = (option) => {
    if (!nonEditable)
      if (optionKategori.filter(e => e._id === option._id).length > 0) {
        const updatedValues = optionKategori.filter((v) => v._id !== option._id);
        setOptionKategori(updatedValues);

        const UpdateValuesRef = optionKategoriRef.current.filter((v) => v.kategori !== option._id);
        optionKategoriRef.current = UpdateValuesRef;
      } else {
        var temp = JSON.parse(JSON.stringify(option));

        optionKategoriRef.current.push({
          kategori: temp._id,
          subKategori: temp.subKategori
        });
        optionKategoriRef.current[optionKategoriRef.current.length - 1].subKategori.length = 0;

        const newSubKategori = option.subKategori.map(item => ({
          _id: item._id,
          label: item.label,
          checked: false
        }));

        setOptionKategori([...optionKategori, {
          ...option,
          subKategori: newSubKategori
        }])
      }
  };

  const handleCheckboxChange = (option, index, subOption) => {
    if (subOption.checked) {
      const updatedValuesRef = optionKategoriRef.current.map(item => ({
        ...item,
        subKategori: item.subKategori.filter(subItem => subItem !== subOption._id)
      }))
      optionKategoriRef.current = updatedValuesRef;
    } else
      optionKategoriRef.current[index].subKategori.push(subOption._id);

    const updateOption = optionKategori.map((item) => {
      if (item._id === option._id && Array.isArray(item.subKategori)) {
        return {
          ...item,
          subKategori: item.subKategori.map((subItem) => ({
            ...subItem,
            checked: subItem._id === subOption._id ? !subItem.checked : subItem.checked
          }))
        }
      } else
        return item;
    })

    setOptionKategori(updateOption);
  };

  const handleSubmitMinat = async () => {
    var count = optionKategoriRef.current.reduce((total, item) => total + item.subKategori.length, 0);
    if (optionKategoriRef.current.length >= 2 && count >= 6) {
      setLoading(true);

      try {
        await axios.put(`/account/freelancer/minat`, optionKategoriRef.current, HEADER_NO_COOKIE);
        navigate(0);
      } catch (error) {
        errorAlert(error.response.data.pesan);
      } finally {
        setLoading(false);
      }
    } else {
      errorAlert("Minimum memilih 2 Kategori dan 6 Sub Kategori!");
    }
  }

  useEffect(() => {
    const getData = async() => {
      try{
        const response = await axios.get("/category");
        setKategori(response.data.kategori);

        const minat = await getProfile("minat");

        optionKategoriRef.current = minat.data.map((item) => ({
          kategori: item.kategori._id,
          subKategori: item.subKategori.map((sub) => sub._id)
        }));
        const dataHasil = convertData(response.data.kategori, minat.data);
        setOptionKategori(dataHasil);
      } catch(error) {
        console.log(error);
      }
    }
    
    if (selectedItem === "Kategori") getData();
  },[selectedItem])

  return (
    <div className="pengaturan-detail">
      <div className="profilSaya-edit pengaturan-detail-top">
        <div className="lengkapiProfil-title pengaturan-detail-top-title">
          <label className="pengaturan-detail-top-title-label">Pilih Kategori</label><p className="warning-text">*Minimum memilih 2 Kategori dan 6 Sub Kategori!</p>
        </div>
        <div
          className="profilSaya-edit-wrap"
          onClick={() => setNonEditable(false)}
        >
          Edit
          <EditTealBlue className="profilSaya-edit-svg" />
        </div>
      </div>
      <div className="minat-item pengaturan-detail-minat">
        {kategori.map((optionDetail, indexDetail) => (
          <button
            className={`minat-item-button pengaturan-detail-minat-button ${nonEditable ? 'editable' : ''} ${optionKategori.filter(e => e._id === optionDetail._id).length > 0 ? 'clicked' : ''}`}
            onClick={() => handleKategori(optionDetail)}
            key={indexDetail}
          >
            {optionDetail.label}
          </button>
        ))}
      </div>
      <label className="lengkapiProfil-title">
        <label className="pengaturan-detail-top-title-label">Sub Kategori</label>
      </label>
      <div className="minat-subKategori pengaturan-detail-minat-subKategori">
        {optionKategori.map((optionDetail, indexDetail) => (
          <div
            className="minat-subKategori-wrap"
            key={indexDetail}
          >
            <label className="pengaturan-detail-minat-subKategori-label">{optionDetail.label}</label>
            {optionDetail.subKategori.map((optionSubKategori, indexSubKategori) => (
              <div key={indexSubKategori} className="minat-subKategori-wrap-item">
                <label className="pengaturan-detail-minat-subKategori-wrap-label">{optionSubKategori.label}</label>
                <CustomCheckbox
                  id={optionSubKategori._id}
                  checked={optionSubKategori.checked}
                  onChange={() => handleCheckboxChange(optionDetail, indexDetail, optionSubKategori)}
                  disabled={nonEditable}
                  readOnly={nonEditable}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      {!nonEditable &&
        <div className="lengkapiProfil-bottom">
          <CustomButton2
            color={true}
            label={loading ?
              <BeatLoader
                color={"#E4E4E7"}
                size={10}
              />
              :
              "Simpan"
            }
            onClick={handleSubmitMinat}
          />
          <CustomButton2
            onClick={() => setNonEditable(true)}
            label={"Batal"}
          />
        </div>
      }
      <ToastContainer />
    </div>
  )
}

export default Kategori;